import * as React from "react";

function RadioOn({
  width = 16,
  height = 16,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" {...props}>
      <path
        fill="#1672F7"
        fillRule="evenodd"
        d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 3.333a4.667 4.667 0 10.214.005L8 3.333z"
      />
    </svg>
  );
}

export default RadioOn;
