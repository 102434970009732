import * as React from "react";

function Cabinet({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 2.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5h-5.947v10.5H21V2.25a.75.75 0 011.5 0v16.5a.75.75 0 01-1.5 0V15H3v3.75a.75.75 0 01-1.5 0V2.25zM3 13.5h8.303V3H3v10.5zm6.12-6.75a.75.75 0 01.75.75V9a.75.75 0 11-1.5 0V7.5a.75.75 0 01.75-.75zm6.616.75a.75.75 0 00-1.5 0V9a.75.75 0 001.5 0V7.5z"
        fill={fill}
      />
    </svg>
  );
}

export default Cabinet;
