import React, { ReactNode, useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";

import colors from "styles/colors";
import useLockedBody from "hooks/useLockedBody";

interface Props {
  /** 모달 visible */
  visible: boolean;
  /** 상단 헤더 타이틀 */
  headerTitle?: string;
  /** close 버튼 이벤트 정의 */
  onClose?: (e: React.MouseEvent) => void;
  /** overlay 클릭 이벤트 정의 */
  onOverlayClose?: (e: React.MouseEvent) => void;
  /** 하위 노드 선언 */
  children: ReactNode;
  /** 커스텀 스타일 */
  style?: React.CSSProperties;
  /** 닫기 버튼 텍스트 */
  closeText?: string;
}

const fadeIn = keyframes`
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1
    }
    to {
        opacity: 0
    }
`;

const slideUp = keyframes`
    from {
        transform: translateY(200%);
    } 
    to {
        transform: translateY(0px);
    }
`;

const slideDown = keyframes`
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(200%);
    }
`;

const S = {
  Container: styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 14px 24px 24px;
    background-color: ${colors.white};
    box-sizing: border-box;
    z-index: 20;
    @media (min-width: 1200px) {
      top: 50%;
      left: 50%;
      width: 360px;
      height: 480px;
      max-height: none;
      margin-top: -240px;
      margin-left: -180px;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
      background-color: inherit;
    }
  `,
  Overlay: styled.div<{ visible: boolean }>`
    width: 100%;
    height: 100%;
    opacity: 0.88;
    background: ${colors.pointVividBlue};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    background: rgba(0, 0, 0, 0.6);

    transition: all 0.25s ease-in-out;
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    animation-name: ${fadeIn};
    animation-fill-mode: forwards;
    ${({ visible }) =>
      visible &&
      css`
        animation-name: ${fadeOut};
      `};

    @media (min-width: 1200px) {
      background: rgba(18, 18, 18, 0.6);
      background: linear-gradient(
        to right,
        rgba(18, 18, 18, 0.6) 0%,
        rgba(18, 18, 18, 0.6) 100%
      );
      filter: none;
    }
  `,
  Box: styled.div<{ visible: boolean }>`
    position: fixed;
    z-index: 20;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 420px;
    padding: 0;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    background-color: ${colors.white};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
    animation-name: ${slideUp};
    animation-fill-mode: forwards;
    ${({ visible }) =>
      visible &&
      css`
        animation-name: ${slideDown};
      `};

    @media (min-width: 1200px) {
      top: 50%;
      left: 50%;
      width: 360px;
      height: 480px;
      max-height: none;
      margin-top: -240px;
      margin-left: -180px;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
    }
    @media screen and (max-width: 320px) {
      max-height: 320px;
    }
  `,
  Header: styled.div`
    width: 100%;
    height: 40px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    background-color: ${colors.grayBg};
    text-align: right;
    strong {
      position: absolute;
      top: 10px;
      left: 20px;
      font-size: 15px;
      font-weight: 400;
      color: ${colors.gray33};
      line-height: 24px;
    }
    span {
      height: 40px;
      padding: 0 24px;
      font-size: 15px;
      font-weight: 400;
      color: ${colors.pointBlue};
      line-height: 41px;
      cursor: pointer;
    }

    @media (min-width: 1200px) {
      padding: 1px 0;
      strong {
        display: block;
      }
    }
  `,
};

export default function BasePopup({
  visible,
  headerTitle,
  onClose,
  onOverlayClose,
  children,
  style,
  closeText = "닫기",
}: Props) {
  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);
  const [locked, setLocked] = useLockedBody();

  useEffect(() => {
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(visible);
    // 부모창 스크롤 못움직이게 막음
  }, [localVisible, visible]);

  useEffect(() => {
    if (visible) {
      setLocked(true);
      return () => {
        setLocked(false);
      };
    }
  }, [visible]);

  if (!animate && !localVisible) return null;

  return (
    <S.Container>
      <S.Overlay visible={!visible} onClick={onOverlayClose} />
      <S.Box visible={!visible} style={style} className="boxscroll">
        <S.Header>
          <strong>{headerTitle}</strong>
          <span onClick={onClose}>{closeText}</span>
        </S.Header>
        {children}
      </S.Box>
    </S.Container>
  );
}
