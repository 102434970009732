import * as React from "react";

function SvgComponent({
  width = 24,
  height = 24,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.121.879A3 3 0 0115 3h3.75a.75.75 0 010 1.5H4.5v18h15V8.25a.75.75 0 011.5 0v15a.75.75 0 01-.75.75H3.75a.75.75 0 01-.75-.75V3.75A.75.75 0 013.75 3h9.75a1.5 1.5 0 10-3 0H9A3 3 0 0114.121.879zM7.5 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zm0 3a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5h-7.5z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
