import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import { SENTRY_DSN, SENTRY_ENV } from "constants/env";

export const init = () => {
  return Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    integrations: [new Integrations.CaptureConsole({ levels: ["error"] })],
  });
};
