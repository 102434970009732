import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { split } from "lodash";

import MultiStep from "components/templates/multistep";

import { dataLayer } from "lib/gtm";
import { useCookies } from "react-cookie";

import * as orderActions from "services/order/actions";
import * as orderSelector from "services/order/selectors";

export default function OrderPage() {
  const APLYADMIN_KEY = "0dj38gepoekf98234aplyadmin";
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies([
    "0dj38gepoekf98234aplyadmin",
    "keywordid",
  ]);

  const orderForm = useSelector(orderSelector.getOrderForm);

  useEffect(() => {
    dataLayer({
      event: "pageview",
    });
  }, []);

  useEffect(() => {
    const agentId = cookies[APLYADMIN_KEY];
    const keywordCookie = cookies["keywordid"];

    if (agentId) {
      const mda = agentId.split("=")[1];
      dispatch(
        orderActions.setOrderForm({
          order: {
            ...orderForm,
            agent: mda && mda !== "" ? mda : null,
            keywordid: keywordCookie ? keywordCookie : "",
          },
        })
      );
    }
  }, [cookies]);

  useEffect(() => {
    const mda = queryString.parse(window.location.search).mda || "";
    const options =
      window.location.hostname === "localhost"
        ? { path: "/" }
        : { path: "/", domain: ".wematch.com" };

    if (mda) {
      setCookies(APLYADMIN_KEY, `agentid=${mda}`, options);
    }

    const keywordid = queryString.parse(window.location.search).keywordid || "";
    if (keywordid) {
      setCookies("keywordid", `${keywordid}`, options);
    }
  }, []);

  return <MultiStep />;
}
