import * as React from "react";

function Bidet({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.144 9.75V7.5a6 6 0 00-12 0V12h5.072v-1.5a1.5 1.5 0 00-3 0 .75.75 0 01-1.5 0 3 3 0 015.25-1.984 3 3 0 015.25 1.984.75.75 0 01-1.5 0 1.5 1.5 0 00-3 0V12h7.5c.415 0 .754.337.713.749-.256 2.584-3.324 4.783-5.529 5.92l.708 3.537A1.5 1.5 0 0114.637 24h-5.34a1.5 1.5 0 01-1.472-1.794l.708-3.537c-2.205-1.137-5.273-3.336-5.53-5.92-.04-.412.299-.749.713-.749h.928V7.5a7.5 7.5 0 1115 0v2.25a.75.75 0 01-1.5 0zM4.655 13.5c.43 1.67 3.061 3.06 4.565 3.836l1.005.519-.929 4.645h5.34l-.928-4.645 1.005-.519c1.504-.776 4.135-2.167 4.565-3.836H4.655z"
        fill={fill}
      />
    </svg>
  );
}

export default Bidet;
