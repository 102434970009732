import * as React from "react";

function Tight({
  width = 56,
  height = 56,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 56 56"
      fill="fill"
      {...props}
    >
      <rect x={6} y={8} width={2} height={44} rx={1} fill="#121212" />
      <rect x={27} y={8} width={2} height={18} rx={1} fill="#121212" />
      <rect x={27} y={34} width={2} height={18} rx={1} fill="#121212" />
      <rect x={48} y={29} width={2} height={23} rx={1} fill="#121212" />
      <rect x={6} y={8} width={23} height={2} rx={1} fill="#121212" />
      <rect x={32} y={29} width={18} height={2} rx={1} fill="#121212" />
      <rect x={6} y={29} width={18} height={2} rx={1} fill="#121212" />
      <rect x={6} y={50} width={44} height={2} rx={1} fill="#121212" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.514 10.225a1 1 0 00-1.366.366l-5.5 9.526-.861-.497-.005-.003-.005-.003-8.653-4.996h-.002l-.002-.002-.864-.499 5.5-9.526a1 1 0 00-1.733-1l-6 10.392a1 1 0 00.366 1.366l.01.006.856.494-1.494 2.59-.006.008a1 1 0 00.366 1.366l1.367.79-2.45 9.145a1 1 0 00.752 1.236 1 1 0 00.931-.267l6.693-6.693 1.368.79a.996.996 0 00.767.097.997.997 0 00.599-.464l1.5-2.598.86.497.006.003a1.001 1.001 0 00.184.083 1 1 0 001.182-.449l.007-.012 5.993-10.38a1 1 0 00-.366-1.366zM38.62 22.988l-2.34-1.35-1.35 5.04 3.69-3.69zm3.295-.407l1-1.732-6.928-4-1 1.732 6.928 4z"
        fill="#1672F7"
      />
    </svg>
  );
}

export default Tight;
