import * as React from "react";

function Paint({
  width = 32,
  height = 32,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
      {...props}
    >
      <rect
        x={4.571}
        y={5.714}
        width={1.143}
        height={8.571}
        rx={0.571}
        fill="#121212"
      />
      <rect
        x={26.286}
        y={9.143}
        width={1.143}
        height={8}
        rx={0.571}
        fill="#121212"
      />
      <rect
        x={17.143}
        y={18.857}
        width={1.143}
        height={8.571}
        rx={0.571}
        fill="#1672F7"
      />
      <rect
        x={13.714}
        y={18.857}
        width={1.143}
        height={8.571}
        rx={0.571}
        fill="#1672F7"
      />
      <rect
        x={22.857}
        y={5.714}
        width={1.143}
        height={4.571}
        rx={0.571}
        fill="#121212"
      />
      <rect
        x={15.429}
        y={16}
        width={1.143}
        height={3.429}
        rx={0.571}
        fill="#121212"
      />
      <rect
        x={27.429}
        y={9.143}
        width={1.143}
        height={4.571}
        rx={0.571}
        transform="rotate(90 27.429 9.143)"
        fill="#121212"
      />
      <rect
        x={18.286}
        y={18.857}
        width={1.143}
        height={4.571}
        rx={0.571}
        transform="rotate(90 18.286 18.857)"
        fill="#1672F7"
      />
      <rect
        x={18.286}
        y={26.286}
        width={1.143}
        height={4.571}
        rx={0.571}
        transform="rotate(90 18.286 26.286)"
        fill="#1672F7"
      />
      <rect
        x={27.429}
        y={16}
        width={1.143}
        height={12}
        rx={0.571}
        transform="rotate(90 27.429 16)"
        fill="#121212"
      />
      <path
        d="M22.285 13.143a.571.571 0 110 1.143H5.143a.571.571 0 110-1.143h17.142zM23.429 5.714a.571.571 0 010 1.143H5.143a.571.571 0 110-1.143h18.286z"
        fill="#121212"
      />
    </svg>
  );
}

export default Paint;
