import * as types from "./types";
import { opsApi, oneRoomApi } from "lib/api";

//TODO: 쿼리스트링 변경 예정(API 수정)
export const getUserConsult = async (name: string, phone: string[]) => {
  //TODO: API 변경 시 삭제
  const query = `?name=${name}&phone1=${phone[0]}&phone2=${phone[1]}&phone3=${phone[2]}`;

  const { data } = await oneRoomApi.request({
    method: "GET",
    url: `/user/orders${query}`,
  });

  return data;
};

export const postSignUp = async (formData: types.RequestSignUpProps) => {
  const response = await opsApi.post("/customer-auth/signup", { ...formData });
  return {
    token: response.headers["x-wematch-token"],
    data: response.data.data,
  };
};

export const getSignIn = async (phone: string, code: string) => {
  const response = await opsApi.get(`/customer-auth/signin?tel=${phone}&code=${code}`);
  return {
    token: response.headers["x-wematch-token"],
    data: response.data.data,
  };
};

export const getUser = async (token: string) => {
  const { data } = await opsApi({
    method: "get",
    url: "/customer-auth/user",
    headers: {
      "x-wematch-token": token,
    },
  });

  return data;
};

export const verifySendMessage = async (phone: string) => {
  const { data } = await opsApi({
    method: "post",
    url: "/customer-auth/tel",
    data: {
      tel: phone,
    },
  });

  return data;
};

export const verifyAuthCode = async (phone: string, code: string) => {
  const { data } = await opsApi({
    method: "get",
    url: `/customer-auth/tel?tel=${phone}&code=${code}`,
  });

  return data;
};
