import * as React from "react";

function LevelB({
  width = 56,
  height = 56,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="fill"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.048 28c0 15.464 12.536 28 28 28s28-12.536 28-28-12.536-28-28-28-28 12.536-28 28zm48 0c0 11.046-8.955 20-20 20-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20z"
        fill="#EBEEF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.048 47.538C13.134 52.758 20.24 56 28.104 56a27.903 27.903 0 0019.173-7.594C52.711 43.3 56.104 36.046 56.104 28c0-8.136-3.47-15.46-9.01-20.576A27.9 27.9 0 0028.104 0a4 4 0 000 8c5.105 0 9.89 1.91 13.563 5.301A19.921 19.921 0 0148.104 28a19.919 19.919 0 01-6.306 14.577A19.9 19.9 0 0128.104 48c-5.475 0-10.58-2.2-14.326-6.045l-5.73 5.583z"
        fill="url(#prefix__level_b)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.314 36.28v1.06h-8.52v-1.06h5.82c.073-.313.123-.63.15-.95.026-.32.04-.597.04-.83v-.44h-4.9V33h6.16v1.43c0 .113-.005.245-.015.395a13.607 13.607 0 01-.1.97c-.024.17-.049.332-.075.485h1.44zm-9.966.049v-1.03h-4.84v-1.26h4.77v-1.03h-6.02v3.32h6.09zm1.22 1.71v-1.03h-8.52v1.03h8.52zm-3.105 4.115a6.1 6.1 0 01-1.155.105c-.407 0-.792-.035-1.155-.105a3.57 3.57 0 01-.955-.315 1.846 1.846 0 01-.65-.53 1.23 1.23 0 01-.24-.76v-.3c0-.287.08-.538.24-.755.16-.217.377-.395.65-.535a3.57 3.57 0 01.955-.315c.363-.07.748-.105 1.155-.105a6.1 6.1 0 011.155.105c.363.07.681.175.955.315.273.14.49.318.65.535.16.217.24.468.24.755v.3c0 .293-.08.547-.24.76-.16.213-.377.39-.65.53s-.592.245-.955.315zm-1.155-.915a3.74 3.74 0 01-.645-.055 2.716 2.716 0 01-.56-.155 1.28 1.28 0 01-.395-.24.427.427 0 01-.15-.32v-.14c0-.12.05-.227.15-.32.1-.093.232-.173.395-.24s.35-.118.56-.155a3.75 3.75 0 011.29 0c.21.037.396.088.56.155.163.067.295.147.395.24.1.093.15.2.15.32v.14c0 .12-.05.227-.15.32-.1.093-.232.173-.395.24-.164.067-.35.118-.56.155a3.74 3.74 0 01-.645.055zM35.124 42v-3.95h-1.25v.92h-3.64v-.92h-1.25V42h6.14zm-4.89-1.03h3.64v-1h-3.64v1z"
        fill="#FF8600"
      />
      <circle cx={10.951} cy={44.79} r={4} fill="#FF8600" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.048 30V14h6.027c2.16 0 3.802.377 4.927 1.132 1.126.755 1.689 1.85 1.689 3.286 0 .827-.2 1.534-.6 2.12-.4.587-.987 1.019-1.763 1.297.876.22 1.548.63 2.017 1.23.468.602.703 1.334.703 2.199 0 1.567-.52 2.745-1.562 3.533-1.04.787-2.583 1.188-4.627 1.203h-6.811zm4.045-6.802v3.835h2.65c.73 0 1.292-.16 1.683-.478.392-.319.588-.767.588-1.346 0-1.334-.695-2.004-2.086-2.011h-2.835zm0-2.528h2.11c.883-.007 1.513-.16 1.89-.461.376-.3.564-.744.564-1.33 0-.674-.204-1.16-.61-1.456-.408-.297-1.065-.445-1.972-.445h-1.982v3.692z"
        fill="#FFAA28"
      />
      <defs>
        <linearGradient
          id="prefix__level_b"
          x1={56.104}
          y1={0}
          x2={56.104}
          y2={56}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD551" />
          <stop offset={1} stopColor="#FFAA28" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LevelB;
