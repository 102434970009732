import * as React from "react";

function Gasrange({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M.966 3a.75.75 0 00-.75.75v16.5c0 .414.336.75.75.75h22.5a.75.75 0 00.75-.75V3.75a.75.75 0 10-1.5 0V19.5h-21v-15h18.75a.75.75 0 100-1.5H.966z"
        fill={fill}
      />
      <path
        d="M6.216 15.932a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5h-1.5zM15.784 16.682a.75.75 0 01.75-.75h1.5a.75.75 0 110 1.5h-1.5a.75.75 0 01-.75-.75z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.966 14.25c.765 0 1.475-.229 2.068-.621l.584.583a.75.75 0 101.06-1.06l-.583-.584a3.732 3.732 0 00.621-2.068c0-.764-.228-1.475-.621-2.068l.584-.584a.75.75 0 10-1.061-1.06l-.584.583a3.733 3.733 0 00-2.068-.621c-.764 0-1.475.229-2.068.621l-.583-.583a.75.75 0 10-1.061 1.06l.584.584a3.732 3.732 0 00-.622 2.068c0 .764.229 1.475.622 2.068l-.584.584a.75.75 0 001.06 1.06l.584-.583a3.732 3.732 0 002.068.621zm0-1.5c.619 0 1.18-.25 1.586-.654l.005-.005.005-.005a2.25 2.25 0 10-3.194-.002l.007.007.007.007c.407.403.966.652 1.584.652zM19.352 13.629a3.733 3.733 0 01-2.068.621 3.732 3.732 0 01-2.068-.621l-.583.583a.75.75 0 01-1.061-1.06l.584-.584a3.732 3.732 0 01-.622-2.068c0-.764.229-1.475.621-2.068l-.583-.584a.75.75 0 111.06-1.06l.584.583a3.732 3.732 0 012.068-.621c.765 0 1.476.229 2.068.621l.584-.583a.75.75 0 111.06 1.06l-.583.584c.393.593.621 1.304.621 2.068s-.228 1.475-.621 2.068l.584.584a.75.75 0 01-1.061 1.06l-.584-.583zm-.482-1.533a2.243 2.243 0 01-1.586.654c-.618 0-1.177-.25-1.584-.652l-.007-.007-.006-.007a2.25 2.25 0 113.193.002l-.005.005-.005.005z"
        fill={fill}
      />
    </svg>
  );
}

export default Gasrange;
