import { createSelector } from "reselect";
import { RootState } from "../store";

const getCommonState = (state: RootState) => state.commonState;

export const getAddressList = createSelector(getCommonState, (state) => state.addressList);
export const sendVerifyCode = createSelector(getCommonState, (state) => state.sendVerifyCode);
export const getPhoneVerified = createSelector(getCommonState, (state) => state.phoneVerify);
export const getDeviceId = createSelector(getCommonState, (state) => state.deviceId);
export const getJuso = createSelector(getCommonState, (state) => state.juso);
export const getDistance = createSelector(getCommonState, (state) => state.juso.distance);
export const getPrivacyPartner = createSelector(getCommonState, (state) => state.privacyPartner);
