import { createReducer, ActionType } from "typesafe-actions";
import { remove, some } from "lodash";
import { Order, TempLocation, TempAnswer, TempAnswerCount, TempAnswerEq, TempContact, SubmittedOrder } from "types/order";
import { LoadingStatus } from "types/loading";

import * as actions from "./actions";

export type Actions = ActionType<typeof actions>;

export interface OrderState {
  currentStep: number;
  orderForm: Order;
  tempAnswerValues: TempAnswer[];
  tempAnswerCount: TempAnswerCount[];
  tempAnswerEq: TempAnswerEq[];
  tempLocation: TempLocation;
  tempContact: TempContact;
  submittedOrder: {
    data: SubmittedOrder | undefined;
    loading: LoadingStatus;
  };
}

/* Order */
const initialOrder: Order = {
  need_packing: false,
  is_ilban: false,
  // name: "",
  // tel: "",
  moving_date: "",
  start_sido: "",
  start_gugun: "",
  start_dong: "",
  start_detail: "",
  start_floor: "",
  end_sido: "",
  end_gugun: "",
  end_dong: "",
  end_detail: "",
  end_floor: "",
  avail_time: "",
  agreed_terms: false,
  agreed_marketing: false,
  memo: "",
  box: 0,
  need_storage: false,
  furniture: [],
  home_appliance: [],
  small_appliance: [],
  agent: null,
  keywordid: "",
  distance: 1,
  referer: "",
};

/**** Temp Location : 도로명 주소 한줄로 ******/
const initialLocation = {
  movingDate: "",
  movingFrom: "",
  movingFromFloor: {
    key: "",
    value: "",
  },
  movingFromDetail: "",
  movingTo: "",
  movingToFloor: {
    key: "",
    value: "",
  },
  movingToDetail: "",
  needStorage: false,
};

const initialContact = {
  name: "",
  phone: "",
  code: "",
  availTime: {
    key: "",
    value: "",
  },
  agreedTerms: false,
  agreedPrivacy: false,
  agreedMarketing: false,
};

const initialState: OrderState = {
  currentStep: 0,
  orderForm: initialOrder,
  tempAnswerValues: [],
  tempAnswerCount: [],
  tempAnswerEq: [],
  tempLocation: initialLocation,
  tempContact: initialContact,
  submittedOrder: {
    data: undefined,
    loading: "init",
  },
};
export default createReducer<OrderState, Actions>(initialState)
  .handleAction(actions.setCurrentStep, (state, action) => ({
    ...state,
    currentStep: action.payload.currentStep,
  }))
  .handleAction(actions.setOrderForm, (state, action) => ({
    ...state,
    orderForm: action.payload.order,
  }))
  .handleAction(actions.removeCategory, (state, action) => {
    if (action.payload.categoryName === "f" && state.orderForm.furniture) {
      remove(state.orderForm.furniture, (f) => f.category === action.payload.category);
      remove(state.tempAnswerValues, (t) => t.category === action.payload.category);
      remove(state.tempAnswerEq, (t) => t.category === action.payload.category);
      remove(state.tempAnswerCount, (t) => t.category === action.payload.category);
    }

    if (action.payload.categoryName === "h" && state.orderForm.home_appliance) {
      remove(state.orderForm.home_appliance, (f) => f.category === action.payload.category);
      remove(state.tempAnswerValues, (t) => t.category === action.payload.category);
      remove(state.tempAnswerEq, (t) => t.category === action.payload.category);
      remove(state.tempAnswerCount, (t) => t.category === action.payload.category);
    }

    if (action.payload.categoryName === "s" && state.orderForm.small_appliance) {
      remove(state.orderForm.small_appliance, (f) => f.category === action.payload.category);
      remove(state.tempAnswerValues, (t) => t.category === action.payload.category);
      remove(state.tempAnswerEq, (t) => t.category === action.payload.category);
      remove(state.tempAnswerCount, (t) => t.category === action.payload.category);
    }

    return { ...state };
  })
  .handleAction(actions.submitOrderAsync.request, (state) => ({
    ...state,
    submittedOrder: { data: undefined, loading: "loading" },
  }))
  .handleAction(actions.submitOrderAsync.success, (state, action) => ({
    ...state,
    submittedOrder: { data: action.payload, loading: "done" },
  }))
  .handleAction(actions.submitOrderAsync.failure, (state) => ({
    ...state,
    submittedOrder: { ...state.submittedOrder, loading: "error" },
  }))
  .handleAction(actions.getOrderAsync.request, (state) => ({
    ...state,
    submittedOrder: { data: undefined, loading: "loading" },
  }))
  .handleAction(actions.getOrderAsync.success, (state, action) => ({
    ...state,
    submittedOrder: { data: action.payload, loading: "done" },
  }))
  .handleAction(actions.getOrderAsync.failure, (state) => ({
    ...state,
    submittedOrder: { ...state.submittedOrder, loading: "error" },
  }))
  .handleAction(actions.setTempAnswer, (state, action) => {
    if (
      some(
        state.tempAnswerValues,
        (t) => t.group === action.payload.group && t.category === action.payload.category && t.groupValue === action.payload.groupValue
      )
    ) {
      remove(
        state.tempAnswerValues,
        (t) => t.group === action.payload.group && t.category === action.payload.category && t.groupValue === action.payload.groupValue
      );
    }
    return {
      ...state,
      tempAnswerValues: [...state.tempAnswerValues, action.payload],
    };
  })
  .handleAction(actions.removeTempAnswer, (state, action) => {
    if (some(state.tempAnswerValues, (t) => t.category === action.payload.category && t.groupValue === action.payload.groupValue)) {
      remove(state.tempAnswerValues, (t) => t.category === action.payload.category && t.groupValue === action.payload.groupValue);
    }
    return { ...state };
  })
  .handleAction(actions.setTempAnswerCount, (state, action) => {
    if (some(state.tempAnswerCount, (t) => t.category === action.payload.category)) {
      remove(state.tempAnswerCount, (t) => t.category === action.payload.category);
    }
    return {
      ...state,
      tempAnswerCount: [...state.tempAnswerCount, action.payload],
    };
  })
  .handleAction(actions.setTempAnswerEq, (state, action) => {
    if (some(state.tempAnswerEq, (t) => t.category === action.payload.category && t.id === action.payload.id)) {
      remove(state.tempAnswerEq, (t) => t.category === action.payload.category && t.id === action.payload.id);
    }
    return { ...state, tempAnswerEq: [...state.tempAnswerEq, action.payload] };
  })
  .handleAction(actions.removeTempAnswerEq, (state, action) => {
    if (some(state.tempAnswerEq, (t) => t.category === action.payload.category && t.id === action.payload.id)) {
      remove(state.tempAnswerEq, (t) => t.category === action.payload.category && t.id === action.payload.id);
    }
    return { ...state };
  })
  .handleAction(actions.setTempLocation, (state, action) => ({
    ...state,
    tempLocation: action.payload,
  }))
  .handleAction(actions.setTempContact, (state, action) => ({
    ...state,
    tempContact: action.payload,
  }))
  .handleAction(actions.resetAll, () => ({ ...initialState }));
