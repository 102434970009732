import * as React from "react";

function Table({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 6a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75H3v6.75a.75.75 0 001.5 0V12h15v6.75a.75.75 0 001.5 0V12h.75a.75.75 0 00.75-.75v-4.5a.75.75 0 00-1.5 0v3.75H3v-3h15.75a.75.75 0 000-1.5H2.25z"
        fill={fill}
      />
    </svg>
  );
}

export default Table;
