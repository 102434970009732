import * as React from "react";

function Previous({
  width = 16,
  height = 16,
  fill = "121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" {...props}>
      <path
        fill={fill}
        d="M6.307 14.293L.144 8.003a.505.505 0 01.004-.71l.055-.045L6.3 1.147a.5.5 0 01.638-.059l.069.058a.5.5 0 010 .708l-5.8 5.805 5.804 5.923a.505.505 0 01-.004.71.495.495 0 01-.7 0z"
      />
    </svg>
  );
}

export default Previous;
