import * as React from "react";

function Sofa({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 3a.75.75 0 00-.75.75V9H2.25a.75.75 0 00-.75.75v10.5a.75.75 0 001.5 0V18h18v2.25a.75.75 0 001.5 0V9.75a.75.75 0 00-.75-.75H19.5V3.75a.75.75 0 00-1.5 0V9h-.75a.75.75 0 00-.75.75V12h-9V9.75A.75.75 0 006.75 9H6V4.5h9.75a.75.75 0 000-1.5H5.25zm13.5 7.5H18V12h3v-1.5h-2.25zM3 10.5h2.203a.8.8 0 00.094 0H6V12H3v-1.5zm3.75 3H3v3h18v-3H6.75z"
        fill={fill}
      />
    </svg>
  );
}

export default Sofa;
