export enum Initialize {
  EMPTY = "",
}

export enum CategoryName {
  Furniture = "f",
  HomeAppliance = "h",
  SmallAppliance = "s",
}

export enum Furniture {
  BED = "BED",
  BOOK = "BOOK",
  CABI = "CABI",
  CHAI = "CHAI",
  CHIF = "CHIF",
  CLOS = "CLOS",
  DESK = "DESK",
  SOFA = "SOFA",
  TABL = "TABL",
  VANI = "VANI",
}

export enum HomeAppliance {
  AC = "AC",
  CLOT = "CLOT",
  DISP = "DISP",
  DRYE = "DRYE",
  FRID = "FRID",
  WASH = "WASH",
}

export enum Kind {
  AC_STAN = "AC_STAN",
  AC_WALL = "AC_WALL",
  CABI_SHOE = "CABI_SHOE",
  CABI_DISP = "CABI_DISP",
  CABI_TV = "CABI_TV",
  CHAI_ASSI = "CHAI_ASSI",
  CHAI_BACK = "CHAI_BACK",
  DISP_TV = "DISP_TV",
  DISP_WALL = "DISP_WALL",
  DISP_MONI = "DISP_MONI",
  FRID_MINI = "FRID_MINI",
  FRID_REGU = "FRID_REGU",
  FRID_TWOD = "FRID_TWOD",
  TABL_CIRC = "TABL_CIRC",
  TABL_LIBR = "TABL_LIBR",
  TABL_RECT = "TABL_RECT",
  VANI_REGU = "VANI_REGU",
  VANI_SHOR = "VANI_SHOR",
  WASH_FRON = "WASH_FRON",
  WASH_TOP = "WASH_TOP",
}

export enum Size {
  S = "S",
  SS = "SS",
  D = "D",
  Q = "Q",
  K = "K",
  COUPLE = "COUPLE",
  FAMILY = "FAMILY",
  LESS3 = "LESS3",
  MORE4 = "MORE4",
  LESS15 = "LESS15",
  MORE15 = "MORE15",
  ONE = "ONE",
  TWO = "TWO",
}

// export enum Dimension {
//   UNIT10 = 'UNIT10',
//   UNIT11 = 'UNIT11',
//   UNIT20 = 'UNIT20',
//   UNIT21 = 'UNIT21',
//   UNIT22 = 'UNIT22',
//   UNIT30 = 'UNIT30',
// }

export enum Height {
  H10 = "H10",
  H11 = "H11",
  H20 = "H20",
  H21 = "H21",
  H22 = "H22",
  H30 = "H30",
}

export enum Width {
  W10 = "W10",
  W11 = "W11",
  W20 = "W20",
  W21 = "W21",
  W22 = "W22",
  W30 = "W30",
}

export enum Material {
  NOFR = "NOFR",
  WOOD = "WOOD",
  STEE = "STEE",
}

export enum Option {
  DISA_T = "DISA_T",
  DISA_F = "DISA_F",
  DETA_T = "DETA_T",
  DETA_F = "DETA_F",
  DANDA_T = "DANDA_T",
  DANDA_F = "DANDA_F",
}

export enum SmallAppliance {
  BIDE = "BIDE",
  CATT = "CATT",
  ETC = "ETC",
  MICR = "MICR",
  SPOR = "SPOR",
  STOV = "STOV",
  WATE = "WATE",
  AIRP = "AIRP",
}

export interface Detail {
  id?: number | undefined;
  size?: Size | undefined;
  material?: Material | undefined;
  kind?: Kind | undefined;
  option?: Option | undefined;
}

interface Item {
  count: number;
}

interface FurnitureItem extends Item {
  category: Furniture;
  detail: Detail[];
}

interface HomeApplianceItem extends Item {
  category: HomeAppliance;
  detail: Detail[];
}

interface SmallApplianceItem extends Item {
  category: SmallAppliance;
}

export type GroupType = "size" | "material" | "option" | "width" | "height" | "kind";

export interface TempAnswer {
  id: string;
  category: Furniture | HomeAppliance | SmallAppliance | Initialize;
  group: GroupType;
  groupValue: string;
  value: string;
  label: string; // 선택한값의 라벨
  key: string;
}

export interface TempAnswerCount {
  category: Furniture | HomeAppliance | SmallAppliance;
  count: number;
}

export interface TempAnswerEq {
  id: number;
  category: Furniture | HomeAppliance | SmallAppliance | Initialize;
  status: CheckType;
  disabled: boolean;
}

export interface TempLocation {
  movingDate: string;
  movingFrom: string;
  // movingFrom: {
  //   start: string;
  // };
  movingFromFloor: {
    key: string;
    value: string;
  };
  movingFromDetail: string;
  movingTo: string;
  // movingTo: {
  //   start: string;
  // };
  movingToFloor: {
    key: string;
    value: string;
  };
  movingToDetail: string;
  needStorage: boolean;
}

export interface TempContact {
  name: string;
  phone: string;
  code: string;
  availTime: {
    key: string;
    value: string;
  };
  agreedTerms: boolean;
  agreedPrivacy: boolean;
  agreedMarketing: boolean;
}

export type CheckType = "eq" | "diff" | "init";

export interface Order {
  need_packing: boolean;
  is_ilban: boolean;
  // name: string;
  // tel: string;
  moving_date: string;
  start_sido: string;
  start_gugun: string;
  start_dong: string;
  start_detail: string;
  start_floor: string;
  end_sido: string;
  end_gugun: string;
  end_dong: string;
  end_detail: string;
  end_floor: string;
  need_storage: boolean;
  agreed_terms: boolean;
  agreed_marketing: boolean;
  avail_time: string;
  memo: string;
  box: number;
  furniture?: FurnitureItem[];
  home_appliance?: HomeApplianceItem[];
  small_appliance?: SmallApplianceItem[];
  agent: string | null;
  keywordid: string | null | undefined;
  distance: number;
  referer: string;
}

interface Options {
  avail_time: string;
  need_packing: boolean;
  need_storage: boolean;
  is_ilban: boolean;
  stuff: string;
  memo: string;
}

export interface MatchedOrder {
  adminname: string;
  adminid: string;
  feedback_cnt: number;
  level: string;
  level_text: string;
  experience: string;
  phone1: string;
  phone2: string;
  phone3: string;
  partner_meta: {
    profile_img: string;
    keyword_list: string[];
    score: number;
  };
}

export interface SubmittedOrder {
  who: string;
  when: string;
  starting_point: string;
  destination: string;
  options: Options;
  match_status: "success" | "no partner" | "no service";
  matches: MatchedOrder[];
}
