import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import colors from "styles/colors";
import { Close, NextArrow } from "components/common/Icon";
import { useMedia } from "react-use-media";
import { dataLayer } from "lib/gtm";
import { openDefaultBrowser } from "lib/openBrowser";
interface Props {
  visible: boolean;
  onClose?: () => void;
}

const CompletedModal: React.FC<Props> = (props) => {
  const { visible, onClose } = props;

  const isMobile = useMedia({
    maxWidth: 767,
  });

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.removeAttribute("style");
    };
  }, [visible]);

  if (!visible) return null;

  const ArrowIcon = () => {
    return (
      <div className="arrow">
        <NextArrow color="#ffffff" width={20} height={20} />
      </div>
    );
  };

  return createPortal(
    <S.PopupOverlay>
      <S.PopupWrapper>
        <S.PopupHeader>
          <div onClick={onClose}>
            <Close width={20} color={"#121212"} />
          </div>
        </S.PopupHeader>
        <S.Container>
          <S.Content>
            <S.Info>
              <img src={require("assets/images/info.png")} alt="" />
              <div className="bold">이사 필수코스,</div>
              <div className="normal">인터넷 변경 / 에어컨설치</div>
              <div className="normal">상담 필요하세요?</div>
            </S.Info>
            <S.SimpleButtonWrapper
              onClick={() => {
                dataLayer({
                  event: "completed_popup",
                  category: "에어컨",
                  label: "원룸",
                });
                openDefaultBrowser("https://wematch.com/main/ac-apply");
                // openDefaultBrowser("https://smartstore.naver.com/wematch/products/8713246341?nt_source=wematch&nt_medium=crosssellcard");
              }}
            >
              <S.SimpleButton isMobile={isMobile}>
                <S.ProductWrapper>
                  <S.ProductCardWrapper>
                    <div className="title">
                      <span className="bold">에어컨 설치 </span>예약하기
                    </div>
                    <div className="content">전문가에게 가격 상담받기</div>
                    <div className="count">누적 이용 고객 수 298,362명</div>
                  </S.ProductCardWrapper>
                </S.ProductWrapper>
                <ArrowIcon />
              </S.SimpleButton>
            </S.SimpleButtonWrapper>
            <S.SimpleButtonWrapper
              onClick={() => {
                dataLayer({
                  event: "completed_popup",
                  category: "인터넷",
                  label: "원룸",
                });
                openDefaultBrowser(
                  "https://tv.wematch.com/?_gl=1*z06ds0*_ga*NDQ4MjY0MDg0LjE2ODAxNDM3MzA.*_ga_FF1T15NDQN*MTY4Nzg0NTk3OS41OS4xLjE2ODc4NDYwNTkuNjAuMC4w"
                );
              }}
            >
              <S.SimpleButton isMobile={isMobile}>
                <S.ProductWrapper>
                  <S.ProductCardWrapper>
                    <div className="title">
                      <span className="bold">인터넷통신사 변경/설치</span>
                    </div>
                    <div className="content">최대 47만원 지원금 지급</div>
                    <div className="count">누적 이용 고객 수 100,297명</div>
                  </S.ProductCardWrapper>
                </S.ProductWrapper>
                <ArrowIcon />
              </S.SimpleButton>
            </S.SimpleButtonWrapper>
          </S.Content>
        </S.Container>
      </S.PopupWrapper>
    </S.PopupOverlay>,
    document.body
  );
};

export default CompletedModal;

const S = {
  PopupOverlay: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: rgba(18, 18, 18, 0.6);
  `,

  PopupWrapper: styled.div`
    position: relative;
    height: 100%;
    overflow-y: hidden;
    background-color: #ffffff;

    box-sizing: border-box;
    height: -webkit-fill-available;
    -ms-overflow-style: none;
    display: flex;
    flex-direction: column;
    ::-webkit-scrollbar {
      display: none;
    }

    width: 320px;
    height: 460px;
    border-radius: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  PopupHeader: styled.div`
    position: fixed;
    width: 100%;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    z-index: 210;
    svg {
      margin-right: 20px;
    }
  `,
  Container: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
  `,
  Content: styled.div`
    text-align: center;
  `,
  Info: styled.div`
    padding: 72px 30px 18px;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.01em;
    img {
      height: 32px;
      width: 32px;
      margin-bottom: 10px;
    }

    .bold {
      font-weight: 700;
      color: ${colors.gray33};
    }

    .normal {
      font-weight: 400;
      color: ${colors.gray33};
    }
  `,
  SimpleButtonWrapper: styled.div`
    padding: 0px 16px;
    margin: 8px;
    cursor: pointer;
  `,
  SimpleButton: styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #ffffff;
    border: 0.5px solid #d7dbe2;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    .arrow {
      background: ${colors.pointBlue};
      color: #ffffff;
      border: 1px solid ${colors.pointBlue};
      border-radius: 0px 6px 6px 0px;
      svg {
        margin: 40px 6px 0px 17px;
        vertical-align: middle;
      }
    }
  `,
  ProductWrapper: styled.div`
    padding: 22px;
  `,
  ProductCardWrapper: styled.div`
    text-align: left;
    letter-spacing: -1px;
    font-weight: 400;
    .title {
      font-size: 16px;
      color: ${colors.gray33};
      margin-bottom: 4px;

      .bold {
        font-weight: 700;
      }
    }

    .content {
      font-size: 14px;
      letter-spacing: -0.5px;
      color: ${colors.gray66};
      margin-bottom: 4px;
    }

    .count {
      font-size: 14px;
      color: ${colors.gray99};
    }
  `,
};
