import * as React from "react";

function LevelA({
  width = 56,
  height = 56,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill={fill}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.144 28c0 15.464 12.536 28 28 28s28-12.536 28-28-12.536-28-28-28-28 12.536-28 28zm48 0c0 11.046-8.955 20-20 20-11.046 0-20-8.954-20-20s8.954-20 20-20c11.045 0 20 8.954 20 20z"
        fill="#EBEEF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.065 16.103a933.087 933.087 0 01-6.499-4.667A27.874 27.874 0 00.144 28c0 15.464 12.536 28 28 28s28-12.536 28-28c0-15.43-12.48-27.944-27.897-28l.266 8.003C39.388 8.2 48.143 17.078 48.143 28c0 11.046-8.954 20-20 20-11.045 0-20-8.954-20-20a19.91 19.91 0 013.922-11.897z"
        fill="url(#prefix__level_a)"
      />
      <circle cx={8.704} cy={13.925} r={4} fill="#00B2ED" />
      <path d="M32.666 4a4 4 0 11-8 0 4 4 0 018 0z" fill="#01DCF7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.41 36.28v1.06h-8.52v-1.06h5.82c.073-.313.123-.63.15-.95.026-.32.04-.597.04-.83v-.44H29V33h6.16v1.43c0 .113-.006.245-.016.395a13.607 13.607 0 01-.1.97c-.023.17-.048.332-.075.485h1.44zm-9.966.049v-1.03h-4.84v-1.26h4.77v-1.03h-6.02v3.32h6.09zm1.22 1.71v-1.03h-8.52v1.03h8.52zm-3.105 4.115a6.1 6.1 0 01-1.155.105c-.407 0-.792-.035-1.155-.105a3.57 3.57 0 01-.955-.315 1.846 1.846 0 01-.65-.53 1.23 1.23 0 01-.24-.76v-.3c0-.287.08-.538.24-.755.16-.217.376-.395.65-.535a3.57 3.57 0 01.955-.315c.363-.07.748-.105 1.155-.105a6.1 6.1 0 011.155.105c.363.07.681.175.955.315.273.14.49.318.65.535.16.217.24.468.24.755v.3c0 .293-.08.547-.24.76-.16.213-.377.39-.65.53-.274.14-.592.245-.955.315zm-1.155-.915a3.74 3.74 0 01-.645-.055 2.716 2.716 0 01-.56-.155 1.28 1.28 0 01-.395-.24.427.427 0 01-.15-.32v-.14c0-.12.05-.227.15-.32.1-.093.231-.173.395-.24.163-.067.35-.118.56-.155a3.75 3.75 0 011.29 0c.21.037.396.088.56.155.163.067.295.147.395.24.1.093.15.2.15.32v.14c0 .12-.05.227-.15.32-.1.093-.232.173-.395.24-.164.067-.35.118-.56.155a3.74 3.74 0 01-.645.055zM35.219 42v-3.95h-1.25v.92h-3.64v-.92h-1.25V42h6.14zm-4.89-1.03h3.64v-1h-3.64v1z"
        fill="#00B2ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.871 27.011h-5.49L24.424 30h-4.28l6.095-16h3.766l6.14 16H31.84l-.97-2.989zm-4.53-2.978h3.572l-1.792-5.55-1.78 5.55z"
        fill="#00B6ED"
      />
      <defs>
        <linearGradient
          id="prefix__level_a"
          x1={29.014}
          y1={0}
          x2={29.014}
          y2={56}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01DBF7" />
          <stop offset={1} stopColor="#01B9EE" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LevelA;
