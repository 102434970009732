import { combineReducers, applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from "connected-react-router";
import browserHistory from "lib/history";

import CommonService, { CommonState } from "./common/reducers";
import CommonSaga from "./common/sagas";

import OrderService, { OrderState } from "./order/reducers";
import OrderSaga from "./order/sagas";

import LoadingService, { LoadingState } from "./loading/reducers";

import UserService, { UserState } from "./user/reducers";
import UserSaga from "./user/sagas";

export interface RootState {
  router: RouterState;
  commonState: CommonState;
  orderState: OrderState;
  loadingState: LoadingState;
  userState: UserState;
}

const rootReducer = combineReducers({
  router: connectRouter(browserHistory),
  commonState: CommonService,
  orderState: OrderService,
  loadingState: LoadingService,
  userState: UserService,
});

const sagaMiddleware = createSagaMiddleware();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancer =
  (process.env.NODE_ENV !== "production" &&
    (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose)) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancer(
    applyMiddleware(sagaMiddleware, routerMiddleware(browserHistory))
  )
);

function* rootSaga() {
  yield all([CommonSaga(), OrderSaga(), UserSaga()]);
}

sagaMiddleware.run(rootSaga);

export default store;
