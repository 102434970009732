import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import * as sentry from "@sentry/react";
import { Severity } from "@sentry/react";
import { useMedia } from "react-use-media";

import {
  API_URL_OPS,
  API_SLACK_WEBHOOK_PPOMPPU,
  MOVE_URL,
} from "constants/env";

import { getCookie, setCookie } from "lib/cookie";

import { Icon } from "components/common";
import InputText from "./components/InputText";
import Button from "components/da24/Button";
import { S1, S2, S3, SMovingTypes } from "./styles";
import SmileIcon from "components/common/Icon/SmileIcon";
import colors from "styles/colors";

type ResidenceType = "house" | "oneroom" | "office" | undefined;

interface GroupProp {
  type: ResidenceType;
  value: string | undefined;
  subValue?: string;
  weight: string;
}

interface FormType {
  moveType: string | undefined;
  moveMethod: string;
  moveDate: string;
  startAddress: string;
  startFloor: string;
  endAddress: string;
  endFloor: string;
  largeLuggage: string;
  boxNumber: string;
  phone: string;
  name: string;
}

const categories = [
  {
    title: "포장이사",
    subTitle1: "업체에게 모든 걸 맡기세요!",
    needPacking: true,
    isIlban: false,
    range: "full",
  },
  {
    title: "반포장이사",
    subTitle1: "도착지 뒷정리만 고객님이!",
    needPacking: false,
    isIlban: false,
    range: "range14",
  },
  {
    title: "일반이사",
    subTitle1: "업체가 짐 운반만 도와드려요!",
    needPacking: false,
    isIlban: true,
    range: "range23",
  },
];

const PpomppuPage = () => {
  const [step, setStep] = useState(1);
  const [moveType] = useState<GroupProp>({
    type: "oneroom",
    value: "원룸이사",
    subValue: "원룸 · 투룸\n오피스텔 등",
    weight: "1톤 이내 짐량",
  });
  const [moveDate, setMoveDate] = useState<string>("");
  const [startAddress, setStartAddress] = useState<string>("");
  const [startFloor, setStartFloor] = useState<string>("");
  const [endAddress, setEndAddress] = useState<string>("");
  const [endFloor, setEndFloor] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [name, setName] = useState<string>("");

  const [largeLuggage, setLargeLuggage] = useState<string>("");
  const [boxNumber, setBoxNumber] = useState<string>("");

  const [disabled, setIsDisabled] = useState(true);
  const [moveMethod, setMoveMethod] = useState<string>("");
  const [iframeJson, setIframeJson] = useState<string>("");
  const isMobile = useMedia({
    maxWidth: 767,
  });

  useEffect(() => {
    const formString = getCookie("form");
    if (formString) {
      const form: FormType = JSON.parse(formString);
      setMoveDate(form.moveDate);
      setStartAddress(form.startAddress);
      setStartFloor(
        form.startFloor.includes("층")
          ? form.startFloor
          : `${form.startFloor}층`
      );
      setEndAddress(form.endAddress);
      setEndFloor(
        form.endFloor.includes("층") ? form.endFloor : `${form.endFloor}층`
      );
    }
  }, []);

  const validateForm = () => {
    const isDisabled =
      moveDate === "" ||
      startAddress === "" ||
      startFloor === "" ||
      endAddress === "" ||
      endFloor === "" ||
      phone === "" ||
      name === "";
    setIsDisabled(isDisabled);
  };

  useEffect(() => {
    validateForm();
  }, [moveDate, startAddress, startFloor, endAddress, endFloor, phone, name]);

  const getCallbackUrl = () => {
    return isMobile
      ? "https://m.ppomppu.co.kr/new/partner/da24_estimate_result.php"
      : "https://www.ppomppu.co.kr/partner/da24_estimate_result.php";
  };

  const moveStep = (step: number) => {
    window.scrollTo(0, 0);
    setStep(step);
  };

  const onClickMoveMethod = (method: string) => {
    setMoveMethod(method);
    moveStep(step + 1);
  };

  const goWematchPpomppu = () => {
    window.location.href = `${MOVE_URL}/ppomppu`;
  };

  const sendFormToSlackChannel = async (form: FormType) => {
    const contents = `\n\`${moveType?.value}\`\n*[방식]* ${form.moveMethod}\n*[날짜]* ${form.moveDate}\n*[출발지 주소]* ${form.startAddress} ${form.startFloor}\n*[도착지 주소]* ${form.endAddress} ${form.endFloor}\n*[큰 이사짐]* ${form.largeLuggage}\n*[잔짐(박스)]* ${form.boxNumber}\n*[전화번호]* ${form.phone}\n*[이름]* ${form.name}
  `;
    const payload = {
      webhook_url: API_SLACK_WEBHOOK_PPOMPPU,
      name: "뽐뿌 등록 알리미",
      contents: contents,
      icon_emoji: "love_letter",
    };
    await axios
      .post(`${API_URL_OPS}/da24/slack-webhook`, payload)
      .catch((e) => {
        sentry.captureMessage("원룸이사 접수 실패", {
          level: Severity.Error,
          extra: { payload: payload },
        });
        sentry.captureException(e);
      });
  };

  const submitForm = () => {
    if (!disabled) {
      const form: FormType = {
        moveMethod: moveMethod,
        moveType: moveType?.value,
        moveDate: moveDate,
        startAddress: startAddress,
        startFloor: startFloor,
        endAddress: endAddress,
        endFloor: endFloor,
        largeLuggage: largeLuggage,
        boxNumber: boxNumber,
        phone: phone,
        name: name,
      };

      setCookie("form", JSON.stringify(form), {
        expires: new Date(dayjs().add(2, "day").format()),
      });
      sendFormToSlackChannel(form);
      const encodedForm = encodeURIComponent(
        JSON.stringify({
          bbstype: "moving",
          movingtype: moveType?.value,
          movingdate: moveDate,
          fromaddress: startAddress,
          pyungtype: "선택안함",
          toaddress: endAddress,
        })
      );
      setIframeJson(encodedForm);
      moveStep(step + 1);
    }
  };

  if (step == 1) {
    return (
      <S1.Container>
        <div className="back-btn-container">
          <div className="btn-back" onClick={goWematchPpomppu}>
            <Icon.Previous width={24} height={24} />
          </div>
        </div>

        <div className="title">{"원하시는 이사 방식을 선택해 주세요."}</div>
        <div className="move-types">
          {categories.map((category, i) => {
            return (
              <SMovingTypes.Container
                key={i}
                onClick={() => {
                  onClickMoveMethod(category.title);
                }}
              >
                <div>
                  <div className="type-title">{category.title}</div>
                  <div className="type-subtitle">{category.subTitle1}</div>
                </div>
                <div>
                  <div className="service-bg mr-2">
                    <div className={`service-bar ${category.range}`}>
                      <div className="point" />
                      <div className="point" />
                      <div className="point" />
                      <div className="point" />
                      <div className="point" />
                    </div>
                  </div>
                  <div className="service-text flex justify-between -mx-4 font-button3 pt-4">
                    <div>포장</div>
                    <div>운반</div>
                    <div className="">이동</div>
                    <div className="-mr-2">운반</div>
                    <div className="">뒷정리</div>
                  </div>
                </div>
              </SMovingTypes.Container>
            );
          })}
        </div>
      </S1.Container>
    );
  } else if (step === 2) {
    return (
      <S2.Container>
        <S2.Header>
          <div className="back-btn-container">
            <div
              className="btn-back"
              onClick={() => {
                moveStep(1);
              }}
            >
              <Icon.Previous width={24} height={24} fill={"#ffffff"} />
            </div>
          </div>
          <S2.HeaderMain>
            <div>{moveType?.value} 무료방문견적 신청하기</div>
            <div>이사정보를 입력해주시면 조건에 맞는 이사업체를 찾아드려요</div>
          </S2.HeaderMain>
        </S2.Header>
        <S2.Form>
          <div>
            <S2.Label>이사 가능 날짜</S2.Label>
            <InputText
              placeholder={"이사 가능한 날짜를 입력해주세요"}
              onChange={(e) => setMoveDate(e.target.value)}
              value={moveDate}
            />
          </div>
          <div>
            <S2.Label>출발지 주소/층수</S2.Label>
            <InputText
              placeholder={"주소를 입력해주세요"}
              onChange={(e) => setStartAddress(e.target.value)}
              value={startAddress}
            />
            <InputText
              placeholder={"층수를 입력해주세요"}
              onChange={(e) => setStartFloor(e.target.value)}
              value={startFloor}
            />
          </div>
          <div>
            <S2.Label>도착지 주소/층수</S2.Label>
            <InputText
              placeholder={"주소를 입력해주세요"}
              onChange={(e) => setEndAddress(e.target.value)}
              value={endAddress}
            />
            <InputText
              placeholder={"층수를 입력해주세요"}
              onChange={(e) => setEndFloor(e.target.value)}
              value={endFloor}
            />
          </div>
          <div>
            <S2.Label>큰 이사짐</S2.Label>
            <InputText
              placeholder={"예) 냉장고, 세탁기, 옷장2개, 침대, 2인용식탁"}
              onChange={(e) => setLargeLuggage(e.target.value)}
              value={largeLuggage}
            />
          </div>
          <div>
            <S2.Label>잔짐(박스)</S2.Label>
            <InputText
              placeholder={"박스 수량을 적어주세요."}
              onChange={(e) => setBoxNumber(e.target.value)}
              value={boxNumber}
            />
          </div>
          <div style={{ paddingBottom: "18px" }}>
            <S2.Label>상담받으실 연락처</S2.Label>
            <InputText
              placeholder={"전화번호를 입력해주세요."}
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
            <InputText
              placeholder={"성함을 입력해주세요."}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <Button
            theme="primary"
            bold
            border
            onClick={submitForm}
            disabled={disabled}
          >
            견적상담 신청하기
          </Button>
        </S2.Form>
      </S2.Container>
    );
  } else {
    return (
      <S3.Container>
        <SmileIcon />
        <S3.Title>신청이 완료되었습니다.</S3.Title>
        <S3.Content>
          조건에 맞는 업체 발생 시 연락드리겠습니다. <br />
          위매치 상담원의 <b>연락을 꼭 받아주세요!</b>
        </S3.Content>
        <S3.ButtonContainer>
          <Button theme="primary" onClick={goWematchPpomppu} bold border>
            홈으로 돌아가기
          </Button>
        </S3.ButtonContainer>
        <iframe
          title="ppomppu"
          style={{ height: "0px" }}
          src={`${getCallbackUrl()}?json=${iframeJson}`}
          id="iframe"
        />
      </S3.Container>
    );
  }
};

export default PpomppuPage;
