import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import GlobalStyled from "styles/global";
import { Provider, useDispatch } from "react-redux";
import store from "services/store";
import browserHistory from "lib/history";
import { ConnectedRouter } from "connected-react-router";
import { useCookies } from "react-cookie";
import { get } from "lodash";
// import ReactPixel from "react-facebook-pixel";

// import IndexPage from 'pages/index'
import OrderPage from "pages/requests/order";
import CompletedPage from "pages/requests/completed";
import NoPartnerPage from "pages/requests/noPartner";
import NoServicePage from "pages/requests/noService";
import PpomppuPage from "pages/ppomppu";
import UnSupported from "pages/unsupported";
import ToastTestPage from "./components/common/Toast/ToastTestPage";

import { NotFound, ErrorService } from "components/common";

import { init as initSentry } from "lib/sentry";
import useUserAgent from "hooks/useUserAgent";
import useReceiveMessage from "hooks/useReceiveMessage";
// import useScript from "hooks/useScript";

import * as commonActions from "services/common/actions";
import * as userActions from "services/user/actions";
import { setReferer } from "lib/referer";

initSentry();

declare global {
  interface Window {
    ReactNativeWebView: any; // eslint-disable-line
  }
}

function AppRoute() {
  const dispatch = useDispatch();
  const { isIE } = useUserAgent();
  const [cookies] = useCookies(["x-wematch-token"]);
  const getDeviceId = useReceiveMessage();
  // const location = useLocation();
  // const [script, setScript] = useState("");
  // const customScript = useScript(script);

  useEffect(() => {
    const wematchToken = get(cookies, "x-wematch-token");
    if (wematchToken !== undefined) {
      dispatch(userActions.fetchGetUserAsync.request({ token: wematchToken }));
    }
  }, [cookies, dispatch]);

  useEffect(() => {
    setReferer();
  }, []);

  useEffect(() => {
    if (getDeviceId) {
      dispatch(commonActions.setDeviceId(getDeviceId));
    }
  }, [getDeviceId]);

  // const getPathname = () => {
  //   let pathname = 5;
  //   switch (location.pathname) {
  //     case "/requests/order":
  //       return (pathname = 3);
  //     case "/requests/completed":
  //       return (pathname = 1);
  //     case "/requests/nopartner":
  //       return (pathname = 5);
  //     case "/requests/noservice":
  //       return (pathname = 5);
  //   }
  //   return pathname;
  // };

  // useEffect(() => {
  //   if (location.pathname !== "/") {
  //     const script = `
  //        if(!wcs.add) var wcs_add = {};
  //        wcs_add["wa"] = "s_4abc339a6ccc";
  //        if (!_nasa) var _nasa={};
  //        _nasa["cnv"] = wcs.cnv("${getPathname()}", "1");
  //        wcs_do(_nasa);
  //     `;
  //     setScript(script);
  //   }
  //   ReactPixel.pageView();
  // }, [location.pathname]);

  // useEffect(() => {
  //   if (script.length !== 0) {
  //     return customScript;
  //   }
  // }, [script]);

  if (isIE) {
    return (
      <Switch>
        <Route exact path="/unsupported" component={UnSupported} />
        <Redirect path="/requests/order" to="/unsupported" />
        <Redirect path="/" to="/unsupported" />
      </Switch>
    );
  } else {
    return (
      <Switch>
        {/*<Route exact path="/" component={IndexPage} />*/}
        <Route exact path="/toast" component={ToastTestPage} />
        <Route exact path="/requests/order" component={OrderPage} />
        <Route exact path="/requests/completed" component={CompletedPage} />
        <Route exact path="/requests/nopartner" component={NoPartnerPage} />
        <Route exact path="/requests/noservice" component={NoPartnerPage} />
        <Route exact path="/ppomppu" component={PpomppuPage} />
        <Route exact path="/unsupported" component={UnSupported} />
        <Route exact path="/error" component={ErrorService} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default function App() {
  return (
    <>
      <GlobalStyled />
      <Provider store={store}>
        <ConnectedRouter history={browserHistory}>
          <AppRoute />
        </ConnectedRouter>
      </Provider>
    </>
  );
}
