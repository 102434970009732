import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { find } from "lodash";

import GridCheckbox from "./gridCheckBox";

import {
  Furniture,
  HomeAppliance,
  Initialize,
  SmallAppliance,
} from "types/order";
import { DataType } from "components/common/categoryPopup";

interface Props {
  title?: string;
  details: DataType[];
  gId?: string;
  gValue: string;
  category: Furniture | HomeAppliance | SmallAppliance | Initialize;
  previousTempAnswer: any; // eslint-disable-line
  handleSetTempAnswer: any; // eslint-disable-line
}

const S = {
  Container: styled.div``,
  Details: styled.div`
    overflow: hidden;
    margin: 0 -1.5%;
    margin-bottom: 40px;
  `,
};

export default function SingleSelect({
  gId,
  gValue,
  details,
  category,
  previousTempAnswer,
  handleSetTempAnswer,
}: Props) {
  // 체크박스 체크 된 값 (중복된값 있으면 안됨)
  const [value, setValue] = useState<string>();
  // 그룹 아이디
  const [groupId, setGroupId] = useState<string>();
  // const [groupValue, setGroupValue] = useState<string>()
  const [id, setId] = useState<string>();
  // 라벨
  const [label, setLabel] = useState<string>();
  // 옵션값들
  const [key, setKey] = useState<string>();

  const onSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value);

    const tId = e.target.dataset.id;
    setId(tId); // id & value

    const gId = e.target.dataset.groupId;
    setGroupId(gId); // group id (ex: size ..)

    // const gValue = e.target.dataset.groupValue
    // setGroupValue(gValue)

    const key = e.target.dataset.key;
    setKey(key);

    const label = e.target.dataset.label;
    setLabel(label); // label
  };

  // useEffect(() => {
  //     setValue(undefined)
  // },[details])

  useEffect(() => {
    if (!value && !groupId && previousTempAnswer.length > 0) {
      const answer = find(previousTempAnswer, (t) => {
        return (
          t.category === category && t.group === gId && t.groupValue === gValue
        );
      });

      setId(answer?.id);
      setValue(answer?.category + answer?.id);
      setGroupId(answer?.group);
      setLabel(answer?.label);
      setKey(answer?.key);
    }
  }, [category, gId, gValue, groupId, previousTempAnswer, value]);

  useEffect(() => {
    if (value && groupId && category) {
      handleSetTempAnswer({
        id: id,
        group: gId,
        groupValue: gValue,
        label: label,
        category: category,
        key: key,
      });
    }
  }, [category, gId, gValue, groupId, handleSetTempAnswer, id, label, value]);

  return (
    <S.Container>
      <S.Details>
        {details?.map((detail, i) => {
          return (
            <GridCheckbox
              key={i}
              label={detail.label}
              isHalf={true}
              onChange={onSelectChange}
              checked={value === String(detail.category + detail.id)}
              value={detail.category + detail.id}
              data-id={detail.id}
              data-key={detail.key}
              data-label={detail.label}
              data-group-id={detail.group}
            />
          );
        })}
      </S.Details>
    </S.Container>
  );
}
