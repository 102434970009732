import {
  Furniture,
  Height,
  HomeAppliance,
  Kind,
  Material,
  Option,
  Size,
  Width,
} from "types/order";

const OrderOptions = [
  //침대
  { id: 1, category: Furniture.BED, group: "size", key: Size.S, label: "싱글" },
  {
    id: 2,
    category: Furniture.BED,
    group: "size",
    key: Size.SS,
    label: "슈퍼싱글",
  },
  { id: 3, category: Furniture.BED, group: "size", key: Size.D, label: "더블" },
  { id: 4, category: Furniture.BED, group: "size", key: Size.Q, label: "퀸" },
  { id: 5, category: Furniture.BED, group: "size", key: Size.K, label: "킹" },
  {
    id: 6,
    category: Furniture.BED,
    group: "material",
    key: Material.STEE,
    label: "철제",
  },
  {
    id: 7,
    category: Furniture.BED,
    group: "material",
    key: Material.WOOD,
    label: "목제",
  },
  {
    id: 8,
    category: Furniture.BED,
    group: "material",
    key: Material.NOFR,
    label: "프레임 없음",
  },
  {
    id: 9,
    category: Furniture.BED,
    group: "option",
    key: Option.DANDA_T,
    label: "필요",
  },
  {
    id: 10,
    category: Furniture.BED,
    group: "option",
    key: Option.DANDA_F,
    label: "필요 없음",
  },
  //옷장
  {
    id: 2000,
    category: Furniture.CLOS,
    group: "width",
    key: Width.W20,
    label: "100cm 미만",
  },
  {
    id: 2001,
    category: Furniture.CLOS,
    group: "width",
    key: Width.W21,
    label: "100cm~150cm",
  },
  {
    id: 2002,
    category: Furniture.CLOS,
    group: "width",
    key: Width.W22,
    label: "150~200cm",
  },
  {
    id: 2003,
    category: Furniture.CLOS,
    group: "width",
    key: Width.W30,
    label: "200cm 초과",
  },
  //책장
  {
    id: 3000,
    category: Furniture.BOOK,
    group: "width",
    key: Width.W10,
    label: "50cm 미만",
  },
  {
    id: 3001,
    category: Furniture.BOOK,
    group: "width",
    key: Width.W11,
    label: "50cm~100cm",
  },
  {
    id: 3002,
    category: Furniture.BOOK,
    group: "width",
    key: Width.W21,
    label: "100~150cm",
  },
  {
    id: 3003,
    category: Furniture.BOOK,
    group: "width",
    key: Width.W22,
    label: "150~200cm",
  },
  {
    id: 3004,
    category: Furniture.BOOK,
    group: "width",
    key: Width.W30,
    label: "200cm 초과",
  },
  {
    id: 3005,
    category: Furniture.BOOK,
    group: "height",
    key: Height.H10,
    label: "50cm 미만",
  },
  {
    id: 3006,
    category: Furniture.BOOK,
    group: "height",
    key: Height.H11,
    label: "50cm~100cm",
  },
  {
    id: 3007,
    category: Furniture.BOOK,
    group: "height",
    key: Height.H21,
    label: "100~150cm",
  },
  {
    id: 3008,
    category: Furniture.BOOK,
    group: "height",
    key: Height.H22,
    label: "150~200cm",
  },
  {
    id: 3009,
    category: Furniture.BOOK,
    group: "height",
    key: Height.H30,
    label: "200cm 초과",
  },
  //책상
  {
    id: 4000,
    category: Furniture.DESK,
    group: "kind",
    key: Kind.TABL_RECT,
    label: "사각",
  },
  {
    id: 4001,
    category: Furniture.DESK,
    group: "kind",
    key: Kind.TABL_CIRC,
    label: "원형",
  },
  {
    id: 4002,
    category: Furniture.DESK,
    group: "kind",
    key: Kind.TABL_LIBR,
    label: "독서실 책상",
  },
  {
    id: 4003,
    category: Furniture.DESK,
    group: "size",
    key: Size.COUPLE,
    label: "1~2인용",
  },
  {
    id: 4004,
    category: Furniture.DESK,
    group: "size",
    key: Size.FAMILY,
    label: "3~4인용",
  },
  //의자
  {
    id: 5000,
    category: Furniture.CHAI,
    group: "kind",
    key: Kind.CHAI_BACK,
    label: "등받이 의자",
  },
  {
    id: 5001,
    category: Furniture.CHAI,
    group: "kind",
    key: Kind.CHAI_ASSI,
    label: "보조 의자",
  },
  //테이블
  {
    id: 6000,
    category: Furniture.TABL,
    group: "kind",
    key: Kind.TABL_RECT,
    label: "사각",
  },
  {
    id: 6001,
    category: Furniture.TABL,
    group: "kind",
    key: Kind.TABL_CIRC,
    label: "원형",
  },
  {
    id: 6002,
    category: Furniture.TABL,
    group: "size",
    key: Size.COUPLE,
    label: "1~2인용",
  },
  {
    id: 6003,
    category: Furniture.TABL,
    group: "size",
    key: Size.FAMILY,
    label: "3~4인용",
  },
  //소파
  {
    id: 7000,
    category: Furniture.SOFA,
    group: "size",
    key: Size.COUPLE,
    label: "1~2인용",
  },
  {
    id: 7001,
    category: Furniture.SOFA,
    group: "size",
    key: Size.FAMILY,
    label: "3~4인용",
  },
  //화장대
  {
    id: 8000,
    category: Furniture.VANI,
    group: "kind",
    key: Kind.VANI_SHOR,
    label: "좌식",
  },
  {
    id: 8001,
    category: Furniture.VANI,
    group: "kind",
    key: Kind.VANI_REGU,
    label: "일반",
  },
  //수납장
  {
    id: 9000,
    category: Furniture.CABI,
    group: "kind",
    key: Kind.CABI_SHOE,
    label: "신발장",
  },
  {
    id: 9001,
    category: Furniture.CABI,
    group: "kind",
    key: Kind.CABI_DISP,
    label: "진열장",
  },
  {
    id: 9002,
    category: Furniture.CABI,
    group: "kind",
    key: Kind.CABI_TV,
    label: "TV 장식장",
  },
  //서랍장
  {
    id: 10000,
    category: Furniture.CHIF,
    group: "size",
    key: Size.LESS3,
    label: "3단 이하",
  },
  {
    id: 10001,
    category: Furniture.CHIF,
    group: "size",
    key: Size.MORE4,
    label: "4단 이상",
  },
  //TV & 모니터
  {
    id: 11000,
    category: HomeAppliance.DISP,
    group: "kind",
    key: Kind.DISP_TV,
    label: "일반 TV",
  },
  {
    id: 11001,
    category: HomeAppliance.DISP,
    group: "kind",
    key: Kind.DISP_WALL,
    label: "벽걸이 TV",
  },
  {
    id: 11002,
    category: HomeAppliance.DISP,
    group: "kind",
    key: Kind.DISP_MONI,
    label: "모니터",
  },
  {
    id: 11003,
    category: HomeAppliance.DISP,
    group: "option",
    key: Option.DETA_T,
    label: "탈부착 필요",
  },
  {
    id: 11004,
    category: HomeAppliance.DISP,
    group: "option",
    key: Option.DETA_F,
    label: "탈부착 불필요",
  },
  //세탁기
  {
    id: 12000,
    category: HomeAppliance.WASH,
    group: "kind",
    key: Kind.WASH_TOP,
    label: "통돌이 세탁기",
  },
  {
    id: 12001,
    category: HomeAppliance.WASH,
    group: "kind",
    key: Kind.WASH_FRON,
    label: "드럼 세탁기",
  },
  {
    id: 12002,
    category: HomeAppliance.WASH,
    group: "size",
    key: Size.LESS15,
    label: "15kg 이하",
  },
  {
    id: 12003,
    category: HomeAppliance.WASH,
    group: "size",
    key: Size.MORE15,
    label: "15kg 초과",
  },
  //건조기
  {
    id: 13000,
    category: HomeAppliance.DRYE,
    group: "size",
    key: Size.LESS15,
    label: "15kg 이하",
  },
  {
    id: 13001,
    category: HomeAppliance.DRYE,
    group: "size",
    key: Size.MORE15,
    label: "15kg 초과",
  },
  //에어컨
  {
    id: 14000,
    category: HomeAppliance.AC,
    group: "kind",
    key: Kind.AC_STAN,
    label: "스탠드형",
  },
  {
    id: 14001,
    category: HomeAppliance.AC,
    group: "kind",
    key: Kind.AC_WALL,
    label: "벽걸이형",
  },
  {
    id: 14002,
    category: HomeAppliance.AC,
    group: "option",
    key: Option.DISA_T,
    label: "분리작업 필요",
  },
  {
    id: 14003,
    category: HomeAppliance.AC,
    group: "option",
    key: Option.DISA_F,
    label: "분리작업 불필요",
  },
  //냉장고
  {
    id: 15000,
    category: HomeAppliance.FRID,
    group: "kind",
    key: Kind.FRID_MINI,
    label: "미니 (허리 높이 미만)",
  },
  {
    id: 15001,
    category: HomeAppliance.FRID,
    group: "kind",
    key: Kind.FRID_REGU,
    label: "일반형 (허리 높이 이상)",
  },
  {
    id: 15002,
    category: HomeAppliance.FRID,
    group: "kind",
    key: Kind.FRID_TWOD,
    label: "양문형",
  },
];

export default OrderOptions;
