import React from "react";
import styled, { css } from "styled-components";
import { Icon } from "components/common";

import colors from "styles/colors";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** 테마 설정 */
  theme: ThemeProps;
  /** border 여부 */
  border?: boolean;
  /** Search 아이콘 출력 여부 */
  icon?: IconProps;
  /** Disabled 여부 */
  disabled?: boolean;
  /** 최상단 스타일 custom */
  rootStyle?: React.CSSProperties;
  /** ref object **/
  ref?: React.RefObject<HTMLInputElement>;
  checked?: boolean;
}

type ThemeProps = "default" | "primary" | "error";
type BorderProp = boolean;
type IconProps = "search" | "down";

const S = {
  Container: styled.div`
    position: relative;
    display: block;

    svg {
      position: absolute;
      z-index: -1;
      right: 15px;
      bottom: 25px;
    }
  `,
  StyledInput: styled.input<{
    theme: ThemeProps;
    border: BorderProp;
    checked: boolean;
  }>`
    width: 100%;
    height: 56px;
    margin-bottom: 8px;
    padding: 0 35px 0 16px;
    font-size: 16px;
    line-height: 24px;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
    ${({ border }) =>
      border &&
      css`
        border-radius: 6px;
      `};

    ${({ theme }) =>
      theme === "default" &&
      css`
        color: ${colors.gray88};
        box-sizing: border-box;
        border: 1px solid ${colors.lineDefault};
      `};

    ${({ theme }) =>
      theme === "primary" &&
      css`
        border: solid 1px ${colors.pointBlue};
        &:focus {
          border: solid 1px ${colors.pointBlue};
          border-radius: 4px;
          color: ${colors.gray33};
          box-shadow: 0 4px 10px 4px rgba(0, 104, 255, 0.1);
          background-color: ${colors.white};
        }
      `};

    ${({ theme }) =>
      theme === "error" &&
      css`
        border: solid 1px ${colors.red};
        &:focus {
          border: solid 1px ${colors.red};
          border-radius: 6px;
          color: ${colors.gray33};
          box-shadow: 0 4px 10px 4px rgba(0, 104, 255, 0.1);
          background-color: ${colors.white};
        }
      `};

    &:disabled {
      background-color: ${colors.grayBg};
    }

    ${({ theme }) =>
      theme === "error" &&
      css`
        border: solid 1px ${colors.red};
        &:focus {
          border: solid 1px ${colors.red};
          border-radius: 4px;
          color: ${colors.gray33};
          box-shadow: 0 4px 10px 4px rgba(0, 104, 255, 0.1);
          background-color: ${colors.white};
        }
      `};

    &:disabled {
      background-color: ${colors.grayBg};
    }

    &:placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }

    &:focus {
      border: solid 1px ${colors.pointBlue};
      border-radius: 6px;
      color: ${colors.gray33};
      box-shadow: 0 4px 10px 4px rgba(0, 104, 255, 0.1);
    }

    ${(props) =>
      props.checked &&
      css`
        color: ${colors.gray33};
      `}
  `,
};

export default function Input({
  theme = "default",
  border = false,
  icon,
  disabled,
  rootStyle,
  ...restProps
}: InputProps) {
  return (
    <S.Container style={rootStyle}>
      <S.StyledInput
        theme={theme}
        border={border}
        disabled={disabled}
        checked={!!restProps.value}
        {...restProps}
      />
      {icon === "search" && <Icon.Search />}
      {icon === "down" && <Icon.Down width={24} height={24} />}
    </S.Container>
  );
}
