import * as React from "react";

function Interior({
  width = 56,
  height = 56,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 56 56"
      fill="fill"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.31 10.113a.974.974 0 011.39-.01l22.897 22.788a.998.998 0 01.291 1.262c-.046.097-.11.188-.188.27l-.016.015a.974.974 0 01-.977.255H44V48.04a1 1 0 01-1.292.957H14.292A1 1 0 0113 48.04v-5.151a1 1 0 012 0v4.108h27V33.693a1 1 0 011-1h5.532L28.02 12.28l-5.551 5.664a.975.975 0 01-1.399 0l-.015-.016a1.026 1.026 0 010-1.43l6.19-6.316a.993.993 0 01.05-.054l.015-.015z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.975 23.142c.051.555-.406 1.008-.963 1.008-.558 0-1.005-.453-1.067-1.008a8.98 8.98 0 10-1.718 6.368H18.63a1 1 0 110-2h5a1 1 0 011 1v5.015a1 1 0 11-2 0v-2.528a11 11 0 112.344-7.855z"
        fill="#1672F7"
      />
    </svg>
  );
}

export default Interior;
