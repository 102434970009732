import * as React from "react";

function LevelD({
  width = 56,
  height = 56,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="fill"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.856 28c0 15.464 12.536 28 28 28s28-12.536 28-28-12.536-28-28-28-28 12.536-28 28zm48 0c0 11.046-8.954 20-20 20s-20-8.954-20-20 8.954-20 20-20 20 8.954 20 20z"
        fill="#EBEEF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.122 36.28v1.06h-8.52v-1.06h5.82c.073-.313.123-.63.15-.95.026-.32.04-.597.04-.83v-.44h-4.9V33h6.16v1.43c0 .113-.005.245-.015.395a13.607 13.607 0 01-.1.97c-.024.17-.049.332-.075.485h1.44zm-9.966.049v-1.03h-4.84v-1.26h4.77v-1.03h-6.02v3.32h6.09zm1.22 1.71v-1.03h-8.52v1.03h8.52zm-3.105 4.115a6.1 6.1 0 01-1.155.105c-.407 0-.792-.035-1.155-.105a3.57 3.57 0 01-.955-.315 1.846 1.846 0 01-.65-.53 1.23 1.23 0 01-.24-.76v-.3c0-.287.08-.538.24-.755.16-.217.377-.395.65-.535a3.57 3.57 0 01.955-.315c.363-.07.748-.105 1.155-.105a6.1 6.1 0 011.155.105c.363.07.682.175.955.315.273.14.49.318.65.535.16.217.24.468.24.755v.3c0 .293-.08.547-.24.76-.16.213-.377.39-.65.53s-.592.245-.955.315zm-1.155-.915a3.74 3.74 0 01-.645-.055 2.716 2.716 0 01-.56-.155 1.28 1.28 0 01-.395-.24.427.427 0 01-.15-.32v-.14c0-.12.05-.227.15-.32.1-.093.232-.173.395-.24s.35-.118.56-.155a3.75 3.75 0 011.29 0c.21.037.397.088.56.155.163.067.295.147.395.24.1.093.15.2.15.32v.14c0 .12-.05.227-.15.32-.1.093-.232.173-.395.24s-.35.118-.56.155a3.74 3.74 0 01-.645.055zM35.932 42v-3.95h-1.25v.92h-3.64v-.92h-1.25V42h6.14zm-4.89-1.03h3.64v-1h-3.64v1z"
        fill="#D10707"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.856 28c0-9.758-7.462-19.555-20-20.114-2.21 0-4-1.765-4-3.943S26.646 0 28.856 0c15.464 0 28 12.536 28 28a27.894 27.894 0 01-7.02 18.543l-5.45-5.75c3.318-4.092 4.47-9.136 4.47-12.793z"
        fill="url(#prefix__level_d)"
      />
      <circle cx={46.978} cy={43.78} r={4} fill="#D10707" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.856 29V13h5.33c1.462 0 2.776.32 3.943.961a6.87 6.87 0 012.733 2.715c.655 1.168.986 2.478.994 3.928v.737c0 1.465-.32 2.778-.96 3.94a6.894 6.894 0 01-2.705 2.725c-1.163.655-2.46.987-3.892.994h-5.443zm3.989-13.022v10.055h1.386c1.144 0 2.023-.394 2.636-1.181.614-.788.92-1.958.92-3.511v-.693c0-1.545-.306-2.71-.92-3.494-.613-.784-1.507-1.176-2.681-1.176h-1.341z"
        fill="#FA3C3C"
      />
      <defs>
        <linearGradient
          id="prefix__level_d"
          x1={56.856}
          y1={0}
          x2={56.856}
          y2={46.543}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD6F6F" />
          <stop offset={1} stopColor="#FA3C3C" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LevelD;
