import * as React from "react";

function Chair({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.004 3.673A.75.75 0 016.75 3h7.5a.75.75 0 010 1.5H7.5V12h9V3.75a.75.75 0 011.5 0V12h2.25a.75.75 0 01.75.75v3a.75.75 0 01-.75.75H18v5.25a.75.75 0 01-1.5 0V16.5h-9v5.25a.75.75 0 01-1.5 0V16.5H3.75a.75.75 0 01-.75-.75v-3a.75.75 0 01.75-.75H6V3.75c0-.026.001-.051.004-.077zM4.5 15h15v-1.5h-15V15z"
        fill={fill}
      />
    </svg>
  );
}

export default Chair;
