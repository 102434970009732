import React from "react";
import styled, { css } from "styled-components";

import colors from "styles/colors";

interface Props {
  icon?: React.ReactNode;
  label: string;
  checked: boolean;
  onClick?: () => void;
}

const S = {
  Container: styled.div<{ checked: boolean }>`
    float: left;
    width: 30.3%;
    height: 80px;
    margin: 0 1.5%;
    margin-bottom: 10px;
    border: 1px solid ${colors.lineDefault};
    border-radius: 8px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    ${(props) =>
      props.checked &&
      css`
        border: 1px solid ${colors.pointBlue};
        box-shadow: 0 4px 10px rgba(22, 114, 247, 0.25);
      `};
  `,
  Item: styled.div<{ checked: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    p {
      margin-top: 5px;
      font-size: 16px;
      ${(props) =>
        props.checked &&
        css`
          color: ${colors.pointBlue};
          font-weight: bold;
        `};
    }
  `,
};

export default function CategoryItem({ icon, label, checked, onClick }: Props) {
  return (
    <S.Container {...{ label, checked }} onClick={onClick}>
      <S.Item {...{ checked }}>
        {icon}
        <p {...{ checked }}>{label}</p>
      </S.Item>
    </S.Container>
  );
}
