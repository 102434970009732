import * as React from "react";

function Microwave({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.5a.75.75 0 01.75-.75h19.5a.75.75 0 010 1.5H15v13.5h7.5V4.5a.75.75 0 011.5 0v15a.75.75 0 01-.75.75H.75A.75.75 0 010 19.5v-15zm1.5.75h12v13.5h-12V5.25zM21 7.5a.75.75 0 00-.75-.75h-3a.75.75 0 000 1.5h3A.75.75 0 0021 7.5zm-1.5 8.014a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm1.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
        fill={fill}
      />
    </svg>
  );
}

export default Microwave;
