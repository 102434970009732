import * as React from "react";

function Purifier({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.015 3.599A.75.75 0 013.75 3h13.5a.75.75 0 010 1.5H4.5v18H6v-9.75a.75.75 0 01.75-.75h10.5a.75.75 0 01.75.75v9.75h1.5V3.75a.75.75 0 011.5 0v19.5a.75.75 0 01-.75.75H3.75a.747.747 0 01-.75-.75V3.75c0-.052.005-.102.015-.151zM7.5 22.5v-9h3.75v2.25a.75.75 0 001.5 0V13.5h3.75v9h-2.46l.91-3.398a.75.75 0 00-.039-.624.748.748 0 00-.755-.396H9.845a.748.748 0 00-.713.325.747.747 0 00-.08.702l.91 3.391H7.5zm3.232-2.918l.782 2.918h.973l.781-2.918h-2.536z"
        fill={fill}
      />
    </svg>
  );
}

export default Purifier;
