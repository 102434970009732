import React from "react";

import Input from "components/common/input";

interface Props {
  placeholder: string;
  onChange: (e: any) => void;
  value: string;
}
const InputText = ({ placeholder, onChange, value }: Props) => (
  <Input
    type={"text"}
    theme={"default"}
    placeholder={placeholder}
    border
    style={{ backgroundColor: "transparent", fontSize: 16, paddingRight: 40 }}
    onChange={onChange}
    value={value}
  />
);

export default InputText;
