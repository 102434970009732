import * as React from "react";

function Washing({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 0a.75.75 0 00-.75.75v22.5c0 .414.336.75.75.75h19.5a.75.75 0 00.75-.75V.75a.75.75 0 00-1.5 0V6H3V1.5h15.75a.75.75 0 000-1.5H2.25zM3 7.5h18v15H3v-15zM16.5 15a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM12 21a6 6 0 100-12 6 6 0 000 12z"
        fill={fill}
      />
    </svg>
  );
}

export default Washing;
