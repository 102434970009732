import { createSelector } from "reselect";
import { RootState } from "../store";

const getOrderState = (state: RootState) => state.orderState;

export const getCurrentStep = createSelector(
  getOrderState,
  (state) => state.currentStep
);
export const getOrderForm = createSelector(
  getOrderState,
  (state) => state.orderForm
);
export const getSubmittedOrder = createSelector(
  getOrderState,
  (state) => state.submittedOrder
);
export const getPreviousTempAnswers = createSelector(
  getOrderState,
  (state) => state.tempAnswerValues
);
export const getTempAnswersCount = createSelector(
  getOrderState,
  (state) => state.tempAnswerCount
);
export const getTempAnswerEq = createSelector(
  getOrderState,
  (state) => state.tempAnswerEq
);
export const getTempLocation = createSelector(
  getOrderState,
  (state) => state.tempLocation
);
export const getTempContact = createSelector(
  getOrderState,
  (state) => state.tempContact
);
