import * as React from "react";

function Vanity({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12.65 4.681a.75.75 0 01.274 1.025l-1.5 2.598a.75.75 0 01-1.299-.75l1.5-2.598a.75.75 0 011.025-.275zM14.575 7.337a.75.75 0 00-1.3-.75l-2.25 3.897a.75.75 0 001.3.75l2.25-3.897z"
        fill="{fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 7.715c0 3.314-2.35 6-5.25 6s-5.25-2.686-5.25-6c0-3.313 2.35-6 5.25-6s5.25 2.687 5.25 6zm-1.5 0c0 2.68-1.86 4.5-3.75 4.5s-3.75-1.82-3.75-4.5c0-2.68 1.86-4.5 3.75-4.5s3.75 1.82 3.75 4.5zM12 13.715h9.75a.75.75 0 01.75.75v7.5a.75.75 0 01-1.5 0v-3.75H3v3.75a.75.75 0 01-1.5 0v-7.5a.75.75 0 01.75-.75H12zm9 1.5v1.5h-8.25v-1.5H21zm-9.75 0H3v1.5h8.25v-1.5z"
        fill={fill}
      />
    </svg>
  );
}

export default Vanity;
