import * as React from "react";

function CheckCircleOff({
  width = 24,
  height = 24,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      {...props}
    >
      <path
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12 6.201 22.5 12 22.5zm0 1.5c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
        fill="#C4C9D1"
      />
      <path
        d="M6.22 9.841a.75.75 0 011.06 0l3.713 3.712 5.833-5.833a.75.75 0 111.06 1.06l-6.277 6.278a.872.872 0 01-1.233 0L6.22 10.902a.75.75 0 010-1.061z"
        fill="#C4C9D1"
      />
    </svg>
  );
}

export default CheckCircleOff;
