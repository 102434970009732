import * as React from "react";

function Next({
  width = 16,
  height = 16,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M5.854 2.647a.498.498 0 00-.708 0 .504.504 0 000 .71l4.647 4.668-4.596 4.617a.504.504 0 000 .71.498.498 0 00.707 0l4.95-4.972a.504.504 0 00-.026-.734.525.525 0 00-.025-.026l-4.95-4.973z"
        fill={fill}
      />
    </svg>
  );
}

export default Next;
