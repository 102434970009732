import React, { useState } from "react";
import styled from "styled-components";
import colors from "styles/colors";
import {
  CheckCircleOn,
  CheckCircleOff,
  Down,
  Up,
} from "components/common/Icon";
import CheckOn from "./CheckOn";
import TermsModal from "../modal/TermsModal";
import PrivacyModal from "../modal/PrivacyModal";

interface Props {
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

const iconColor = "#c4c9d1";
const S = {
  Container: styled.div`
    margin-bottom: 20px;

    span {
      font-size: 15px;
      line-height: 22px;
      letter-spacing: -1px;
      color: ${colors.gray66};
      margin-top: 2px;
    }
  `,
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  DropIcon: styled.div`
    cursor: pointer;
  `,
  List: styled.div`
    width: 100%;
    margin-left: 32px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -1px;
    color: ${colors.gray88};
  `,
  ListItem: styled.div`
    margin-top: 8px;
    span {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -1px;
      color: ${colors.gray88};
    }
    .underline {
      text-decoration-line: underline;
      cursor: pointer;
    }
  `,
};
const C = {
  Container: styled.label`
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    margin-bottom: 2px;
    margin-right: 6px;
    cursor: pointer;

    .icon {
      flex-shrink: 0;
    }
  `,
  HiddenCheckbox: styled.input`
    display: none;
  `,
};

const RequiredConsent: React.FC<Props> = (props: Props) => {
  const { checked, setChecked } = props;
  const [show, setShow] = useState(false);
  const [visibleModal, setVisibleModal] = useState({
    temrs: false,
    privacy: false,
  });

  const SubChecked = () => {
    return (
      <CheckOn
        size={12}
        color={checked ? colors.pointBlue : iconColor}
        style={{ marginRight: "8px" }}
      />
    );
  };
  return (
    <>
      <S.Container>
        <S.Wrapper>
          <div>
            <C.Container>
              {checked ? (
                <CheckCircleOn width={22} height={22} className="icon" />
              ) : (
                <CheckCircleOff
                  width={22}
                  height={22}
                  className="icon"
                  color={iconColor}
                />
              )}
              <C.HiddenCheckbox
                type="checkbox"
                onChange={() => setChecked(!checked)}
              />
            </C.Container>
            <span>필수 약관 전체동의</span>
          </div>
          <S.DropIcon onClick={() => setShow(!show)}>
            {show ? (
              <Up width={16} height={16} color={iconColor} />
            ) : (
              <Down width={16} height={16} color={iconColor} />
            )}
          </S.DropIcon>
        </S.Wrapper>
        {show && (
          <S.List>
            <S.ListItem>
              <SubChecked />
              (필수){" "}
              <span
                className="underline"
                onClick={() =>
                  setVisibleModal({ ...visibleModal, temrs: true })
                }
              >
                이용약관
              </span>
              동의
            </S.ListItem>
            <S.ListItem>
              <SubChecked />
              (필수){" "}
              <span
                className="underline"
                onClick={() =>
                  setVisibleModal({ ...visibleModal, privacy: true })
                }
              >
                개인정보 수집 및 이용
              </span>
              동의
            </S.ListItem>
            <S.ListItem>
              <SubChecked />
              (필수) 만 14세 이상입니다
            </S.ListItem>
          </S.List>
        )}
      </S.Container>
      <TermsModal
        visible={visibleModal.temrs}
        onClose={() =>
          setVisibleModal({ ...visibleModal, temrs: !visibleModal.temrs })
        }
      />
      <PrivacyModal
        visible={visibleModal.privacy}
        onClose={() =>
          setVisibleModal({ ...visibleModal, privacy: !visibleModal.privacy })
        }
      />
    </>
  );
};

export default RequiredConsent;
