import { createAction, createAsyncAction } from "typesafe-actions";
import {
  Furniture,
  HomeAppliance,
  Initialize,
  Order,
  SmallAppliance,
  TempAnswer,
  TempAnswerCount,
  TempAnswerEq,
  TempLocation,
  TempContact,
  SubmittedOrder,
  CategoryName,
} from "types/order";

export const setCurrentStep =
  createAction("SET_CURRENT_STEP")<{ currentStep: number }>();
export const setOrderForm = createAction("SET_ORDER_FORM")<{ order: Order }>();
export const setDistance = createAction("SET_DISTANCE")();
export const removeCategory =
  createAction("REMOVE_CATEGORY")<{
    categoryName: CategoryName;
    category: Furniture | HomeAppliance | SmallAppliance | Initialize;
  }>();
export const setTempAnswer = createAction("SET_TEMP_ANSWER")<TempAnswer>();
export const removeTempAnswer =
  createAction("REMOVE_TEMP_ANSWER")<{
    category: Furniture | HomeAppliance | SmallAppliance | Initialize;
    groupValue?: string;
  }>();
export const setTempAnswerCount = createAction(
  "SET_TEMP_ANSWER_COUNT"
)<TempAnswerCount>();
export const setTempAnswerEq =
  createAction("SET_TEMP_ANSWER_EQ")<TempAnswerEq>();
export const removeTempAnswerEq = createAction("REMOVE_TEMP_ANSWER_EQ")<{
  category: Furniture | HomeAppliance | SmallAppliance | Initialize;
  id: number;
}>();
export const setTempLocation = createAction("SET_TEMP_ADDRESS")<TempLocation>();
export const setTempContact = createAction("SET_TEMP_CONTACT")<TempContact>();
export const resetAll = createAction("RESET_ALL")();

export const submitOrderAsync = createAsyncAction(
  "FETCH_SUBMIT_ORDER_REQUEST",
  "FETCH_SUBMIT_ORDER_SUCCESS",
  "FETCH_SUBMIT_ORDER_FAILURE"
)<{ order: Order; token: string | null }, SubmittedOrder, undefined>();

export const getOrderAsync = createAsyncAction(
  "FETCH_ORDER_REQUEST",
  "FETCH_ORDER_SUCCESS",
  "FETCH_ORDER_FAILURE"
)<string, SubmittedOrder, undefined>();
