import ReactPixel from "react-facebook-pixel";
import { getAreaLevel } from "./areaLevel";

import * as sentry from "@sentry/react";
import { Severity } from "@sentry/react";

export const sendPixelAreaAvgPrice = (sido: string, gugun: string) => {
  let areaLevel = null;
  try {
    getAreaLevel(sido, gugun).then((result) => {
      areaLevel = result;
      if (areaLevel && areaLevel.value !== 0) {
        const name = `접수_원룸_${areaLevel.level}`;
        ReactPixel.fbq("trackCustom", name, {
          value: areaLevel.value,
          currency: "KRW",
        });
      } else {
        throw "매핑 지역이 없거나, 이사 구분 없음";
      }
    });
  } catch (e) {
    sentry.setExtra("juso", {
      areaLevel: JSON.stringify(areaLevel),
      moveType: "원룸",
      juso: `${sido}${gugun}`,
    });
    sentry.captureMessage("픽셀 지역평균순차감 트래킹 실패", {
      level: Severity.Error,
    });
    sentry.captureException(e);
  }
};
