import React from "react";
import styled from "styled-components";
import colors from "styles/colors";

const StarRating = ({ score }: { score: number }) => {
  return (
    <Wrapper>
      <img src={require("assets/images/star.svg")} alt="" />
      <span>{score.toFixed(1)}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  img {
    align-self: center;
  }
  span {
    font-size: 14px;
    font-weight: 700;
    color: ${colors.gray33};
    margin-left: 2px;
  }
`;

export default StarRating;
