import React from "react";
import styled from "styled-components";

import CheckCircleOn from "components/common/Icon/CheckCircleOn";
import CheckCircleOff from "components/common/Icon/CheckCircleOff";
import CheckCircleFillNormal from "components/common/Icon/CheckCircleFillNormal";
import CheckCircleDone from "components/common/Icon/CheckCircleDone";
import RadioOn from "components/common/Icon/RadioOn";
import RadioOff from "components/common/Icon/RadioOff";

import colors from "styles/colors";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  shape?: "radio" | "checkbox";
  className?: string;
  checked?: boolean;
  defaultTheme?: boolean;
}

const S = {
  Container: styled.label`
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    .icon {
      flex-shrink: 0;
    }
  `,
  LabelText: styled.span<{ checked: boolean }>`
    display: inline-block;
    font-size: 15px;
    word-break: keep-all;
    font-weight: ${(props) => (props.checked ? "bold" : "normal")};
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    padding-top: 3px;
    color: ${(props) => (props.checked ? colors.pointBlue : colors.gray33)};
    margin-left: 8px;
    user-select: none;
  `,
  HiddenCheckbox: styled.input`
    display: none;
  `,
};

export default function Checkbox({
  checked = false,
  className,
  shape = "checkbox",
  label,
  defaultTheme = false,
  ...restProps
}: Props) {
  const IconOn =
    shape === "radio"
      ? RadioOn
      : defaultTheme
      ? CheckCircleOn
      : CheckCircleDone;
  const IconOff =
    shape === "radio"
      ? RadioOff
      : defaultTheme
      ? CheckCircleFillNormal
      : CheckCircleOff;
  return (
    <S.Container className={className}>
      {checked ? <IconOn className="icon" /> : <IconOff className="icon" />}
      {label && <S.LabelText checked={checked}>{label}</S.LabelText>}
      <S.HiddenCheckbox type="checkbox" {...restProps} />
    </S.Container>
  );
}
