import * as React from "react";

function Chiffonier({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 .75A.748.748 0 013.75 0h13.5a.75.75 0 010 1.5H4.5V9h15V.75a.75.75 0 011.5 0v21a.75.75 0 01-1.5 0V19.5h-15v2.25a.75.75 0 01-1.5 0v-21zM19.5 18v-7.5h-15V18h15zM11.25 4.5a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5h-1.5zm-.75 9.75a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75z"
        fill={fill}
      />
    </svg>
  );
}

export default Chiffonier;
