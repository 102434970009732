import * as React from "react";

function LevelS({ width = 56, height = 56, fill = "none", ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 28c0 15.464 12.536 28 28 28s28-12.536 28-28S43.464 0 28 0 0 12.536 0 28zm48 0c0 11.046-8.954 20-20 20S8 39.046 8 28 16.954 8 28 8s20 8.954 20 20zm-20.62 7.18h-5.06v-1.23h5.01V32.9h-6.32v3.32h6.37v-1.04zm-3.25 5.68c-1.23 0-1.86-.21-1.86-.69s.63-.7 1.86-.7 1.86.22 1.86.7-.63.69-1.86.69zm0-2.39c-1.99 0-3.19.61-3.19 1.7 0 1.08 1.2 1.69 3.19 1.69s3.2-.61 3.2-1.69c0-1.09-1.21-1.7-3.2-1.7zM20 36.86v1.08h8.33v-.64h8.2v-1.06h-1.18c.2-1.03.2-1.76.2-2.48v-.74h-6.33v1.05h5.03c0 .61-.03 1.3-.21 2.17h-5.85v.62H20zm14.21 3.84h-3.72v-.83h3.72v.83zm0-1.85h-3.72v-.96h-1.31v3.87h6.35v-3.87h-1.32v.96zM29.76 24.364c.38.306.57.734.57 1.283 0 .485-.176.861-.528 1.128-.352.268-.83.402-1.437.402-.948 0-1.633-.198-2.054-.594-.422-.396-.633-1.01-.633-1.845H22c0 1.027.253 1.934.758 2.722s1.258 1.408 2.258 1.86c1 .454 2.116.68 3.35.68 1.749 0 3.125-.389 4.129-1.166C33.498 28.057 34 26.988 34 25.626c0-1.704-.822-3.041-2.467-4.011-.676-.4-1.54-.772-2.592-1.118-1.052-.346-1.788-.68-2.206-1.005-.418-.324-.627-.686-.627-1.086 0-.456.19-.832.57-1.128.38-.296.897-.444 1.552-.444.648 0 1.162.175 1.542.524.38.35.57.842.57 1.476H34c0-.948-.24-1.791-.721-2.53-.481-.737-1.155-1.306-2.023-1.705-.867-.4-1.848-.599-2.942-.599-1.122 0-2.133.184-3.032.55-.899.368-1.594.881-2.085 1.54-.491.66-.737 1.422-.737 2.284 0 1.733.986 3.095 2.958 4.086.606.306 1.387.624 2.342.952.954.328 1.621.645 2.001.952z"
        fill="#1672F7"
      />
    </svg>
  );
}

export default LevelS;
