import React from "react";
import Styled, { css } from "styled-components";

import colors from "styles/colors";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  /** 테마 정의 */
  theme: "default" | "primary";
  /** 비활성화 여부 */
  disabled?: boolean;
  border?: boolean;
  bold?: boolean;
}

const S = {
  StyledButton: Styled.button<{ border: boolean; bold: boolean }>`
        display: block;
        width: 100%;
        height: 56px;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: -0.03em;
        font-weight: ${(props) => (props.bold ? "bold" : "400")};
        text-align: center;
        cursor: pointer;
        
        ${({ theme }) =>
          theme === "default" &&
          css`
            background-color: ${colors.white};
            color: ${colors.black};
          `}
        
        ${({ theme }) =>
          theme === "primary" &&
          css`
            background-color: ${colors.pointBlue};
            color: ${colors.white};
          `}
        
        ${({ border }) =>
          border &&
          css`
            border-radius: 8px;
          `}
        
        &:disabled {
            background-color: ${colors.lineDefault};
            cursor: not-allowed;
        }
    `,
};

const Button: React.FC<Props> = (props) => {
  const {
    theme = "default",
    disabled = false,
    border = false,
    bold = false,
    children,
    ...restProps
  } = props;

  return (
    <S.StyledButton
      theme={theme}
      disabled={disabled}
      border={border}
      bold={bold}
      {...restProps}
    >
      {children}
    </S.StyledButton>
  );
};

export default Button;
