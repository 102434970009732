import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import * as Icon from "components/common/Icon";
import { Juso, JUSO_TYPE } from "types/address";

import colors from "styles/colors";
import { checkApp, checkMobile } from "lib/checkDevice";
import { isEmpty, throttle } from "lodash";

interface Props
  extends Omit<React.HTMLAttributes<HTMLUListElement>, "onSelect"> {
  /** 텍스트 클릭 이벤트 */
  onClick?: () => void;
  /** 아이템 정의 */
  addresses: Juso[] | undefined;
  onSelect?(juso: Juso, type: JUSO_TYPE): void;
  onMoreAddresses?: () => void;
  loading: boolean;
}

export type ItemsProps = {
  id: number;
  label: string;
  value: string;
};

const S = {
  Ul: styled.ul<{ isEmpty: boolean }>`
    display: flex;
    flex-direction: column;
    // max-height: calc(${window.innerHeight}px - 99px - 48px);
    max-height: calc(${window.innerHeight}px - 200px);
    overflow-y: auto;

    @media (min-width: 768px) {
      //max-height: 1200px;
      height: ${(props) => (props.isEmpty ? "0" : "350px")};
      //overflow-y: auto;
    }
    //@media (min-width: 1200px) {
    //overflow-y: auto;
    //}
  `,
  Li: styled.li<{ isApp: boolean }>`
    font-size: 12px;
    display: inline-block;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-top: 1px solid #d8d8d8;
    word-break: keep-all;
    &:first-child {
      border: none;
      //margin-bottom: 100px;
    }
  `,
  Content: styled.div`
    margin: 24px 3px 24px 0;
  `,
  Line: styled.div`
    height: fit-content;
    margin-bottom: 10px;
    display: flex;
  `,
  Tag: styled.div`
    font-size: 11px;
    width: 34px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    color: ${colors.pointBlue};
    border: 1px solid ${colors.pointBlue};
    border-radius: 3px;
    margin-right: 8px;
  `,
  A: styled.a`
    width: 100%;
    letter-spacing: -1px;
    font-size: 14px;
    line-height: 1.2;
    color: ${colors.gray33};
    em {
      color: ${colors.pointBlue};
    }
    &:last-child {
      //margin-bottom: 25px;
    }
  `,
  Spinner: styled.div`
    margin: 0 auto 10px;
  `,
};

const List: React.FC<Props> = (props) => {
  const {
    onClick,
    addresses,
    onSelect,
    onMoreAddresses,
    loading,
    ...restProps
  } = props;

  const scrollRef = useRef<HTMLUListElement>(null);

  const handleOnSelect = (juso: Juso, type: JUSO_TYPE) => {
    if (onSelect) {
      return onSelect(juso, type);
    }
  };

  const handleScroll = throttle(() => {
    const scrollTop = scrollRef?.current?.scrollTop || 0;
    const clientHeight = scrollRef?.current?.clientHeight || 0;
    const scrollHeight = scrollRef?.current?.scrollHeight || 0;
    if (scrollTop + clientHeight >= scrollHeight - 10 && onMoreAddresses) {
      onMoreAddresses();
    }
  }, 300);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <S.Ul
      ref={scrollRef}
      onScroll={handleScroll}
      isEmpty={isEmpty(addresses)}
      {...restProps}
    >
      {addresses?.map((juso, index) => {
        return (
          <S.Li key={index} isApp={checkMobile() || checkApp()}>
            <S.Content>
              <S.Line onClick={() => handleOnSelect(juso, "road")}>
                <S.Tag>도로명</S.Tag>
                <S.A>{juso.roadAddr}</S.A>
              </S.Line>
              <S.Line onClick={() => handleOnSelect(juso, "jibun")}>
                <S.Tag>지번</S.Tag>
                <S.A>{juso.jibunAddr}</S.A>
              </S.Line>
            </S.Content>
          </S.Li>
        );
      })}
      <S.Spinner>
        {loading && (
          <img
            src={require("assets/images/wematch_spinner.svg")}
            alt="로딩중"
          />
        )}
      </S.Spinner>
    </S.Ul>
  );
};

export default List;
