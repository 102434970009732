import * as React from "react";

function Up({
  width = 16,
  height = 16,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="fill"
      {...props}
    >
      <path
        d="M2.647 10.146a.498.498 0 000 .708.504.504 0 00.71 0l4.668-4.647 4.617 4.596a.504.504 0 00.71 0 .498.498 0 000-.707L8.38 5.146a.504.504 0 00-.734.026.512.512 0 00-.026.025l-4.973 4.95z"
        fill="#121212"
      />
    </svg>
  );
}

export default Up;
