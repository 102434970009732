import React, { useState } from "react";
import styled, { css } from "styled-components";

const S = {
  Section: styled.section``,
  Title: styled.h3`
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
  `,
  SubTitle: styled.p`
    margin-bottom: 8px;
    font-size: 15px;
  `,
  Container: styled.div`
    overflow: hidden;
    margin: 0 -1.5%;
    margin-bottom: 40px;
  `,
  Option: styled.div<{ isHalf: boolean; checked: boolean }>`
    float: left;
    width: ${(props) => (!props.isHalf ? "30.3%" : "47%")};
    height: ${(props) => (!props.isHalf ? "80px" : "56px")};
    margin: 0 1.5%;
    margin-bottom: 10px;
    border: 1px solid #d7dbe2;
    border-radius: 8px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;

    p {
      font-size: 16px;
      line-height: ${(props) => (!props.isHalf ? "84px" : "60px")};
    }
    ${(props) =>
      props.checked &&
      css`
        border: 1px solid #1672f7;
        box-shadow: 0px 4px 10px rgba(22, 114, 247, 0.25);
      `};
  `,
};

interface CategoryListProps {
  lists: {
    key: string;
    value: string;
    component?: React.ReactNode;
  }[];
  title?: string;
  subTitle?: string;
  isHalf: boolean;
  onChange?: (id: string, label: string) => void;
}

export default function OptionList({
  lists,
  title,
  subTitle,
  isHalf,
  onChange,
}: CategoryListProps) {
  const [index, setIndex] = useState<number | undefined>(undefined);

  return (
    <S.Section>
      <S.Title>{title}</S.Title>
      {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
      <S.Container>
        {lists.map((l, i) => (
          <S.Option
            key={i}
            isHalf={isHalf}
            onClick={() => {
              setIndex(i);

              if (onChange) {
                onChange(l.key, l.value);
              }
            }}
            checked={index === i}
          >
            <p>{l.value}</p>
          </S.Option>
        ))}
      </S.Container>
    </S.Section>
  );
}
