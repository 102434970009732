import React, { useState } from "react";
import Styled from "styled-components";
import colors from "styles/colors";
import { Down, Up } from "components/common/Icon";
import PopupTemplate from "./PopupTemplate";
import styled from "styled-components";

interface Props {
  visible: boolean;
  onClose: () => void;
}

const PrivacyHistoryModal: React.FC<Props> = (props) => {
  const { visible = false, onClose } = props;
  const [show, setShow] = useState({
    1: false,
  });

  return (
    <PopupTemplate visible={visible} onClose={onClose}>
      <S.Container>
        <S.Wrapper>
          <S.ListItem>
            <S.ListTitle>
              1차 개인정보처리방침
              <br />
              (2015년 11월 25일)
            </S.ListTitle>
            <S.ListButton onClick={() => setShow({ ...show, 1: !show[1] })}>
              {show[1] ? <Up width={16} height={16} color={colors.gray33} /> : <Down width={16} height={16} color={colors.gray33} />}
            </S.ListButton>
          </S.ListItem>
          {show[1] && (
            <S.ListInfo>
              <p>
                (주)다이사(이하 “회사”)는 위매치 서비스(이하 “서비스”)를 제공함에 있어 귀하의 개인정보보호를 매우 중요시하며, 『개인정보보호법』,『정보통신망
                이용촉진 및 정보보호에 관한 법률』상의 개인정보보호규정 및 정보통신부가 제정한 『개인정보보호지침』을 준수하고 있습니다. 회사는
                개인정보처리방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지
                알려드립니다.
              </p>
              <S.Section>
                <h4>1. 개인정보의 수집에 대한 동의</h4>
                <p>
                  회사는 이용자가 회사의 개인정보처리방침 또는 이용약관의 내용에 대하여 「동의」 또는 「동의 안함」을 선택할 수 있는 절차를 마련하여, 「동의」를
                  선택하면 개인정보 수집에 대해 동의한 것으로 봅니다.
                </p>
                <p>단, 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 법령에 따라 이와 같은 동의 없이 이용자의 개인정보를 수집∙이용할 수 있습니다.</p>
                <ul>
                  <ul>
                    <li>
                      (1) 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적∙기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게
                      곤란한 경우
                    </li>
                    <li>(2) 정보통신서비스 제공에 따른 요금정산을 위하여 필요한 경우</li>
                    <li>(3) 그 밖에 법률에 특별한 규정이 있는 경우</li>
                  </ul>
                </ul>
              </S.Section>
              <S.Section>
                <h4>2. 개인정보의 수집목적 및 이용, 제공</h4>
                <p>
                  2-1. &quot;개인정보&quot;라 함은 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주소, 전화번호 등의 사항에 의하여 당해 개인을
                  식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다.
                  회사는 이용자의 사전 동의 없이는 이용자의 개인 정보를 함부로 공개하지 않으며, 수집된 정보는 아래와 같이 이용하고 있습니다.
                </p>
                <ul>
                  <ul>
                    <li>
                      (1) 서비스 제공에 관한 계약 이행 및 요금 정산 <br />
                      이용자의 기본정보를 바탕으로 위매치 서비스 회원사(이하 “회원사”)의 서비스 견적 비교제공 및 견적 산출관리 활용, “회원사”의 서비스진행을
                      위한 사전연락, 계약 이행을 위한 방문 견적 진행을 위한 용도로 쓰입니다.
                    </li>
                    <li>
                      (2) 이용자 사후관리 <br />
                      계약이행 후 이용자의 회사 및 “회원사”에 대한 피드백 반영을 위한 해피콜, A/S이행, 애프터서비스 및 서비스 완료 후 평가 작성을 위한 이용자
                      확인 용도로 활용됩니다.
                    </li>
                    <li>
                      (3) 신규서비스 개발 및 마케팅, 광고에서의 활용 <br />
                      이용자가 제공한 개인정보는 회사 내에 공유되어 더 유용한 서비스를 이용자에게 제공해 드립니다. <br />
                      신규서비스 개발 및 고객맞춤서비스 제공, 통계학적 특성에 따른 서비스 제공, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보
                      제공, 접속빈도 파악, 이용자의 서비스이용에 대한 통계 등의 용도로 활용됩니다. 또한 회사의 서비스와 관련된 뉴스, 이벤트, 업데이트 정보 등의
                      소식을 받아보실 수 있도록 이용자의 동의를 구하고 발송하는 회사의 이메일링, SMS수신서비스를 보다 원활하게 제공하기 위한 목적으로
                      사용됩니다. 회사는 이용자가 서비스 이용 시 마케팅 활용 동의 절차에서 「동의」를 선택함으로써 상기 이벤트 정보 및 광고성 정보 제공 등의
                      목적으로 활용함에 동의한 것으로 봅니다.
                    </li>
                  </ul>
                </ul>
              </S.Section>
              <S.Section>
                <h4>3. 회사가 수집하는 개인정보 항목 및 수집방법</h4>
                <p>
                  3-1. 회사가 제공하는 서비스를 이용하기 위해서는 각 서비스의 종류에 따라 회원가입이나 실명인증이 필요할 수 있으며 본 약관에서는 회원용 서비스를
                  위한 공통 수집항목을 고지하고 있습니다. 수집항목은 각 서비스에 따라 달라질 수 있으며 해당 서비스의 별도 약관 및 서비스 신청 과정에서 확인할 수
                  있습니다.
                </p>
                <p>3-1-1. 개인정보 수집항목</p>
                <ul>
                  <ul>
                    <li>(1) 필수항목: 이름, 전화번호(휴대폰번호), 출발지주소, 도착지주소, 이사일자 또는 청소일자</li>
                    <li>(2) 선택항목1: 평수 및 통계학적 정보 (기타 서비스 이용 증진 목적)</li>
                    <li>(3) 선택항목2: 고객-상담원간 상담내용 녹취 (고객 상담 시)</li>
                    <li>(4) 선택항목3: 고객-회원사간 통화내용 녹취 (서비스를 통해 050번호로 통화 시)</li>
                  </ul>
                </ul>
                <p>
                  3-2. 회사는 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록,
                  건강상태 및 성생활 등)는 가급적 수집하지 않으며 부득이하게 수집해야 할 경우 이용자의 사전동의를 반드시 구할 것입니다. 그리고, 어떤 경우에라도
                  입력하신 정보를 이용자에게 사전에 밝힌 목적 이외에 다른 목적으로는 사용하지 않으며 외부로 유출하지 않습니다.
                </p>
                <p>3-3. 회사는 개인정보의 수집 및 이용 목적에 따라 고객과 상담원 간의 통화내용을 녹취할 수 있습니다.</p>
                <p>
                  3-4. 회사는 온라인 상의 회원가입, 회원정보수정 및 서비스 화면, 회사가 제공하는 서면양식, 전화 또는 팩스, 이메일, 회원사를 통해 개인정보를
                  수집할 수 있습니다. 이용자는 이상의 개인정보 수집 과정에서 「동의」를 선택할 수 있으며 이 경우 개인정보 수집에 동의한 것으로 간주됩니다.
                </p>
              </S.Section>
              <S.Section>
                <h4>4. 수집하는 개인정보의 보유 및 이용기간</h4>
                <p>
                  4-1. 개인정보 보유기간 <br />
                  회사는 이용자의 서비스 이용기간 또는 고지 및 약정기간동안 이용자의 개인정보를 보유합니다. <br />
                  또한, 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 개인정보를 보존할 필요가 있는 경우, 관계법령에서 정한
                  보존기간 동안 이용자의 정보를 보관합니다. 이 경우, 해당 개인정보는 보관의 목적으로만 관리합니다.
                </p>
                <ul>
                  <ul>
                    <li>
                      (1) 계약 또는 청약철회 등에 관한 기록 <br />
                      보존근거 : 전자상거래 등에서의 소비자보호에 관한 법률 <br />
                      보존기간 : 5년 <br />
                    </li>
                    <li>
                      (2) 대금결제 및 재화 등의 공급에 관한 기록 <br />
                      보존근거 : 전자상거래 등에서의 소비자보호에 관한 법률 <br />
                      보존기간 : 5년 <br />
                    </li>
                    <li>
                      (3) 소비자의 불만 또는 분쟁처리에 관한 기록 <br />
                      보존근거 : 전자상거래 등에서의 소비자보호에 관한 법률 <br />
                      보존기간 : 3년 <br />
                    </li>
                    <li>
                      (4) 웹사이트 방문 기록 <br />
                      보존근거 : 통신비밀보호법 <br />
                      보존기간 : 3개월 <br />
                    </li>
                  </ul>
                </ul>
                <br />
                <p>
                  이용자는 언제든지 아래의 &quot;7. 개인정보 수집, 이용, 제공에 대한 동의철회&quot;에서 설명한 절차와 방법에 따라 본인이 직접 개인정보 삭제를
                  요청할 수 있으며, 이 경우 개인정보는 재생할 수 없는 방법에 의하여 디스크에서 완전히 삭제되어 추후 열람이나 이용이 불가능한 상태로 처리됩니다.
                </p>
                <br />
                <p>
                  4-2. 수집된 개인정보의 보유기간 후 처리 <br />
                  이용기간이 만료된 개인정보에 대해서는 상기 명시된 정보보유 사유에 따라 일정기간 저장하는 자료를 제외하고는 지체없이 파기하며, 개인정보처리가
                  제3자에게 위탁된 경우에는 수탁자에게도 파기하도록 지시합니다.
                </p>
                <p>
                  4-3. 파기방법 <br />
                  회사는 이용자의 개인정보를 안전하게 처리하며, 이용자의 개인정보는 수집 및 이용목적이 달성된 후에는 지체없이 파기합니다. 종이에 출력된
                  개인정보는 분쇄하거나 소각 등을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 파기합니다.
                </p>
              </S.Section>
              <S.Section>
                <h4>5. 수집한 개인정보의 공유 및 제공</h4>
                <p>
                  5-1. 수집한 개인정보의 공유 및 제공 <br />
                  회사는 이용자의 개인정보를 &quot;2. 개인정보의 수집목적 및 이용목적&quot;에서 고지한 범위 내에서 사용합니다. 회사는 이용자의 사전 동의 없이는
                  동 범위를 초과하여 이용하지 않으며, 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
                </p>
                <ul>
                  <ul>
                    <li />
                    <li>(1) 이용자가 사전에 개인정보 공개에 동의한 경우</li>
                    <li>
                      (2) 회사의 서비스를 이용하여 타인에게 정신적-물질적 피해를 줌으로서 그에 대한 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고
                      판단되는 충분한 근거가 있는 경우
                    </li>
                    <li>
                      (3) 기타 법에 의해 요구된다고 선의로 판단되는 경우(ex. 법령의 규정에 의거하거나, 수사, 조사목적으로 법령에 정해진 절차와 방법에 따라
                      수사기관 및 감독당국의 요구가 있는 경우)
                    </li>
                    <li>(4) 홈페이지에 게시한 서비스 이용약관이나 기타 서비스 등의 이용약관 또는 운영원칙을 위반한 경우</li>
                    <li>(5) 통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 광고주, 협력업체나 연구단체 등에 제공하는 경우</li>
                    <li>
                      (6) 성별, 연령별 기타 특정 조건의 집단에 대한 광고 게재 및 발송 시에도 이용자의 개인정보는 광고를 의뢰한 개인이나 기업 등에 제공되지
                      않습니다.
                    </li>
                  </ul>
                </ul>
                <p>
                  5-2. 수집한 개인정보의 처리 위탁 <br />
                  회사는 서비스 제공 계약 이행과 이용자 편의 증진을 위해 아래와 같은 업무를 외부 업체에 위탁해 처리하고 있습니다. 회사에서 이용자의 개인정보를
                  위탁하는 현황은 하단과 같으며, 위탁받은 업체가 개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 관리/감독을 하고 있고, 수탁사 및
                  위탁업무의 내용이 변경될 경우, 회사는 본 개인정보처리방침을 통해 제공받는 자, 제공목적, 제공정보 항목, 제공기간을 회원에게 고지하여 동의를
                  구합니다
                </p>
                <ul>
                  <ul>
                    <br />
                    <li>
                      수탁업체: (주)다이사 회원사 <br />
                      위탁업무 내용: 견적서비스 및 고객주문정보 <br />
                      개인정보의 보유 및 이용기간: 이용목적 달성시 혹은 위탁계약 종료시 <br />
                    </li>
                    <br />
                    <li>
                      수탁업체: (주)엠디서비스 <br />
                      위탁업무 내용: 견적서비스 및 고객주문정보, 고객상담서비스 및 민원처리 업무, 주문 또는 취소처리 관리업무, (주)다이사 회원사의 영업/상담대행
                      업무 <br />
                      개인정보의 보유 및 이용기간: 이용목적 달성시 혹은 위탁계약 종료시 <br />
                    </li>
                    <br />
                    <li>
                      수탁업체: ㈜마켓디자이너스 <br />
                      위탁업무 내용: 이사/청소 신청내역 조회, 위매치 통합 부가서비스 제공, 신규서비스 추천 <br />
                      개인정보의 보유 및 이용기간: 이용목적 달성 시 혹은 회원계약 종료 시 <br />
                    </li>
                    <br />
                    <li>
                      수탁업체: NICE평가정보㈜ <br />
                      위탁업무 내용: 실명본인인증 <br />
                      개인정보의 보유 및 이용기간: 이용목적 달성시 혹은 위탁계약 종료시 <br />
                    </li>
                    <br />
                    <li>
                      수탁업체: KT <br />
                      위탁업무 내용: 문자메시지전송 / 전문고객지원센터의 고객상담서비스 및 민원처리 업무, 주문 또는 취소처리 관리업무 <br />
                      개인정보의 보유 및 이용기간: 이용목적 달성시 혹은 위탁계약 종료시 <br />
                    </li>
                    <br />
                    <li>
                      수탁업체: (주)델피콤 <br />
                      위탁업무 내용: 안심번호(050번호) 및 녹취서비스 제공 <br />
                      개인정보의 보유 및 이용기간: 3년 <br />
                    </li>
                    <br />
                    <li>
                      수탁업체: Amazon Web Services Inc., (https://aws.amazon.com/compliance/contact) <br />
                      위탁업무 내용: 데이터 보관 및 시스템 운영 <br />
                      이전 국가: 서울 (AWS Seoul Region) <br />
                      이전 일시 및 방법: 서비스 이용 시점에 네트워크를 통한 전송 <br />
                      개인정보의 보유 및 이용기간: 개인정보 보관기간 동안 개인정보가 보관된 클라우드 서버 운영 및 관리 <br />
                    </li>
                    <br />
                    <li>
                      수탁업체: (주)다이사 제휴사 <br />
                      위탁업무 내용: 제휴 이벤트 안내 및 상담 <br />
                      개인정보의 보유 및 이용기간: 이용목적 달성 시 혹은 위탁 계약 종료시 <br />
                    </li>
                  </ul>
                </ul>
                <br />
                <p>
                  회사는 서비스 제공의 안정성과 최신 기술을 이용자에게 제공하기 위해 상기 업체에게 개인정보를 위탁하여 이용자로부터 취득 또는 생성한 개인정보를
                  AWS (Amazon Web Services Inc.)가 보유하고 있는 데이터베이스(물리적 저장 장소: 서울)에 저장합니다. AWS는 해당 서버의 물리적인 관리만을 행하고,
                  이용자의 개인정보에 접근할 수 없습니다.
                </p>
                <br />
                <p>
                  5-3. 목적 외 이용 및 제3자 제공 <br />
                  회사는 이용자 개인정보를 「개인정보의 수집목적 및 이용목적」에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업,
                  기관에 제공하지 않습니다. 단, 양질의 서비스 제공을 위해 이용자의 개인정보를 협력업체와 공유할 필요가 있는 경우에는 제공받는 자, 제공목적,
                  제공정보 항목, 제공기간을 회원에게 고지하여 동의를 구합니다.
                </p>
              </S.Section>
              <S.Section>
                <h4>6. 개인정보의 열람, 정정, 동의철회</h4>
                <p>
                  6-1. 이용자는 언제든지 개인정보를 열람하시거나 정정 및 철회하실 수 있으며 당사의 개인정보관리책임자에게 전자우편 또는 서면으로 요청하신 경우
                  정정하여 드립니다.
                </p>
                <p>
                  6-2. 이용자는 개인정보의 수집, 이용에 대한 동의 철회(해지) 및 제3자에게 제공한 동의의 철회를 E-mail, 전화, 팩스, 기타의 방법을 통하여 할 수
                  있으며 이 경우, 이용자는 동일성 증명을 위하여 반드시 성명과 전화번호를 밝히셔야 합니다.
                </p>
              </S.Section>
              <S.Section>
                <h4>7. 개인정보 수집, 이용, 제공에 대한 동의철회</h4>
                <p>이용자는 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 동의한 내용을 언제든지 철회할 수 있습니다.</p>
              </S.Section>
              <S.Section>
                <h4>8. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</h4>
                <p>
                  회사는 이용자에게 다양하고 차별화된 서비스를 제공하기 위하여 이용자에 대한 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를 사용합니다. 쿠키란
                  웹사이트서버가 사용자의 컴퓨터 브라우저에게 전송하는 소량의 정보입니다. 이용자가 당사 인터넷사이트에 접속을 하면 이용자의 컴퓨터는 브라우저에
                  저장되어 있는 쿠키의 내용을 읽고, 이용자의 추가정보를 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다. 쿠키는
                  이용자의 컴퓨터는 식별하지만 이용자를 개인적으로 식별하지는 않습니다.
                </p>
                <ul>
                  <ul>
                    <li>
                      (1) 쿠키에 의해 수집되는 정보 및 이용 목적
                      <ul>
                        <ul>
                          <li>- 수집정보: 접속IP, 접속로그, 이용 콘텐츠 등 서비스 이용정보</li>
                          <li>
                            - 이용목적: 개인 맞춤 마케팅, 서비스 제공 <br />
                            . 이용자의 접속빈도 또는 머문 시간 등을 분석하여 마케팅에 활용 <br />
                            . 클릭한 정보들에 대한 세부정보를 분석하여 다음 번 접속 때 개인 맞춤 서비스를 제공 <br />
                            . 이용자의 습관을 분석하여 서비스 개편 등의 기준으로 이용 <br />
                          </li>
                        </ul>
                      </ul>
                      <p>
                        또한 이용자는 쿠키 설정에 대한 선택권이 있습니다. 이용자는 웹 브라우저를 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때
                        통지를 보내도록 하거나, 아니면 모든 쿠키 저장을 거부할 수 있습니다.
                      </p>
                    </li>
                    <li>
                      (2) 쿠키 설정에 대한 선택 및 거부 <br />- 쿠키 정보수집 수준 설정: 이용자가 직접 웹브라우저의 [도구] 메뉴 {">"} 인터넷 옵션 {">"} 보안{" "}
                      {">"} 사용자 정의 수준 설정 <br />
                      단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다. <br />
                    </li>
                  </ul>
                </ul>
              </S.Section>
              <S.Section>
                <h4>9. 개인정보관련 기술적-관리적 대책</h4>
                <p>
                  9-1. 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적-관리적
                  대책을 강구하고 있습니다. 그러나 이용자의 주민등록번호는 공공장소에서의 인터넷사용 등 여러 방법으로 타인에게 알려질 가능성이 높으므로 이의
                  보호를 철저히 하는 것이 무엇보다 중요합니다. 그러므로 이용자도 개인의 정보를 타인에게 유출시키거나 제공하여서는 아니 되며, 자신의 개인정보를
                  책임 있게 관리할 의무가 있습니다. 개인의 관리소홀로 인한 개인정보 유출에 대해 당사는 어떠한 책임도 지지 않습니다.
                </p>
                <p>9-1-1. 이용자의 개인정보는 실명확인절차에 의해 보호되고 있습니다.</p>
                <p>9-1-2. 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.</p>
                <ul>
                  <ul>
                    <li>(1) 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있습니다,</li>
                    <li>(2) 최신 백신프로그램을 이용하여 이용자의 개인정보나 자료가 누출되거나 손상되지 않도록 방지합니다.</li>
                    <li>(3) Comodo에서 인증하는 SSL 암호화 알고리즘을 이용하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.</li>
                    <li>
                      (4) 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 안정성을 확보하기 위한 가능한 모든 기술적 장치를
                      갖추려 노력하고 있습니다.
                    </li>
                  </ul>
                </ul>
                <p>
                  9-1-3. 회사는 이용자의 개인정보보호의 중요성을 인식하고 있으며 이를 위해 개인정보처리자를 최소한으로 제한하고 있으며 개인정보관리책임자가
                  처리자를 대상으로 주기적인 교육을 실시하여 개인정보보호를 위해 최선을 다하고 있습니다. 또한 본 정책에 명시된 이행사항 및 관련 직원의
                  준수여부를 정기적으로 점검하여 위반 내용이 있는 경우 이를 시정 또는 개선하고 기타 필요한 조치를 취하도록 하고 있습니다.
                </p>
              </S.Section>
              <S.Section>
                <h4>10. 이용자 및 법정대리인의 권리와 그 행사방법</h4>
                <ul>
                  <ul>
                    <li>(1) 언제든지 자신의 개인정보를 조회하고 수정할 수 있습니다.</li>
                    <li>(2) 언제든지 개인정보 제공에 관한 동의철회/회원가입 해지를 요청할 수 있습니다.</li>
                    <li>(3) 만 14세 미만 아동의 경우, 법정대리인이 아동의 개인정보를 조회하거나 수정할 권리, 수집 및 이용 동의를 철회할 권리를 가집니다.</li>
                    <li>
                      (4) 정확한 개인정보의 이용 및 제공을 위해 수정이 완료될 때까지 이용자의 개인정보는 이용되거나 제공되지 않습니다. 또한, 이미 제3자에게
                      제공된 경우에는 지체 없이 제공받은 자에게 사실을 알려 수정이 이루어질 수 있도록 하겠습니다.
                    </li>
                  </ul>
                </ul>
              </S.Section>

              <S.Section>
                <h4>11. 게시물에 포함된 개인정보</h4>
                <p>
                  11-1. 회사는 이용자님의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 그러나 다음의 경우에는 명시적 또는
                  개별적인 경고 후 삭제 조치할 수 있습니다.
                </p>
                <ul>
                  <ul>
                    <li>(1) 스팸(spam)성 게시물(예, 돈 버는 메일 등)</li>
                    <li>(2) 타인을 비방할 목적으로 허위사실을 유포하여 타인의 명예를 훼손하는 글</li>
                    <li>(3) 동의 없는 타인의 개인정보 공개</li>
                    <li>(4) 제3자의 저작권 등 권리를 침해하는 내용</li>
                  </ul>
                </ul>
                <p>
                  11-2. 회사는 바람직한 게시판 문화를 활성화하고 개인정보를 보호하기 위하여 동의 없이 타인의 개인정보를 게시한 경우 특정부분을 삭제하거나 기호
                  등으로 수정하여 게시할 수 있습니다.
                </p>
              </S.Section>
              <S.Section>
                <h4>12. 개인정보관련 의견수렴 및 불만처리에 관한 사항</h4>
                <p>12-1. 회사는 개인정보와 관련된 이용자의 의견수렴과 불만처리를 위하여 개인정보 관리자를 지정해서 해당 업무를 수행하도록 합니다.</p>
                <p>12-2. 회사는 이용자의 의견을 소중하게 생각하며, 의문사항에 대하여 언제나 성실한 답변을 받을 권리가 있습니다.</p>
                <p>12-3. 회사는 이용자의 원활한 의사소통을 위해 민원처리센터를 운영하고 있으며 연락처는 다음과 같습니다.</p>
                <p>12-3-1. 민원처리센터</p>
                <ul>
                  <ul>
                    <li>(1) 전자우편 : aiden@marketdesigners.com</li>
                    <li>(2) 전화번호 : 1522-2483</li>
                    <li>(3) 팩스번호 : 02-6455-2484</li>
                    <li>(4) 등기우편 : 서울특별시 강남구 테헤란로20길 9, 3층 (역삼동, 동궁빌딩)</li>
                    <li>(5) 전화상담은 평일 09:00~18:00까지 가능합니다.</li>
                  </ul>
                </ul>
                <p>
                  12-4. 전자우편이나 팩스 및 우편을 이용한 상담은 접수 후 24시간 이내에 성실하게 답변 드리겠습니다. 다만, 근무시간 이후 또는 주말 및 공휴일에는
                  익일 처리하는 것을 원칙으로 합니다.
                </p>
                <p>
                  12-5. 기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터, 대검찰청 인터넷범죄수사센터, 사이버경찰청 등으로 문의하실 수 있습니다.
                </p>
                <ul>
                  <ul>
                    <li>
                      (1) 개인정보침해신고센터
                      <br />
                      전화번호 : 118 / URL : http://privacy.kisa.or.kr
                    </li>
                    <li>
                      (2) 대검찰청 사이버수사과
                      <br />
                      전화번호 : 02-3480-2000 / URL : http://www.spo.go.kr
                    </li>
                    <li>
                      (3) 사이버 경찰청
                      <br />
                      전화번호 : 182 / URL : http://cyberbureau.police.go.kr
                    </li>
                  </ul>
                </ul>
              </S.Section>
              <S.Section>
                <h4>13. 개인정보 보호책임자에 관한 사항</h4>
                <p>
                  이용자의 개인정보에 대한 보안유지책임은 해당 이용자 자신에게 있습니다. 회사에서는 성명. 주민등록번호에 대해 어떠한 방법으로도 이용자에게
                  직접적으로 질문하는 경우는 없으므로 타인에게 비밀번호가 유출되지 않도록 각별히 주의하시기 바랍니다. 특히 “9. 개인정보관련
                  기술적-관리적대책”항에서 명시한 것과 같이 공공장소에서 온라인상에서 접속해 있을 경우에는 더욱 유의하셔야 합니다. 회사는 고객의 개인정보를
                  보호하고 개인정보와 관련한 고객의 불만사항을 처리하기 위하여 개인정보 보호책임자를 두고 있습니다.
                  <br />
                </p>
                <ul>
                  <ul>
                    <li>
                      (1)개인정보 보호책임자
                      <br />
                      성명 : 박경태 <br />
                      연락처 : 1522-2483
                      <br />
                      <br />
                    </li>
                    <li>
                      (2)개인정보 관리담당자
                      <br />
                      성명 : 김형욱 <br />
                      연락처 : 1522-2483
                      <br />
                      <br />
                    </li>
                  </ul>
                </ul>
                <p>본 개인정보처리방침은 2015년 11월 25일에 작성되었습니다.</p>
                <br />
              </S.Section>
            </S.ListInfo>
          )}
          <hr />
        </S.Wrapper>
      </S.Container>
    </PopupTemplate>
  );
};

const S = {
  Container: Styled.div`
    position: relative;
    padding: 0 24px;
    height: 100%;
    overflow-y: auto;
    margin-top: 56px;
  `,
  Wrapper: Styled.div`
    padding-top: 10px;
    top: 0;
    left: 24px;
    right: 24px;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.gray33};
    
    @media (min-width: 1200px) {
      width: 312px;
    }
    
    h4 {
        display: block;
        margin-bottom: 3px;
        font-size: 15px;
        font-weight: bold;
        line-height: 26px;
        color: ${colors.gray33};
    }
  `,
  ListItem: styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  ListTitle: styled.div`
    font-size: 16px;
    font-weight: 500;
  `,
  ListButton: styled.div`
    cursor: pointer;
  `,
  ListInfo: styled.div`
    margin-top: 15px;
  `,
  Section: Styled.div`
    padding-top: 10px;
  `,
};
export default PrivacyHistoryModal;
