import * as React from "react";

function CatTower({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5.75A.75.75 0 015.25 0h13.5a.75.75 0 01.75.75v2.378a2.25 2.25 0 11-1.5 0V1.5h-3V12a6 6 0 016 6v3.75a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h.75v-3a4.5 4.5 0 00-4.5-4.5H9A4.5 4.5 0 004.5 18v3h3v-1.5a4.5 4.5 0 119 0V22a.5.5 0 01-.5.5H3.75a.75.75 0 01-.75-.75V18a6 6 0 016-6V1.5H5.25A.75.75 0 014.5.75zM9 21h6v-1.5a3 3 0 10-6 0V21zm4.5-10.5V12h-3v-1.5h3zm6.008-5.357a.755.755 0 000 .214.765.765 0 11-.865-.865.755.755 0 00.214 0 .766.766 0 01.65.651zM10.5 4.5V6h3V4.5h-3zm3-1.5h-3V1.5h3V3zm0 4.5h-3V9h3V7.5z"
        fill={fill}
      />
    </svg>
  );
}

export default CatTower;
