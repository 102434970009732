import * as React from "react";

function LevelC({
  width = 56,
  height = 56,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="fill"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.952 28c0 15.464 12.536 28 28 28s28-12.536 28-28-12.536-28-28-28-28 12.536-28 28zm48 0c0 11.046-8.955 20-20 20-11.046 0-20-8.954-20-20s8.954-20 20-20c11.045 0 20 8.954 20 20z"
        fill="#EBEEF2"
      />
      <path
        d="M28.584 48v8c7.49 0 14.299-2.885 19.357-7.594C53.426 43.3 56.852 36.046 56.852 28c0-8.136-3.502-15.46-9.096-20.576A28.315 28.315 0 0028.584 0v8c5.154 0 9.985 1.91 13.693 5.301 4.12 3.769 6.499 9.036 6.499 14.699 0 5.601-2.326 10.815-6.367 14.577A20.194 20.194 0 0128.584 48z"
        fill="url(#prefix__level_c)"
      />
      <path d="M24.952 4a4 4 0 108 0 4 4 0 00-8 0z" fill="#FDA76F" />
      <circle cx={28.952} cy={52} r={4} fill="#E04108" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.217 36.28v1.06h-8.52v-1.06h5.82c.074-.313.124-.63.15-.95.027-.32.04-.597.04-.83v-.44h-4.9V33h6.16v1.43c0 .113-.005.245-.015.395a13.363 13.363 0 01-.1.97c-.023.17-.048.332-.075.485h1.44zm-9.965.049v-1.03h-4.84v-1.26h4.77v-1.03h-6.02v3.32h6.09zm1.22 1.71v-1.03h-8.52v1.03h8.52zm-3.105 4.115a6.1 6.1 0 01-1.155.105c-.407 0-.792-.035-1.155-.105a3.57 3.57 0 01-.955-.315 1.846 1.846 0 01-.65-.53 1.23 1.23 0 01-.24-.76v-.3c0-.287.08-.538.24-.755.16-.217.376-.395.65-.535a3.57 3.57 0 01.955-.315c.363-.07.748-.105 1.155-.105a6.1 6.1 0 011.155.105c.363.07.681.175.955.315.273.14.49.318.65.535.16.217.24.468.24.755v.3c0 .293-.08.547-.24.76-.16.213-.377.39-.65.53-.274.14-.592.245-.955.315zm-1.155-.915a3.74 3.74 0 01-.645-.055 2.716 2.716 0 01-.56-.155 1.28 1.28 0 01-.395-.24.427.427 0 01-.15-.32v-.14c0-.12.05-.227.15-.32.1-.093.231-.173.395-.24.163-.067.35-.118.56-.155a3.75 3.75 0 011.29 0c.21.037.396.088.56.155.163.067.295.147.395.24.1.093.15.2.15.32v.14c0 .12-.05.227-.15.32-.1.093-.232.173-.395.24-.164.067-.35.118-.56.155a3.74 3.74 0 01-.645.055zM36.027 42v-3.95h-1.25v.92h-3.64v-.92h-1.25V42h6.14zm-4.89-1.03h3.64v-1h-3.64v1z"
        fill="#E04108"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.917 24.174c-.053 1.151-.366 2.168-.94 3.05-.572.881-1.376 1.565-2.41 2.05-1.036.484-2.218.726-3.547.726-2.192 0-3.918-.708-5.178-2.124-1.26-1.415-1.89-3.414-1.89-5.996v-.817c0-1.62.284-3.038.853-4.253.57-1.215 1.388-2.154 2.458-2.816 1.069-.663 2.306-.994 3.712-.994 2.024 0 3.65.528 4.88 1.584s1.929 2.512 2.097 4.367h-4.01c-.03-1.007-.287-1.73-.768-2.17-.48-.438-1.214-.658-2.2-.658-1 0-1.733.37-2.2 1.113-.465.742-.71 1.927-.732 3.554v1.17c0 1.764.223 3.024.67 3.781.447.758 1.216 1.136 2.308 1.136.925 0 1.631-.216 2.12-.647.489-.432.748-1.117.779-2.056h3.998z"
        fill="#FA6E3C"
      />
      <defs>
        <linearGradient
          id="prefix__level_c"
          x1={56.852}
          y1={0}
          x2={56.852}
          y2={56}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDA76F" />
          <stop offset={1} stopColor="#FA6E3C" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LevelC;
