import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useMedia } from "react-use-media";
import ReactPixel from "react-facebook-pixel";
import { useCookies } from "react-cookie";
import { get, isEmpty } from "lodash";
import queryString from "query-string";
import colors from "styles/colors";
import { SpinnerPopup } from "@wematch/wematch-ui";
import { getCookie } from "lib/cookie";
import { dataLayer } from "lib/gtm";
import { events } from "lib/appsflyer";
import { MOVE_URL } from "constants/env";
import { MainHeader } from "components/common";
import Collapse from "components/base/collapse";
import { Down, Up, Check } from "components/common/Icon";
import * as orderActions from "services/order/actions";
import * as orderSelectors from "services/order/selectors";
import * as userSelectors from "services/user/selectors";
import CompletedModal from "./CompletedModal";
import PartnerInfoCard from "components/common/PartnerInfoCard";

export default function CompletedPage() {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["x-wematch-token"]);
  const isDesktop = useMedia({
    minWidth: 1200,
  });
  const [dong, setDong] = useState("");
  const [expand, setExpand] = useState(true);
  const [visibleCheckModal, setVisibleCheckModal] = useState(false);
  const getSubmittedOrder = useSelector(orderSelectors.getSubmittedOrder);
  const { token } = useSelector(userSelectors.getUser);

  // 초기 진입 시 내 신청내역 정보가 있는지 확인. 없으면 내신청내역으로 보냄
  useEffect(() => {
    if (getSubmittedOrder?.loading === "init" && token && isEmpty(getSubmittedOrder?.data)) {
      dispatch(orderActions.getOrderAsync.request(token));
    }
  }, [getSubmittedOrder, token]);

  // 비 로그인 상태일 경우는 무조건 내신청내역으로 보냄
  useEffect(() => {
    const wematchToken = get(cookies, "x-wematch-token");
    if (isEmpty(wematchToken)) {
      window.location.href = `${MOVE_URL}/myrequest`;
    }
  }, [cookies, dispatch, window]);

  // 성공일 경우에만 호출
  useEffect(() => {
    setVisibleCheckModal(true);
    if (getSubmittedOrder.loading === "done" && getSubmittedOrder?.data?.match_status === "success") {
      dataLayer({
        event: "pageview",
      });
      events({
        action: "app_move_oneroom_done",
      });

      ReactPixel.fbq("track", "Purchase");

      // 텐핑 전환스크립트 추가
      // const cookie = getCookie("0dj38gepoekf98234aplyadmin");
      // if (cookie !== undefined) {
      //   if (queryString.parse(cookie).agentid === "833") {
      //     TenpingScript.SendConversion();
      //   }
      // }
    }
  }, [getSubmittedOrder]);

  const getMoveTypeText = () => {
    if (getSubmittedOrder?.data?.options.need_packing) {
      return "포장이사";
    } else if (getSubmittedOrder?.data?.options.is_ilban) {
      return "일반이사";
    } else {
      return "반포장이사";
    }
  };

  useEffect(() => {
    if (getSubmittedOrder.data?.match_status === "success" && getSubmittedOrder.loading === "done") {
      // 주소에 동이 3번째, 4번째 있는 경우가 있음.
      const newDong = getSubmittedOrder?.data?.destination?.split(" ");
      if (newDong[2].indexOf("동") !== -1) {
        setDong(newDong[2]);
      } else {
        setDong(newDong[3]);
      }
    }
  }, [getSubmittedOrder?.data?.match_status, getSubmittedOrder.loading]);

  if (getSubmittedOrder.loading === "loading" || isEmpty(getSubmittedOrder?.data)) {
    return <SpinnerPopup title="내 조건에 맞는 이사업체 찾는 중" subtitle="(최대 1분 소요)" />;
  }

  const handleConsultRegister = () => {
    window.open(`https://tv.wematch.com?token=${token}`, "_blank");
  };

  return (
    <>
      <S.Container>
        {isDesktop && <MainHeader />}
        <S.TopContents>
          <S.Icon>
            <Check fill={"#fff"} />
          </S.Icon>
          <S.TopTitle>
            <em>이사업체 매칭</em> 완료
            <br />
            <span>
              업체에서 연락이 없다면 먼저 전화해주세요!
              <br /> 전화번호를 문자로 보내드립니다.
            </span>
          </S.TopTitle>
        </S.TopContents>
        <S.ContentsWrap>
          <S.TitleWrap>
            <S.BoxTitle>매칭업체 정보</S.BoxTitle>
          </S.TitleWrap>
          <S.CompanyList>
            {getSubmittedOrder?.data?.matches?.map((data, index) => (
              <PartnerInfoCard key={index} data={data} index={index} />
            ))}
          </S.CompanyList>
          <S.TitleWrap onClick={() => setExpand(!expand)} className="toggle">
            <S.BoxTitle>내 신청 정보</S.BoxTitle>
            {expand ? <Up style={{ marginTop: 6 }} /> : <Down style={{ marginTop: 6 }} />}
          </S.TitleWrap>
          <Collapse expand={expand}>
            <S.MoveInfo>
              <li>
                <S.MoveText>연락처</S.MoveText>
                <S.MoveSubtext>{getSubmittedOrder?.data?.who}</S.MoveSubtext>
              </li>
              <li>
                <S.MoveText>이사날짜</S.MoveText>
                <S.MoveSubtext>{getSubmittedOrder?.data?.when}</S.MoveSubtext>
              </li>
              <li>
                <S.MoveText>이사 종류</S.MoveText>
                <S.MoveSubtext>{getMoveTypeText()}</S.MoveSubtext>
              </li>
              <li>
                <S.MoveText>출발지</S.MoveText>
                <S.MoveSubtext>{getSubmittedOrder?.data?.starting_point}</S.MoveSubtext>
              </li>
              <li>
                <S.MoveText>도착지</S.MoveText>
                <S.MoveSubtext>{getSubmittedOrder?.data?.destination}</S.MoveSubtext>
              </li>
            </S.MoveInfo>
            <S.MoveOption>
              <li>
                <S.MoveText>짐량</S.MoveText>
                <S.MoveSubtext>
                  {getSubmittedOrder?.data?.options?.stuff.split(";").map((o, i) => (
                    <span key={i}>
                      {o}
                      <br />
                    </span>
                  ))}
                </S.MoveSubtext>
              </li>
            </S.MoveOption>
          </Collapse>
          <S.Consult
            onClick={() => {
              dataLayer({
                event: "oneroom_completed_internet_banner",
              });
              handleConsultRegister();
            }}
            style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06)" }}
          >
            <img src={require("assets/images/internet_consult_banner.png")} alt="인터넷비교상담" />
          </S.Consult>
        </S.ContentsWrap>
        <S.Button id="oneroom_completed_confirm_btn" onClick={() => (window.location.href = `${MOVE_URL}`)}>
          신청 정보 확인완료
        </S.Button>
      </S.Container>
      <CompletedModal visible={visibleCheckModal} onClose={() => setVisibleCheckModal(false)} />
    </>
  );
}

const S = {
  Container: styled.div`
    background-color: #fafafa;
  `,
  TopContents: styled.div`
    padding: 25px 0;
    background-color: white;
    border-bottom: 0.5px solid ${colors.lineDefault};
    @media screen and (max-width: 320px) {
      padding: 40px 0 0;
    }
  `,
  ContentsWrap: styled.div`
    position: relative;
    padding: 0 24px 42px;
    @media screen and (min-width: 768px) {
      width: 720px;
      margin: 0 auto;
      padding: 0 0 42px;
    }
    .toggle {
      cursor: pointer;
    }

    ul:last-child {
      margin-bottom: 30px;
    }
  `,
  Icon: styled.div`
    position: relative;
    width: 56px;
    height: 56px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: #1672f7;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -9px;
      margin-left: -13px;
    }
  `,
  TopTitle: styled.p`
    margin-top: 15px;
    font-size: 18px;
    text-align: center;
    em {
      font-weight: 700;
    }
    span {
      display: inline-block;
      margin-top: 14px;
      font-size: 14px;
      line-height: 20px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 24px;
    }
  `,
  TitleWrap: styled.div`
    overflow: hidden;
    padding-top: 32px;
    svg {
      float: right;
    }
    @media screen and (min-width: 768px) {
      padding-top: 52px;
    }
  `,
  BoxTitle: styled.strong`
    display: block;
    float: left;
    padding-bottom: 10px;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    em {
      color: #1672f7;
    }
  `,
  CompanyList: styled.ul``,
  ListBox: styled.div`
    overflow: hidden;
    svg {
      float: left;
      @media screen and (max-width: 320px) {
        width: 48px;
        height: 48px;
      }
    }
  `,
  CompanyTitle: styled.p`
    overflow: hidden;
    float: left;
    width: 76%;
    margin: 7px 0 0 10px;
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      display: inline-block;
      margin-top: 12px;
      font-size: 14px;
      font-weight: 400;
      @media screen and (max-width: 320px) {
        margin-top: 6px;
        font-size: 12px;
      }
    }
    @media screen and (max-width: 320px) {
      margin: 5px 0 0 10px;
      font-size: 15px;
    }
  `,
  LinkCompany: styled.a`
    display: block;
    margin-top: 20px;
    padding: 12px 0;
    border: 1px solid #d7dbe2;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
    @media screen and (max-width: 320px) {
      margin-top: 12px;
      font-size: 13px;
    }
    em {
      font-weight: 700;
      color: #1672f7;
    }
  `,
  MoveInfo: styled.ul`
    padding: 21px 0 6px;
    border-bottom: 1px solid #ebeef2;
    li {
      overflow: hidden;
      margin-bottom: 15px;
    }
  `,
  MoveText: styled.p`
    float: left;
    width: 27%;
    padding-top: 3px;
    font-size: 15px;
    color: ${colors.gray88};
  `,
  MoveSubtext: styled.p`
    float: left;
    width: 73%;
    font-size: 15px;
    color: #333;
    line-height: 22px;
  `,
  MoveOption: styled.ul`
    padding-top: 24px;
    margin-bottom: 50px;
    li {
      overflow: hidden;
      margin-bottom: 20px;
    }
    li:first-child {
      padding-bottom: 16px;
      border-bottom: 1px solid #ebeef2;
    }
  `,
  ServiceList: styled.div`
    overflow: hidden;
    clear: both;
  `,
  ServiceLink: styled.a`
    float: left;
    width: 25%;
    svg {
      display: block;
      margin: 17px auto 0;
    }
  `,
  ServiceText: styled.p`
    margin-top: 20px;
    font-size: 15px;
    text-align: center;
  `,
  Button: styled.button`
    position: fixed;
    bottom: 0;
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 56px;
    font-size: 18px;
    background: #1672f7;
    color: #fff;
    @media screen and (min-width: 1200px) {
      left: 0;
      right: 0;
      width: 720px;
      margin: 0 auto;
    }
  `,
  Consult: styled.div`
    border-radius: 10px;
    background-color: ${colors.white};
    padding: 16px 0;
    margin: 16px 0 106px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    img {
      width: 197px;
      height: 81px;
    }
  `,
};
