export default {
  white: "#FFFFFF",
  black: "#121212",
  red: "#EC485C",
  pointBlue: "#1672f7",
  lineDefault: "#d7dbe2",
  lineDeco: "#ebeef2",
  lineEnd: "#bcc0c6",
  gray33: "#333333",
  gray66: "#666666",
  gray88: "#888888",
  gray99: "#999999",
  grayBg: "#f7f8fa",
  pointVividBlue: "#1689f7",
  vividCyan: "#15D4CC",
  skeletonBg: "#EBEEF2",
  pointSky: "#00b6ed", // da24 colors 제거 예정
  boxBg: "#f5f7f9",
};
