import React, { useEffect } from "react";
import { useMedia } from "react-use-media";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MainHeader } from "components/common";
import { MOVE_URL } from "constants/env";
import { dataLayer } from "lib/gtm";
import { events } from "lib/appsflyer";
import SmileIcon from "components/common/Icon/SmileIcon";
import { Button } from "@wematch/wematch-ui";
import colors from "styles/colors";

const S = {
  Header: styled.header`
    display: block;
    height: 55px;
    padding: 0 24px;
    margin-top: 0;

    a {
      display: block;
      width: 87px;
      height: 16px;
      padding: 24px 0 10px;
    }

    span {
      display: block;
      width: 133px;
      height: 16px;
      font-size: 1px;
      background: url(https://marketdesigners-asset.s3.ap-northeast-2.amazonaws.com/images/logo/wm_da24_c.png) 0 0 no-repeat;
      background-size: 100% auto;
      color: transparent;
    }
    @media screen and (min-width: 768px) {
      height: 72px;

      a {
        width: 108px;
        height: 20px;
        padding-top: 26px;
      }
    }
  `,
  Container: styled.div`
    position: relative;
    padding-bottom: 24px;
    height: calc(100vh - 55px);
  `,
  Contents: styled.div`
    margin-top: 56px;
    text-align: center;
    letter-spacing: -0.5px;
    padding: 24px;
    svg {
      display: block;
      margin: 0 auto;
      @media screen and (max-width: 320px) {
        width: 80px;
        height: 80px;
      }
    }

    @media screen and (min-width: 1200px) {
      margin: 172px 0 63px;
    }
    @media screen and (max-width: 320px) {
      margin-top: 46px;
    }
  `,
  Title: styled.p`
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: ${colors.gray33};
    margin: 16px 0;
  `,
  SubTitle: styled.p`
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    color: ${colors.gray33};
  `,
  Bold: styled.span`
    font-weight: 700;
  `,
  ButtonWrapper: styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 24px;
    box-sizing: border-box;
  `,
};

export default function NoPartner() {
  const isDesktop = useMedia({
    minWidth: 1200,
  });

  useEffect(() => {
    dataLayer({
      event: "pageview",
    });

    events({
      action: "app_move_oneroom_nopartner",
    });
  }, []);

  return (
    <S.Container>
      {isDesktop ? (
        <MainHeader />
      ) : (
        <S.Header>
          <Link to={MOVE_URL}>
            <span>위매치</span>
          </Link>
        </S.Header>
      )}
      <S.Contents>
        <SmileIcon />
        <S.Title>접수가 완료되었습니다</S.Title>
        <S.SubTitle>
          <S.Bold>최대 24시간</S.Bold> 안에 <br />
          조건에 맞는 업체정보를 <br />
          알림톡 또는 문자로 보내드리겠습니다. <br />
          <br />
          조금만 기다려주세요!
        </S.SubTitle>
      </S.Contents>
      <S.ButtonWrapper>
        <Button
          theme={"primary"}
          label={"홈으로 돌아가기"}
          isRound={true}
          onClick={() => {
            window.location.href = MOVE_URL;
          }}
        />
      </S.ButtonWrapper>
    </S.Container>
  );
}
