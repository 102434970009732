import React from "react";
import styled from "styled-components";

import colors from "styles/colors";
import BasePopup from "../base/basePopup";

type SelectItemProp = {
  key: string;
  value: string;
};

interface Props {
  /** 모달 visible */
  visible: boolean;
  /** 상단 헤더 타이틀 */
  headerTitle?: string;
  /** 셀렉트 박스 내용 정의 */
  /** close 버튼 이벤트 정의 */
  onClose?: (e: React.MouseEvent) => void;
  /** overlay 클릭 이벤트 정의 */
  onOverlayClose?: (e: React.MouseEvent) => void;
  /** 리스트 내용 */
  options: SelectItemProp[];
  /** 리스트 내용 선택 이벤트 */
  onSelect?(key: string, value: string): void;
}

const S = {
  List: styled.ul`
    overflow-y: scroll;
    max-height: 280px;
    padding: 0 24px;
    ::-webkit-scrollbar {
      display: none !important;
    }
    -ms-overflow-style: none !important;
    @media (min-width: 1200px) {
      max-height: 438px;
    }
  `,
  Item: styled.li`
    height: 60px;
    margin: 0;
    padding: 8px 0 10px;
    font-size: 15px;
    color: ${colors.gray33};
    line-height: 50px;
    cursor: pointer;
    border-top: 1px solid ${colors.lineDeco};
    background-color: transparent;
    text-align: center;
    box-sizing: border-box;
    user-select: none;
    :first-child {
      border-top: 0 none;
    }
    :last-child {
      border-bottom: 0 none;
    }
  `,
};

export default function Select({
  visible,
  headerTitle,
  onClose,
  onOverlayClose,
  options,
  onSelect,
}: Props) {
  const handleOnSelect = (key: string, value: string) => {
    if (onSelect) {
      onSelect(key, value);
    }
  };

  const handleOnClose = (e: React.MouseEvent) => {
    if (onClose) {
      onClose(e);
    }
  };

  return (
    <BasePopup
      {...{ visible, headerTitle, onClose, onOverlayClose, options, onSelect }}
    >
      <S.List>
        {options.map((o, i) => (
          <S.Item
            key={i}
            onClick={(e) => {
              handleOnSelect(o.key, o.value);
              handleOnClose(e);
            }}
          >
            {o.value}
          </S.Item>
        ))}
      </S.List>
    </BasePopup>
  );
}
