import * as React from "react";

function Air({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.75A.75.75 0 01.75 3h22.5a.75.75 0 01.75.75v7.875A3.375 3.375 0 0120.625 15a.75.75 0 010-1.5c1.035 0 1.875-.84 1.875-1.875V4.5h-21v7.125c0 1.036.84 1.875 1.875 1.875a.75.75 0 010 1.5A3.375 3.375 0 010 11.625V3.75zm4.5 3A.75.75 0 015.25 6h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm7.95 6.6a.75.75 0 10-.9-1.2c-.582.436-1.33 1.251-1.558 2.297a2.84 2.84 0 00.165 1.74c.254.596.714 1.154 1.393 1.663.521.39.786.752.913 1.05.125.293.137.57.08.835-.124.566-.575 1.102-.993 1.415a.75.75 0 00.9 1.2c.582-.436 1.33-1.251 1.558-2.297a2.84 2.84 0 00-.165-1.74c-.254-.596-.714-1.154-1.393-1.663-.521-.39-.786-.752-.913-1.05a1.34 1.34 0 01-.08-.835c.124-.566.575-1.101.993-1.415zm-4.266 0a.75.75 0 00-.9-1.2c-.582.436-1.33 1.251-1.558 2.297a2.84 2.84 0 00.165 1.74c.254.596.714 1.154 1.393 1.663.521.39.786.752.913 1.05.125.293.137.57.08.835-.124.566-.575 1.102-.993 1.415a.75.75 0 00.9 1.2c.582-.436 1.331-1.251 1.558-2.297a2.84 2.84 0 00-.165-1.74c-.254-.596-.714-1.154-1.393-1.663-.52-.39-.786-.752-.913-1.05a1.34 1.34 0 01-.079-.835c.123-.566.574-1.101.992-1.415zm8.682-1.05a.75.75 0 01-.15 1.05c-.419.314-.87.849-.992 1.415a1.34 1.34 0 00.079.834c.127.3.392.66.913 1.051.678.51 1.139 1.067 1.393 1.663a2.84 2.84 0 01.165 1.74c-.227 1.046-.977 1.86-1.558 2.297a.75.75 0 11-.9-1.2c.418-.313.869-.849.992-1.415a1.34 1.34 0 00-.08-.834c-.127-.299-.391-.66-.912-1.051-.68-.51-1.14-1.067-1.394-1.663a2.84 2.84 0 01-.164-1.74c.227-1.046.976-1.86 1.558-2.297a.75.75 0 011.05.15z"
        fill={fill}
      />
    </svg>
  );
}

export default Air;
