import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";

import { Close } from "components/common/Icon";

const slideUp = keyframes`
  from {
    transform: translateY(200%);
  }
  to {
    transform: translateY(0px);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(200%);
  }
`;

const S = {
  Container: styled.div``,
  Box: styled.div<{ visible: boolean; height: number }>`
    position: fixed;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none !important;
    }
    z-index: 10;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    padding: 56px 30px 20px;
    background-color: #fff;
    box-sizing: border-box;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-name: ${slideUp};
    animation-fill-mode: forwards;
    ${(props) =>
      props.visible &&
      css`
        animation-name: ${slideDown};
      `};

    @media (min-width: 768px) {
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 360px;
      //height: 480px;
      height: ${(props) => props.height + "px"};
      max-height: none;
      margin-top: -240px;
      margin-left: -180px;
      border-radius: 16px;
    }
    @media screen and (max-width: 320px) {
      padding: 56px 20px 20px;
    }
  `,
  Overlay: styled.div`
    @media (min-width: 768px) {
      width: 100%;
      height: 100%;
      opacity: 0.88;
      background: linear-gradient(
        to right,
        rgba(18, 18, 18, 0.6) 0%,
        rgba(18, 18, 18, 0.6) 100%
      );
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      background: rgba(0, 0, 0, 0.6);
      transition: all 0.25s ease-in-out;
      animation-duration: 0.25s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
    }
  `,
  TopFixed: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
  `,
  CloseBtn: styled.button`
    position: absolute;
    top: 50%;
    right: 14px;
    height: 44px;
    margin-top: -22px;
    padding: 10px;
  `,
  ScrollArea: styled.div`
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none !important;
    }
    -ms-overflow-style: none !important;
    height: 100%;
    padding: 24px 10px 0;
    font-size: 15px;
    color: #333;
    line-height: 22px;
    @media screen and (min-width: 768px) {
      padding-top: 0;
    }
    @media screen and (max-width: 320px) {
      padding: 0;
    }
  `,
  PopupTitle: styled.h3`
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  `,
};

interface Props {
  visible: boolean;
  closePopup: () => void;
  title: string;
  children: React.ReactNode;
  height?: number;
}

export default function FullPopup({
  visible,
  closePopup,
  title,
  children,
  height = 480,
}: Props) {
  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);

  useEffect(() => {
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(visible);
  }, [localVisible, visible]);

  if (!animate && !localVisible) return null;

  return (
    <S.Container>
      <S.Overlay />
      <S.Box visible={!visible} height={height}>
        <S.TopFixed>
          <S.CloseBtn onClick={closePopup}>
            <Close width={24} height={24} />
          </S.CloseBtn>
        </S.TopFixed>
        <S.ScrollArea>
          <S.PopupTitle>{title}</S.PopupTitle>
          {children}
        </S.ScrollArea>
      </S.Box>
    </S.Container>
  );
}
