import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";
import { useMedia } from "react-use-media";

import { ButtonContainer } from "components/templates/multistep";
import { Button } from "components/common";

import * as orderActions from "services/order/actions";
import * as orderSelector from "services/order/selectors";

import colors from "styles/colors";
import { dataLayer } from "lib/gtm";
import { events } from "lib/appsflyer";

const S = {
  Container: styled.div`
    padding: 0px;
    .bg-gray {
      background-color: #fafafa;
    }
    .fixButton {
      width: 100%;
      margin: 0 auto;
      @media screen and (min-width: 1200px) {
        width: 70%;
        border-radius: 0;
      }
    }
  `,
  PreviousButton: styled.button`
    float: left;
    width: 30%;
    height: 56px;
    border: 1px solid #d7dbe2;
    border-radius: 0;
    font-size: 18px;
    background: #fff;
  `,
};

const SMovingTypes = {
  Container: styled.div<{ checked: boolean }>`
    overflow: hidden;
    margin-bottom: 16px;
    background-color: white;
    padding: 22px 20px;
    border: 1px solid ${colors.lineDefault};
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    color: ${(props) => props.checked && colors.pointBlue};
    ${(props) =>
      props.checked &&
      css`
        border: 1px solid ${colors.pointBlue};
        box-shadow: 0 4px 10px rgba(22, 114, 247, 0.25);
      `};
    @media screen and (min-width: 768px) {
      margin-bottom: 16px;
      padding: 22px 46px;
    }
    @media screen and (max-width: 320px) {
      padding: 22px 16px;
    }

    .service-bg {
      position: relative;
      border: solid 1px
        ${(props) => (props.checked ? colors.pointBlue : "#d7dbe2")};
      border-radius: 6px;
    }
    .service-bar {
      background-color: ${(props) =>
        props.checked ? colors.pointBlue : "#d7dbe2"};
      height: 10px;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .point {
        width: 6px;
        height: 6px;
        background-color: white;
        border-radius: 3px;
        margin: 0 2px;
        z-index: 0;
      }
    }
    .full {
      width: 100%;
    }
    .range14 {
      width: 76%;
      .point:nth-child(5) {
        display: none;
      }
    }
    .range23 {
      margin-left: 24%;
      width: 52%;
      .point:nth-child(4),
      .point:nth-child(5) {
        display: none;
      }
    }
    .semi-range12 {
      position: absolute;
      left: 0px;
      width: 27%;
      opacity: 0.4;
    }
  `,
  TypeTitle: styled.strong<{ checked: boolean }>`
    display: inline-block;
    padding-top: 3px;
    font-size: 18px;
    font-weight: 700;
    color: ${colors.gray33};
    line-height: 27px;

    ${(props) =>
      props.checked &&
      css`
        color: ${colors.pointBlue};
      `};
  `,
  TypeSubtitle: styled.p`
    padding-top: 9px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: ${colors.gray66};
    @media screen and (max-width: 320px) {
      font-size: 14px;
    }
  `,
  TypeIcon: styled.div`
    float: right;
    margin-top: 10px;
    @media screen and (max-width: 320px) {
      margin-top: 16px;
    }
    svg {
      @media screen and (max-width: 320px) {
        width: 48px;
        height: 48px;
      }
    }
  `,
};

export const categories = [
  {
    title: "포장이사",
    subTitle1: "업체에게 모든 걸 맡기세요!",
    needPacking: true,
    isIlban: false,
    rangeStyle: "full",
  },
  {
    title: "반포장이사",
    subTitle1: "잔짐 포장은 내가, 큰 짐은 업체가!",
    needPacking: false,
    isIlban: false,
    rangeStyle: "range23",
    rangeStyle2: "semi-range12",
  },
  {
    title: "일반이사",
    subTitle1: "업체가 짐 운반만 도와드려요!",
    needPacking: false,
    isIlban: true,
    rangeStyle: "range23",
  },
];

export default function MovingTypes({}) {
  const dispatch = useDispatch();

  const history = useHistory();

  const isDesktop = useMedia({
    minWidth: 1200,
  });

  const currentStep = useSelector(orderSelector.getCurrentStep);
  const orderForm = useSelector(orderSelector.getOrderForm);

  const [index, setIndex] = useState<number | undefined>(undefined);

  const MovingTypeText = () => {
    // boolean 형 아님.
    if (orderForm.need_packing) {
      return "포장이사";
    } else if (orderForm.is_ilban) {
      return "일반이사";
    } else {
      return "반포장";
    }
  };

  const onNext = () => {
    dataLayer({
      event: "step_1",
      category: "다이사_원룸_신청",
      action: MovingTypeText(),
      label: "step_1",
    });

    events({
      action: "app_move_oneroom_order_01",
    });

    dispatch(
      orderActions.setCurrentStep({
        currentStep: currentStep + 1,
      })
    );
  };

  const onPrev = () => {
    if (currentStep <= 0) {
      history.goBack();
    } else {
      dispatch(
        orderActions.setCurrentStep({
          currentStep: currentStep - 1,
        })
      );
    }
  };

  return (
    <S.Container>
      <div className="bg-white">
        <div className="font-title weight-bold pb-16 preline px-24 w-max">
          {"원하시는 이사 방식을 \n선택해 주세요."}
        </div>
      </div>
      <div className="bg-gray w-max px-24 pt-24 pb-96">
        {categories.map((c, i) => {
          return (
            <SMovingTypes.Container
              key={i}
              checked={index === i}
              onClick={() => {
                setIndex(i);
                dispatch(
                  orderActions.setOrderForm({
                    order: {
                      ...orderForm,
                      need_packing: c.needPacking,
                      is_ilban: c.isIlban,
                    },
                  })
                );
                dataLayer({
                  event: "oneroom_moving_type",
                  label: c.title,
                });
              }}
            >
              <div>
                {/* index === i */}
                <div
                  className={`font-button1 pb-4 weight-700 ${
                    index === i ? colors.pointBlue : "color-33"
                  }`}
                >
                  {c.title}
                </div>
                <div
                  className={`font-body pb-16 ${
                    index === i ? colors.pointBlue : "color-66"
                  }`}
                >
                  {c.subTitle1}
                </div>
              </div>
              <div className="pb-4">
                <div>
                  <div className="service-bg mr-2">
                    <div className={`service-bar ${c.rangeStyle}`}>
                      {c.rangeStyle2 && (
                        <div className={`service-bar ${c.rangeStyle2}`}>
                          <div className="point" />
                        </div>
                      )}
                      <div className="point" />
                      <div className="point" />
                      <div className="point" />
                      <div className="point" />
                      <div className="point" />
                    </div>
                  </div>
                </div>
                <div className="service-text flex justify-between -mx-4 font-button3 pt-4">
                  <div>포장</div>
                  <div>운반</div>
                  <div className="">이동</div>
                  <div className="-mr-2">운반</div>
                  <div className="">뒷정리</div>
                </div>
              </div>
            </SMovingTypes.Container>
          );
        })}
      </div>
      <ButtonContainer>
        {isDesktop && (
          <S.PreviousButton onClick={onPrev}>이전</S.PreviousButton>
        )}
        <Button
          className="fixButton"
          theme="primary"
          onClick={onNext}
          disabled={!(index !== undefined)}
          data-cy="next"
        >
          다음
        </Button>
      </ButtonContainer>
    </S.Container>
  );
}
