import { API_URL_DA24 } from "constants/env";

/**
 * 지역별 순차감 자동화 (22.06.03)
 */
interface Area {
  area: string;
  level: string;
  value: number;
}

export async function http<T>(request: RequestInfo): Promise<T> {
  const response = await fetch(request);
  const data = await response.json();
  return data.data;
}

export const getAreaLevel = async (sido: string, gugun: string) => {
  const areaLevels = await http<Area[]>(`${API_URL_DA24}/area-cost-daily?movingtype=ONEROOM`);
  const address = `${sido}${gugun}`.replace(/ /g, "");
  try {
    const targetArea = areaLevels.find((data) => data.area === address);
    if (targetArea) return targetArea;
  } catch (error) {
    console.log(error);
  }
  return null;
};
