import * as React from "react";

function Search({
  width = 24,
  height = 24,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.107 18.888c.395-.051.643-.437.56-.826-.082-.389-.465-.633-.86-.588a7.56 7.56 0 114.6-2.284.82.82 0 00-.08.096.75.75 0 000 1.06l5.303 5.304a.75.75 0 001.06-1.06l-4.845-4.847a9 9 0 10-5.737 3.145z"
        fill="#121212"
      />
    </svg>
  );
}

export default Search;
