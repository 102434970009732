import React from "react";
import styled from "styled-components";
import dayjs, { Dayjs } from "dayjs";

import FullPopup from "components/base/fullPopup";
import DatePicker from "components/common/datePicker";

import { CalendarDate } from "lib/date";

const S = {
  Container: styled.div``,
  Info: styled.div`
    margin-top: 15px;
    padding: 16px 16px 14px;
    border: 1px solid #d7dbe2;
    border-radius: 6px;
    .infoTitle {
      position: relative;
      padding-left: 13px;
      font-size: 14px;
      line-height: 21px;
      word-break: break-word;

      .dot {
        position: absolute;
        top: 5px;
        left: -1px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: #f79016;
      }
      em {
        display: inline-block;
        margin-bottom: 2px;
        font-size: 15px;
        font-weight: 600;
      }
    }
  `,
};

interface Props {
  visible: boolean;
  closePopup: () => void;
  title: string;
  currentDate?: Dayjs | Date;
  selected?: string[];
  onSelect?(date: CalendarDate): void;
  rangeStartDate?: Dayjs | Date;
  rangeEndDate?: Dayjs | Date;
  disabledDate?: (date: Date) => boolean;
}

export default function CalendarFullPopup({
  visible,
  closePopup,
  title,
  onSelect,
  selected,
}: Props) {
  const rangeStartDate = React.useMemo(() => {
    return dayjs();
  }, []);

  const rangeEndDate = React.useMemo(() => {
    return dayjs().add(55, "day");
  }, []);

  return (
    <FullPopup
      visible={visible}
      closePopup={closePopup}
      title={title}
      height={680}
    >
      <DatePicker
        closePopup={closePopup}
        onSelect={onSelect}
        selected={selected}
        currentDate={new Date()}
        rangeStartDate={rangeStartDate}
        rangeEndDate={rangeEndDate}
      />
      <S.Info>
        <p className="infoTitle">
          <span className="dot" />
          <em>손 없는 날</em>
          <br />
          (손 없는 날 / 금~토요일은 가격이 비쌀 수 있어요!)
        </p>
      </S.Info>
      <S.Info style={{ marginTop: 10 }}>
        <p className="infoTitle">
          <em>조회가능 날짜</em>
          <br />
          내일부터 55일 이내 날짜에만 이사업체 조회가 가능해요!
        </p>
      </S.Info>
    </FullPopup>
  );
}
