import * as React from "react";

function SvgComponent({
  width = 16,
  height = 16,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="fill"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0a8 8 0 11-6.95 4.035.5.5 0 11.867.501l-.01.014a7 7 0 103.017-2.84.5.5 0 01-.548-.834l.07-.04-.001-.002A7.933 7.933 0 018 0zm.46 11.269v1h-1v-1h1zm1.3-6.398c-.466-.401-1.063-.602-1.789-.602-.704 0-1.279.195-1.723.586-.444.39-.706.945-.787 1.663l.978.106c.176-1.047.69-1.57 1.543-1.57.42 0 .775.14 1.068.422.292.28.438.607.438.978 0 .18-.043.359-.13.534-.085.175-.301.416-.647.724l-.296.265a6.863 6.863 0 00-.335.329c-.15.16-.272.329-.365.51a1.82 1.82 0 00-.185.554c-.03.189-.045.489-.045.899h.91c.01-.517.065-.875.166-1.074.1-.2.344-.473.732-.82l.162-.147c.414-.383.682-.702.802-.957.136-.286.204-.581.204-.885 0-.609-.234-1.114-.7-1.515z"
        fill="#BCC0C6"
      />
    </svg>
  );
}

export default SvgComponent;
