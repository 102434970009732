import { getAreaLevel } from "./areaLevel";
import * as sentry from "@sentry/react";
import { Severity } from "@sentry/react";

const googleAds: any = {
  접수_원룸_상상: { gwId: "AW-862163644", eventId: "C0-5CIfowIYDELyljpsD" },
  접수_원룸_상중: { gwId: "AW-862163644", eventId: "BJDsCNrjjoYDELyljpsD" },
  접수_원룸_상하: { gwId: "AW-862163644", eventId: "T30HCJ_njoYDELyljpsD" },
  접수_원룸_중상: { gwId: "AW-862163644", eventId: "RwGxCO7ojoYDELyljpsD" },
  접수_원룸_중중: { gwId: "AW-862163644", eventId: "RIqDCJvat4YDELyljpsD" },
  접수_원룸_중하: { gwId: "AW-862163644", eventId: "1zAjCL_pjoYDELyljpsD" },
  접수_원룸_하상: { gwId: "AW-862163644", eventId: "YkaQCP_bt4YDELyljpsD" },
  접수_원룸_하중: { gwId: "AW-862163644", eventId: "FJLVCJrct4YDELyljpsD" },
  접수_원룸_하하: { gwId: "AW-862163644", eventId: "AapNCJbet4YDELyljpsD" },
};

export const sendGAdsAreaAvgPrice = (sido: string, gugun: string) => {
  let areaLevel = null;
  try {
    getAreaLevel(sido, gugun).then((result) => {
      areaLevel = result;
      if (areaLevel && areaLevel.value !== 0) {
        const key = `접수_원룸_${areaLevel.level}`;
        gtag("event", "conversion", {
          send_to: `${googleAds[key].gwId}/${googleAds[key].eventId}`,
          value: areaLevel.value,
          currency: "KRW",
        });
      } else {
        throw "매핑 지역이 없거나, 이사 구분 없음";
      }
    });
  } catch (e) {
    sentry.setExtra("juso", {
      areaLevel: JSON.stringify(areaLevel),
      moveType: "원룸",
      juso: `${sido}${gugun}`,
    });
    sentry.captureMessage("픽셀 지역평균순차감 트래킹 실패", {
      level: Severity.Error,
    });
    sentry.captureException(e);
  }
};
