import { ADDRESS_KEY } from "constants/env";
import { da24Api, addressApi } from "lib/api";
import * as addressTypes from "types/address";

// export const getAddress = async (query: addressTypes.RequestAddressProps) => {
//   const { data } = await devDa24Api.request<addressTypes.RequestAddressProps>({
//     method: "get",
//     url: `/addresses?keyword=${query.keyword}&currPage=${query.currPage}&cntPerPage=${query.cntPerPage}`,
//   });

//   return data;
// };

// export const getDistance = async (query: addressTypes.RequestDistanceType) => {
//   const { data } = await devDa24Api.request<addressTypes.RequestDistanceType>({
//     method: "get",
//     url: `/addresses/distance?startZone=${query.startZone}&startRoad=${query.startRoad}&startIsGf=${query.startIsGf}&startMainBd=${query.startMainBd}&startSubBd=${query.startSubBd}&endZone=${query.endZone}&endRoad=${query.endRoad}&endIsGf=${query.endIsGf}&endMainBd=${query.endMainBd}&endSubBd=${query.endSubBd}`,
//   });
//   return data;
// };

export const getAddress = async (query: addressTypes.RequestAddressProps) => {
  return await addressApi.request<addressTypes.RequestAddressProps>({
    params: {
      confmKey: ADDRESS_KEY,
      currentPage: query.currPage,
      countPerPage: query.cntPerPage,
      keyword: query.keyword,
      resultType: "json",
    },
  });
};

export const getPrivacyPartner = async () => {
  const { data } = await da24Api.request({
    method: "get",
    url: "/partner-list?service_type=",
  });

  return data;
};

// export const verifySendMessage = async (phone: string) => {
//   const { data } = await opsApi.request<{phone: string}>({
//     method: 'post',
//     url: '/msg/auth',
//     data: {
//       'send_phone': 'DA24_PARTNER',
//       'dest_phone': `${phone}`
//     }
//   })
//
//   return data
// }

// export const verifyAuthCode = async (phone: string, code: string) => {
//   const { data } = await opsApi.request<{ phone: string; code: string }>({
//     method: 'get',
//     url: `/msg/auth?dest_phone=${phone}&code=${code}`
//     // url: `${AUTH_CODE_API_URL}?dest_phone=${phone}&code=${code}`
//   })
//
//   return data
// }
