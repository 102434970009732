import * as React from "react";

function NotFound({
  width = 223,
  height = 101,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 223 101"
      fill={fill}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.379 57.76V31.84h-19.12v25.92H24.056l37.8-56.16H40.473L.18 61.55v12.57h43.08v18h19.12v-18h11.76V57.76h-11.76zm24.573-46.938l-.002.001c-5.937 6.97-8.757 16.822-8.757 29.257v13.44c0 12.443 2.864 22.333 8.879 29.378 6.055 7.094 14.622 10.542 25.401 10.542 10.913 0 19.403-3.395 25.08-10.468 5.593-6.971 8.24-16.888 8.24-29.452V40.08c0-12.426-2.776-22.272-8.624-29.241C131.272 3.73 122.813.28 112.113.28c-10.63 0-19.112 3.456-25.161 10.542zm38 17.694l.006.023.008.023c.874 2.769 1.347 6.542 1.347 11.398v13.68c0 4.95-.435 8.779-1.241 11.564-.829 2.862-1.876 4.926-3.062 6.316-1.207 1.335-2.568 2.194-4.097 2.64-1.731.505-3.542.76-5.44.76-1.811 0-3.63-.253-5.461-.766-1.614-.452-3.109-1.363-4.491-2.813-1.336-1.403-2.53-3.472-3.508-6.326-.87-2.766-1.34-6.532-1.34-11.375V39.96c0-4.772.472-8.507 1.347-11.278.902-2.857 2.021-4.923 3.279-6.322 1.388-1.451 2.844-2.352 4.364-2.797a20.166 20.166 0 015.45-.763c1.898 0 3.709.255 5.44.76l.01.003.011.003c1.593.446 2.98 1.335 4.18 2.736l.034.038.035.038c1.225 1.289 2.302 3.281 3.129 6.138zm86.255 29.244V31.84h-19.12v25.92h-19.203l37.8-56.16h-21.383l-40.294 59.95v12.57h43.08v18h19.12v-18h11.76V57.76h-11.76z"
        fill="#F7F8FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.857 65.286c0-9.863-7.995-17.857-17.857-17.857-9.863 0-17.857 7.994-17.857 17.857 0 9.862 7.994 17.857 17.857 17.857 9.862 0 17.857-7.995 17.857-17.857zm-25 0a7.143 7.143 0 1114.286 0 7.143 7.143 0 01-14.286 0z"
        fill="url(#prefix__not_fount)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.286 31.357a5.358 5.358 0 11-10.715 0 5.358 5.358 0 0110.715 0zm32.142 0a5.357 5.357 0 11-10.714 0 5.357 5.357 0 0110.714 0zm-3.571 33.929a5.357 5.357 0 10-10.714-.001 5.357 5.357 0 0010.714 0z"
        fill="#1672F7"
      />
      <defs>
        <linearGradient
          id="prefix__not_fount"
          x1={129.857}
          y1={47.429}
          x2={94.143}
          y2={83.143}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1672F7" />
          <stop offset={1} stopColor="#15D4CC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default NotFound;
