import * as React from "react";

function CheckOn({
  width = 12,
  height = 9,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 9"
      fill={fill}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.664 7.785a1 1 0 01-1.422.007L.708 4.258a1 1 0 111.414-1.414L4.95 5.672 9.914.707a1 1 0 011.414 1.414L5.672 7.778a.849.849 0 01-.008.007z"
        fill="#fff"
      />
    </svg>
  );
}

export default CheckOn;
