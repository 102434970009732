// export const API_URL_MIDDLEWARE = process.env.REACT_APP_API_URL || 'https://d3heusccn8.execute-api.ap-northeast-2.amazonaws.com/dev'
export const API_URL_OPS = "https://www.ops.wematch.com";
export const API_URL_ONEROOM = "https://www.ops.wematch.com/oneroom";
export const API_URL_DA24 = "https://www.ops.wematch.com/da24";

export const MOVE_URL = "https://da24.wematch.com";
export const CLEAN_URL = "https://da24.wematch.com/clean";

export const MAIN_URL = "https://da24.wematch.com";

export const SENTRY_DSN =
  "https://258a94d7b91d44e1be1fd838f2adb005@o257922.ingest.sentry.io/5435440";
export const SENTRY_ENV = "production";
export const EDGE_REDIRECT_URL =
  process.env.REACT_APP_EDGE_REDIRECT_URL_DEV ||
  "microsoft-edge:http://oneroom.wematch.com/requests/order";

export const API_SLACK_WEBHOOK_PPOMPPU =
  "https://hooks.slack.com/services/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";

export const API_ADDRESS = "https://www.juso.go.kr/addrlink/addrLinkApi.do";
export const ADDRESS_KEY = "U01TX0FVVEgyMDIxMDMzMDE2MjkxMjExMDk4NTk=";