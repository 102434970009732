import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "react-use-media";
import styled, { css } from "styled-components";

import { PrivacyPolicyFullPopup } from "components/common";
import Input from "components/da24/Input";
import Button from "components/da24/Button";
import NewModal from "components/da24/NewModalTemplate";

import RequiredConsent from "pages/privacy/component/RequiredConsent";
import * as userActions from "services/user/actions";
import * as userSelector from "services/user/selectors";
import * as commonSelector from "services/common/selectors";

import useTimer from "hooks/useTimer";
import useHashToggle from "hooks/useHashToggle";

import getMobileOS from "lib/getMobileOS";
import { dataLayer } from "lib/gtm";
import { checkApp } from "lib/checkDevice";
import { InitService } from "types/auth";
import colors from "styles/colors";
import { Spinner } from "@wematch/wematch-ui";
import { debounce } from "lodash";

interface Props {
  onPrev: () => void;
  cancelModal: React.ReactNode;
}

export default function LoginPage({ onPrev, cancelModal }: Props) {
  const dispatch = useDispatch();
  const isDesktop = useMedia({
    minWidth: 1200,
  });
  const mobileOS = getMobileOS();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState<string>("");
  const [deviceId, setDeviceId] = useState<string | undefined>("");
  const [userAgent, setUserAgent] = useState("");
  const [isTimeout, setIsTimeout] = useState(false);
  const [isMobileKeyboard, setIsMobileKeyboard] = useState(false);

  const { counter, handleCounterStart } = useTimer(180);
  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const verifyRef = useRef<HTMLInputElement | null>(null);
  const [visibleTerms, setVisibleTerms] = useHashToggle("#terms");
  const [visibleTimeout, setVisibleTimeout] = useHashToggle("#timeout");
  const [submitted, setSubmitted] = useState(false);

  const { isError } = useSelector(userSelector.getUser);
  const { isVerified, isSendMessage, loading } = useSelector(userSelector.getPhoneVerified);
  const sendMessageError = useSelector(userSelector.getSendMessageError);
  const getDeviceId = useSelector(commonSelector.getDeviceId);

  // 필수약관 동의 체크
  const [checked, setChecked] = useState(true);

  const handleSubmit = debounce(() => {
    setIsTimeout(false);
    dispatch(
      userActions.fetchVerifySendMessageAsync.request({
        phone,
      })
    );
  }, 300);

  const handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const originPhoneValue = event.target.value.replace(/-/gi, "");
    setPhone(originPhoneValue);
  };
  const isNumRegex = /^[0-9]+$/g;
  const isAuth = useMemo(() => !(!!name && phone.length >= 11 && isNumRegex.test(phone)) || !!isVerified, [name, phone, isVerified, isNumRegex]);

  const handleVerify = () => {
    dispatch(
      userActions.fetchVerifyCodeAsync.request({
        phone,
        code,
      })
    );
  };

  const displayCount = (count: number) => `${Math.floor(count / 60)}:${count % 60 < 10 ? `0${count % 60}` : count % 60}`;

  const payload = {
    tel: phone,
    name: name,
    init_service: InitService.MOVE_ONEROOM,
    code,
    user_agent: userAgent,
    device_id: deviceId,
    agreed_marketing: new Date().toISOString(),
  };

  if (!checkApp()) {
    delete payload.device_id;
  }

  const handleSignUp = () => {
    setSubmitted(true);
    // app에서 디바이스 아이디를 못가고 오게되면 회원가입이 안되기 때문에 예외처리한다.
    if (checkApp() && !deviceId) {
      delete payload.device_id;
    }
    dispatch(userActions.fetchSignUpAsync.request(payload));
  };

  useEffect(() => {
    setUserAgent(window.navigator.userAgent);
  }, [window]);

  useEffect(() => {
    if (getDeviceId) {
      setDeviceId(getDeviceId);
    }
  }, [commonSelector]);

  useEffect(() => {
    dispatch(userActions.phoneVerifyCancel());
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (mobileOS === "Android") {
        if (window.innerHeight < 500) {
          setIsMobileKeyboard(true);
        } else {
          setIsMobileKeyboard(false);
        }
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [mobileOS]);

  useEffect(() => {
    if (counter === 0) {
      setVisibleTimeout(true);
    }
  }, [counter]);

  useEffect(() => {
    if (isSendMessage) {
      verifyRef?.current?.focus();
      handleCounterStart();
    }
  }, [isSendMessage, handleCounterStart]);

  useEffect(() => {
    if (isVerified !== null && code.length === 0) {
      dispatch(userActions.phoneVerifyReset());
    }
  }, [dispatch, code, isVerified]);

  return (
    <Container>
      <LoginModalWrapper id="dsl_login_popup">
        <div style={{ width: "100%" }}>
          <TextWrapper>
            {/*<strong>번호인증</strong>*/}
            <p>
              업체와의 <span>견적상담신청/내신청내역 확인</span>을 위해
              <span> 번호인증</span>이 필요해요. (최초 1회만 인증)
            </p>
          </TextWrapper>
          <FormWrapper>
            <Input
              theme="default"
              border
              placeholder="이름"
              data-cy="authName"
              maxLength={8}
              disabled={!!isVerified}
              inputRef={nameInputRef}
              onChange={(e) => setName(e.target.value)}
              value={name}
              style={{ backgroundColor: isVerified ? "" : "transparent" }}
              onBlur={(e) => {
                if (e.target.value.length >= 2) {
                  dataLayer({
                    event: "login",
                    category: "로그인_팝업",
                    action: "이름",
                    label: "회원명",
                  });
                }
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                theme="default"
                border
                placeholder="휴대폰 번호(-없이)"
                data-cy="authPhone"
                pattern="[0-9]*"
                inputMode="numeric"
                value={phone}
                onChange={handlePhone}
                style={{ backgroundColor: isVerified ? "" : "transparent" }}
                rootStyle={{ flex: 1 }}
                maxLength={11}
                disabled={!!isVerified}
                onBlur={(e) => {
                  if (e.target.value.length >= 2) {
                    dataLayer({
                      event: "login",
                      category: "로그인_팝업",
                      action: "휴대폰번호",
                      label: "회원연락처",
                    });
                  }
                }}
              />
              <Button
                theme="primary"
                disabled={isAuth}
                style={{
                  width: "90px",
                  marginLeft: "7px",
                  borderRadius: "4px",
                }}
                onClick={handleSubmit}
                bold
                data-cy="authRequestBtn"
              >
                {!isSendMessage && !isVerified ? "인증요청" : "재전송"}
              </Button>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flex: 1, position: "relative" }}>
                <Input
                  theme="default"
                  border
                  placeholder="인증번호"
                  data-cy="authNumber"
                  maxLength={6}
                  value={code}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  style={{
                    backgroundColor: !isSendMessage || isVerified ? "" : "transparent",
                    borderColor: isVerified === false ? "#EC485C" : "",
                  }}
                  inputRef={verifyRef}
                  disabled={!isSendMessage || !!isVerified}
                  onBlur={(e) => {
                    if (e.target.value.length >= 2) {
                      dataLayer({
                        event: "login",
                        category: "로그인_팝업",
                        action: "인증번호",
                        label: "인증번호입력",
                      });
                    }
                  }}
                />
                <CounterWrapper>
                  <span>{isSendMessage && displayCount(counter)}</span>
                </CounterWrapper>
              </div>
              <Button
                theme="primary"
                disabled={!isSendMessage || isVerified || isTimeout}
                onClick={handleVerify}
                data-cy="authConfirm"
                bold={true}
                style={{
                  width: "90px",
                  marginLeft: "7px",
                  borderRadius: "4px",
                }}
              >
                확인
              </Button>
            </div>
            <div>
              {!loading && sendMessageError && <ErrorMessage>인증번호 전송 횟수를 초과하였습니다. 5분 후에 다시 시도해주세요.</ErrorMessage>}
              {isVerified === false && !loading && code.length > 0 && <ErrorMessage>인증번호를 잘못 입력했습니다.</ErrorMessage>}
              {isVerified === true && !loading && code.length > 0 && <SuccessMessage>인증이 완료되었습니다.</SuccessMessage>}
            </div>
          </FormWrapper>

          {/*{mobileOS === 'Android' && <MobileKeyboardSection isMobileKeyboard={isMobileKeyboard} />}*/}
          <FooterWrapper isIOS={mobileOS === "iOS"}>
            <RequiredConsent checked={checked} setChecked={setChecked} />
            <Button
              theme="primary"
              disabled={!isVerified || !name || !checked}
              style={{
                fontSize: "18px",
                borderRadius: "6px",
                textAlign: "center",
              }}
              bold
              onClick={handleSignUp}
              data-cy="agreeNextBtn"
            >
              {!submitted ? "동의하고 진행하기" : <Spinner color="white" size="small" />}
            </Button>
            {isDesktop && (
              <Button theme="default" border={true} style={{ border: "1px solid #D7DBE2", marginTop: 10 }} onClick={onPrev}>
                이전
              </Button>
            )}
          </FooterWrapper>
        </div>
      </LoginModalWrapper>
      {/*인증번호 초과 모달*/}
      <NewModal
        visible={visibleTimeout}
        title={"인증번호 입력시간 초과"}
        content={"인증번호 입력가능시간이 초과 되었습니다. 인증번호를 다시 받아주세요!"}
        confirmText={"확인"}
        confirmClick={() => {
          setVisibleTimeout(!visibleTimeout);
          setIsTimeout(true);
        }}
      />
      {/*로그인/가입 취소 모달*/}
      <NewModal
        visible={isError}
        title={"죄송합니다"}
        content={"진행 중 오류가 발생하였습니다. 문제가 지속될 시 고객센터로 문의해주세요. \n고객센터 1522-2483"}
        confirmText={"확인"}
        confirmClick={() => dispatch(userActions.setIsError(false))}
      />
      {cancelModal}
      <PrivacyPolicyFullPopup visible={visibleTerms} closePopup={() => setVisibleTerms(!visibleTerms)} title="이용약관 및 개인정보처리방침" />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  min-width: 360px;
  height: 100vh;
  //height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: white;
  box-sizing: border-box;

  @media screen and (min-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

const LoginModalWrapper = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  margin-top: 56px;

  @media screen and (min-width: 768px) {
    width: 312px;
    justify-content: flex-start;
    align-items: center;
    padding-top: 100px;
  }
`;

// const MobileKeyboardSection = styled.div<{ isMobileKeyboard: boolean }>`
//     height: ${({ isMobileKeyboard }) => isMobileKeyboard ? `${window.innerHeight}px` : '0'};
// `

const TextWrapper = styled.div`
  font-size: 16px;
  letter-spacing: -0.01em;
  line-height: 23px;
  font-style: normal;
  font-weight: normal;
  color: ${colors.gray66};

  padding: 0 24px;
  strong {
    display: block;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.03em;
    margin-bottom: 8px;
    color: ${colors.gray33};
  }

  p {
    margin-bottom: 28px;
  }

  span {
    font-weight: 700;
  }

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;

const FormWrapper = styled.div`
  padding: 0 24px;
  input {
    color: ${colors.gray33};
  }

  @media screen and (min-width: 768px) {
    padding: 0;
    margin-bottom: 100px;
  }
`;

const FooterWrapper = styled.div<{ isIOS: boolean }>`
  padding: 24px;
  font-size: 14px;
  p {
    color: ${colors.gray66};
    padding-bottom: 16px;
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  a {
    color: ${colors.gray66};
    text-decoration: underline;
    margin-left: 5px;
    line-height: 24px;
    margin-bottom: -2px;
  }

  @media screen and (min-width: 768px) {
    padding: 0;
    padding-bottom: 16px;
    p {
      padding-bottom: 16px;
    }
    button {
      border-radius: 4px;
    }
  }
`;

const ErrorMessage = styled.span`
  color: #fa3c3c;
`;

const SuccessMessage = styled.span`
  color: #1672f7;
`;

const CounterWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 18px;
  span {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.gray33};
  }
`;
