export const openDefaultBrowser = (url: string) => {
  const userAgent = window.navigator.userAgent;
  const isApp = userAgent.indexOf("WmClientApp");
  if (isApp > 0) {
    const data = {
      action: "app",
      value: url,
    };
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } catch {
      window.open(url, "_blank");
    }
  } else {
    window.open(url, "_blank");
  }
};
