import * as React from "react";

function Dryer({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M13.915 12.763c-.278.067-.591.342-.792.643a.75.75 0 11-1.248-.832c.3-.449.886-1.074 1.684-1.269a2 2 0 011.36.144c.453.217.853.6 1.204 1.125.25.374.462.536.605.605.13.062.243.066.356.039.277-.068.59-.343.791-.644a.75.75 0 111.248.832c-.3.45-.886 1.074-1.684 1.27a2 2 0 01-1.36-.145c-.453-.217-.853-.599-1.204-1.125-.25-.374-.462-.536-.605-.604a.502.502 0 00-.355-.04z"
        fill={fill}
      />
      <path
        d="M13.123 16.406c.2-.3.514-.576.792-.643a.502.502 0 01.355.039c.143.068.356.23.605.604.35.526.75.908 1.205 1.126a2 2 0 001.36.143c.797-.195 1.384-.82 1.683-1.269a.75.75 0 10-1.248-.832c-.2.301-.514.576-.791.644a.502.502 0 01-.356-.04c-.143-.068-.356-.23-.605-.604-.35-.526-.75-.908-1.205-1.125a2 2 0 00-1.36-.144c-.797.195-1.384.82-1.683 1.27a.75.75 0 101.248.831z"
        fill={fill}
      />
      <path
        d="M16.448 19.18c.289-.297.22-.771-.111-1.02-.33-.25-.797-.178-1.098.107a4.501 4.501 0 11-2.356-7.706c.409.068.836-.133.97-.525.135-.392-.072-.823-.478-.908a6 6 0 103.073 10.052z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 0a.75.75 0 00-.75.75v22.5c0 .414.336.75.75.75h19.5a.75.75 0 00.75-.75V.75a.75.75 0 00-1.5 0V6H3V1.5h15.75a.75.75 0 000-1.5H2.25zM21 7.5v15H3v-15h18z"
        fill={fill}
      />
    </svg>
  );
}

export default Dryer;
