import * as React from "react";

function RunningMachine({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M11.25 20.25a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5h-1.5z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 2.25a2.25 2.25 0 014.5 0v3l.75.75h1.5a2.25 2.25 0 010 4.5H6.038l1.968 7.345A.753.753 0 018.03 18H21a3 3 0 110 6H3a3 3 0 110-6h3.494l-1.987-7.419a.759.759 0 01-.02-.093L3 9v6.75a.75.75 0 01-1.5 0V2.25zm3 0v3.621L6.129 7.5H8.25a.75.75 0 010 1.5H5.121L3 6.879V2.25a.75.75 0 011.5 0zM6 21c0-.546-.146-1.059-.401-1.5H18.4A2.987 2.987 0 0018 21c0 .546.146 1.059.401 1.5H5.6c.255-.441.401-.954.401-1.5zm-1.5 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM21 22.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill={fill}
      />
    </svg>
  );
}

export default RunningMachine;
