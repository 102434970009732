import React, { useEffect } from "react";
import Styled from "styled-components";
import colors from "styles/colors";
import PopupTemplate from "./PopupTemplate";
import * as commonActions from "services/common/actions";
import * as commonSelector from "services/common/selectors";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  visible: boolean;
  onClose: () => void;
}

const PrivacyPartnerModal: React.FC<Props> = (props) => {
  const { visible = false, onClose } = props;
  const dispatch = useDispatch();
  const partners = useSelector(commonSelector.getPrivacyPartner);

  useEffect(() => {
    dispatch(commonActions.fetchPrivacyPartnerListAsync.request());
  }, []);

  return (
    <PopupTemplate visible={visible} onClose={onClose}>
      <S.Container>
        <S.Wrapper>
          <S.Title>(주)다이사 회원사</S.Title>
          <p>
            {partners.data?.move},{partners.data?.clean},{partners.data?.rental},{partners.data?.internet}
          </p>
        </S.Wrapper>
      </S.Container>
    </PopupTemplate>
  );
};

const S = {
  Container: Styled.div`
    position: relative;
    padding: 0 24px;
    height: 100%;
    overflow-y: auto;
    margin-top: 56px;
  `,
  Wrapper: Styled.div`
    padding-top: 10px;
    top: 0;
    left: 24px;
    right: 24px;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.gray33};
    
    @media (min-width: 1200px) {
      width: 312px;
    }
    
    h4 {
        display: block;
        margin-bottom: 3px;
        font-size: 15px;
        font-weight: bold;
        line-height: 26px;
        color: ${colors.gray33};
    }
  `,
  Title: Styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    color: ${colors.gray33};
    margin-bottom: 25px;
  `,
};

export default PrivacyPartnerModal;
