import * as React from "react";

function SvgComponent({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.75 8.148a.75.75 0 100 1.5H3v9.75a.75.75 0 001.5 0v-3.75h5.25a.75.75 0 00.75-.75v-5.25h9v9.75a.75.75 0 001.5 0v-9.75h2.25a.75.75 0 000-1.5H.75zm8.25 4.5v1.5H4.5v-1.5H9zm0-3v1.5H4.5v-1.5H9z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgComponent;
