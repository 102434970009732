const REFERER = "REFERRER";

export const setReferer = () => {
  const savedReferer = sessionStorage.getItem(REFERER);
  const isNullSavedReferer = !savedReferer || savedReferer.length === 0;
  if (document.referrer.includes("wematch.com") && !isNullSavedReferer) {
    return;
  } else {
    sessionStorage.setItem(REFERER, document.referrer);
  }
};

export const getReferer = () => {
  const referer = sessionStorage.getItem(REFERER);
  return referer ? referer : "";
};
