import React from "react";

const Dot = () => {
  return (
    <span style={{ margin: "0 6px" }}>
      <img src={require("assets/images/dot.svg")} alt="" style={{ verticalAlign: "middle" }} />
    </span>
  );
};

export default Dot;
