import { createReducer, ActionType } from "typesafe-actions";
import * as actions from "./actions";
import * as addressTypes from "types/address";
import { Juso } from "types/address";

export type Actions = ActionType<typeof actions>;
export const CNT_PER_PAGE = 30;

export interface CommonState {
  addressList: {
    data: addressTypes.Juso[];
    hasMore: boolean;
    loading: boolean;
    error: addressTypes.AddressErrorType | undefined;
  };
  sendVerifyCode: {
    loading: boolean;
    error: boolean;
    status: "wait" | "send" | "success" | "failure";
  };
  phoneVerify: {
    data: { is_verified: boolean | undefined };
    loading: boolean;
  };
  deviceId: string;
  juso: {
    start: Juso | null;
    end: Juso | null;
    distance: number;
  };
  privacyPartner: {
    data: any;
    loading: boolean;
  };
}

const initialState: CommonState = {
  addressList: {
    data: [],
    hasMore: false,
    loading: false,
    error: undefined,
  },
  sendVerifyCode: {
    loading: false,
    error: true,
    status: "wait",
  },
  phoneVerify: {
    data: {
      is_verified: undefined,
    },
    loading: false,
  },
  deviceId: "",
  juso: {
    start: null,
    end: null,
    distance: 1,
  },
  privacyPartner: {
    data: undefined,
    loading: false,
  },
};

export default createReducer<CommonState, Actions>(initialState)
  .handleAction(actions.fetchVerifySendMessageAsync.request, (state) => ({
    ...state,
    sendVerifyCode: { loading: true, error: false, status: "send" },
  }))
  .handleAction(actions.fetchVerifySendMessageAsync.success, (state) => ({
    ...state,
    sendVerifyCode: { loading: false, error: false, status: "success" },
  }))
  .handleAction(actions.fetchVerifySendMessageAsync.failure, (state) => ({
    ...state,
    sendVerifyCode: { loading: false, error: true, status: "failure" },
  }))
  .handleAction(actions.fetchAddressListAsync.request, (state) => ({
    ...state,
    addressList: { ...state.addressList, loading: true, error: undefined },
  }))
  .handleAction(actions.fetchAddressListAsync.success, (state, action) => ({
    ...state,
    addressList: {
      data: action.payload.results.juso,
      loading: false,
      error: undefined,
      hasMore: action.payload.results.juso.length === CNT_PER_PAGE,
    },
  }))
  .handleAction(actions.fetchAddressListAsync.failure, (state, action) => ({
    ...state,
    addressList: {
      data: [],
      loading: false,
      error: action.payload,
      hasMore: false,
    },
  }))
  .handleAction(actions.fetchAddressMoreListAsync.request, (state) => ({
    ...state,
    addressList: { ...state.addressList, loading: true, error: undefined },
  }))
  .handleAction(actions.fetchAddressMoreListAsync.success, (state, action) => ({
    ...state,
    addressList: {
      data: [...state.addressList.data, ...action.payload.results.juso],
      loading: false,
      error: undefined,
      hasMore: action.payload.results.juso.length === CNT_PER_PAGE,
    },
  }))
  .handleAction(actions.fetchAddressMoreListAsync.failure, (state, action) => ({
    ...state,
    addressList: {
      data: [],
      loading: false,
      error: action.payload,
      hasMore: false,
    },
  }))
  .handleAction(actions.fetchVerifyCodeAsync.request, (state) => ({
    ...state,
    phoneVerify: { ...state.phoneVerify, loading: true },
  }))
  .handleAction(actions.fetchVerifyCodeAsync.success, (state, action) => ({
    ...state,
    phoneVerify: { data: action.payload, loading: false },
  }))
  .handleAction(actions.resetAddressList, (state) => ({
    ...state,
    addressList: { ...initialState.addressList },
  }))
  .handleAction(actions.resetAll, (state) => ({ ...state, ...initialState }))
  .handleAction(actions.setDeviceId, (state, action) => ({
    ...state,
    deviceId: action.payload,
  }))
  .handleAction(actions.setJuso, (state, action) => ({
    ...state,
    juso: action.payload,
  }))
  .handleAction(actions.fetchPrivacyPartnerListAsync.request, (state) => ({ ...state, privacyPartner: { ...state.privacyPartner, loading: true } }))
  .handleAction(actions.fetchPrivacyPartnerListAsync.success, (state, action) => ({ ...state, privacyPartner: { data: action.payload.data, loading: false } }));
