import * as React from "react";

function Plus({
  width = 24,
  height = 24,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 2.25a.75.75 0 011.5 0v8.25h9a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5h9V2.25zm0 12a.75.75 0 011.5 0v7.5a.75.75 0 01-1.5 0v-7.5z"
        fill="#333"
      />
    </svg>
  );
}

export default Plus;
