import * as React from "react";

function CheckCircleOn({
  width = 24,
  height = 24,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <circle cx={12} cy={12} r={12} fill="#1672F7" />
      <path
        d="M6.166 10.434a.75.75 0 011.06 0l3.713 3.713 5.834-5.834a.75.75 0 011.06 1.06l-6.277 6.278a.872.872 0 01-1.233 0l-4.157-4.156a.75.75 0 010-1.06z"
        fill="#fff"
      />
    </svg>
  );
}

export default CheckCircleOn;
