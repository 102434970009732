import * as React from "react";

function TV({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.31 2.25a.75.75 0 00-1.06 1.06l2.16 2.16H2.25a.75.75 0 00-.75.75v12c0 .414.336.75.75.75H3v2.25a.75.75 0 001.5 0v-2.25h15v2.25a.75.75 0 001.5 0v-2.25h.75a.75.75 0 00.75-.75v-12a.75.75 0 00-1.5 0v11.25H3V6.97h15.75a.75.75 0 000-1.5h-5.159l2.159-2.16a.75.75 0 00-1.06-1.06L12 4.94 9.31 2.25z"
        fill={fill}
      />
    </svg>
  );
}

export default TV;
