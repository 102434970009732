import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useMedia } from "react-use-media";
import { debounce } from "lodash";

import { ButtonContainer } from "components/templates/multistep";
import { Button, Checkbox, Input } from "components/common";
import Select from "components/da24/Select";
import CalendarModal from "components/da24/NewCalendarModal";
import AddressModal from "components/da24/NewAddressModal";

import * as orderActions from "services/order/actions";
import * as orderSelector from "services/order/selectors";
import * as userSelectors from "services/user/selectors";
import * as commonSelector from "services/common/selectors";
import * as commonActions from "services/common/actions";

import colors from "styles/colors";
import { dataLayer } from "lib/gtm";
import { events } from "lib/appsflyer";
import { CalendarDate, isExceedDiffDay } from "lib/date";
import useLockedBody from "hooks/useLockedBody";
import { Juso, JUSO_TYPE } from "types/address";
import { Order } from "types/order";

const S = {
  Container: styled.div`
    background-color: white;
    padding: 0px 24px 96px;
    margin: 0 auto 66px;
    .fixButton {
      width: 100%;
      @media screen and (min-width: 1200px) {
        width: 70%;
      }
    }
  `,
  Section: styled.section`
    margin-bottom: 50px;
    .detailAddress {
      :focus::-webkit-input-placeholder {
        color: transparent;
      }
      :focus::-ms-input-placeholder {
        color: transparent;
      }
    }
  `,
  Title: styled.h3`
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
  `,
  Group: styled.div`
    display: flex;
    flex-direction: row;
  `,
  Guide: styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: ${colors.gray33};
    margin-top: 9px;
    padding-left: 32px;
    padding-right: 25px;
  `,
  PreviousButton: styled.button`
    float: left;
    width: 30%;
    height: 56px;
    border: 1px solid #d7dbe2;
    font-size: 18px;
    background: #fff;
  `,
};

const floor = [
  { key: "지하", value: "지하층" },
  { key: "1", value: "1층" },
  { key: "2", value: "2층" },
  { key: "3", value: "3층" },
  { key: "4", value: "4층" },
  { key: "5", value: "5층" },
  { key: "6", value: "6층" },
  { key: "7", value: "7층" },
  { key: "8", value: "8층" },
  { key: "9", value: "9층" },
  { key: "10", value: "10층" },
  { key: "11", value: "11층" },
  { key: "12", value: "12층" },
  { key: "13", value: "13층" },
  { key: "14", value: "14층" },
  { key: "15", value: "15층" },
  { key: "16", value: "16층" },
  { key: "17", value: "17층" },
  { key: "18", value: "18층" },
  { key: "19", value: "19층" },
  { key: "20", value: "20층" },
  { key: "21", value: "21층" },
  { key: "22", value: "22층" },
  { key: "23", value: "23층" },
  { key: "24", value: "24층" },
  { key: "25", value: "25층 이상" },
];

export default function Location() {
  const dispatch = useDispatch();
  const history = useHistory();

  const isDesktop = useMedia({
    minWidth: 1200,
  });

  const CALENDAR_MAX_DAYS = 55;

  const currentStep = useSelector(orderSelector.getCurrentStep);
  const orderForm = useSelector(orderSelector.getOrderForm);
  const getLocation = useSelector(orderSelector.getTempLocation);
  const getJuso = useSelector(commonSelector.getJuso);
  const { token, user, loading } = useSelector(userSelectors.getUser);

  const [visible, setVisible] = useState({
    from: false,
    to: false,
  });
  const [fromAddress, setFromAddress] = useState("");
  const [toAddress, setToAddress] = useState("");
  const [locked, setLocked] = useLockedBody();

  const onNext = () => {
    dataLayer({
      event: "step_3",
      category: "다이사_원룸_신청",
      action: "이사조건",
      label: "step_3",
    });

    events({
      action: "app_move_oneroom_order_03",
    });

    // 세종,제주 제외 특별자치 제거
    const handleSido = (sido: string) => {
      let rpSido = sido;
      if (!sido.includes("세종") && !sido.includes("제주")) {
        rpSido = sido.replace("특별자치", "");
      }
      return rpSido;
    };

    const orderData: Order = {
      ...orderForm,
      moving_date: getLocation.movingDate,
      start_sido: handleSido(`${getJuso.start?.siNm}`),
      start_gugun: `${getJuso.start?.sggNm}`,
      start_dong: `${getJuso.start?.emdNm}`,
      start_detail: `${getJuso.start?.rn + " " + getJuso.start?.buldMnnm + "-" + getJuso.start?.buldSlno + " " + getLocation?.movingFromDetail}`,
      start_floor: `${getLocation.movingFromFloor.value}`,
      end_sido: handleSido(`${getJuso.end?.siNm}`),
      end_gugun: `${getJuso.end?.sggNm}`,
      end_dong: `${getJuso.end?.emdNm}`,
      end_detail: `${getJuso.end?.rn + " " + getJuso.end?.buldMnnm + "-" + getJuso.end?.buldSlno + " " + getLocation?.movingToDetail}`,
      end_floor: `${getLocation.movingToFloor.value}`,
      need_storage: getLocation.needStorage,
      agreed_terms: true, // 값 고정 (ui 사라졌음)
      agreed_marketing: true, // 값 고정 (ui 사라졌음)
      avail_time: "9:00~22:00", // 값 고정 (ui 사라졌음),
    };

    if (orderForm) {
      /* setOrderForm 에서 거리계산 */
      dispatch(
        orderActions.setOrderForm({
          order: orderData,
        })
      );
    }

    // 로그인 상태일 경우 바로 접수
    if (user && token) {
      dispatch(
        orderActions.submitOrderAsync.request({
          order: orderData,
          token: token,
        })
      );
    } else {
      dispatch(
        orderActions.setCurrentStep({
          currentStep: currentStep + 1,
        })
      );
    }
  };

  const onPrev = () => {
    if (currentStep <= 0) {
      history.goBack();
    } else {
      dispatch(
        orderActions.setCurrentStep({
          currentStep: currentStep - 1,
        })
      );
    }
  };

  const [calendarPopup, setCalendarPopup] = useState(false);
  const toggleCalendar = () => {
    setCalendarPopup(!calendarPopup);
  };

  const [addressFromPopup, setAddressFromPopup] = useState(false);
  const toggleAddressFrom = () => {
    setAddressFromPopup(!addressFromPopup);
  };

  const [addressToPopup, setAddressToPopup] = useState(false);
  const toggleAddressTo = () => {
    setAddressToPopup(!addressToPopup);
  };

  // useEffect(() => {
  //   if (addressFromPopup || addressToPopup) {
  //     setLocked(true)
  //     return () => {
  //       setLocked(false)
  //     }
  //   }
  // }, [addressFromPopup, addressToPopup])

  const isDisabled = () => {
    return !(
      getLocation.movingDate &&
      getLocation.movingFrom &&
      getLocation.movingFromDetail &&
      getLocation.movingFromFloor &&
      getLocation.movingFromFloor.value &&
      getLocation.movingTo &&
      getLocation.movingToDetail &&
      getLocation.movingToFloor &&
      getLocation.movingToFloor.value
    );
  };

  // 공백 때문에 input placeholder 안나와서....
  useEffect(() => {
    if (!getLocation.movingFrom) return;

    setFromAddress(`${getLocation.movingFrom}`);
  }, [getLocation.movingFrom]);

  useEffect(() => {
    if (!getLocation.movingTo) return;

    setToAddress(`${getLocation.movingTo}`);
  }, [getLocation.movingTo]);

  const debounceSelectDate = debounce((date: CalendarDate) => {
    setCalendarPopup(false);
  }, 300);

  const calendarModalSelector = (selectDate: CalendarDate) => {
    if (isExceedDiffDay(selectDate, CALENDAR_MAX_DAYS)) {
      alert(`이사업체조회는 내일부터 최장${CALENDAR_MAX_DAYS}일까지만 비교가 가능합니다.`);
      return;
    }
    dispatch(
      orderActions.setTempLocation({
        ...getLocation,
        movingDate: selectDate.date.format("YYYY-MM-DD"),
      })
    );
    debounceSelectDate(selectDate);
  };

  const onSelectStartAddress = (juso: Juso, type: JUSO_TYPE) => {
    dispatch(
      orderActions.setTempLocation({
        ...getLocation,
        movingFrom: type === "road" ? juso.roadAddr : juso.jibunAddr,
      })
    );

    dispatch(
      commonActions.setJuso({
        ...getJuso,
        start: juso,
      })
    );

    toggleAddressFrom();
  };

  const onSelectEndAddress = (juso: Juso, type: JUSO_TYPE) => {
    dispatch(
      orderActions.setTempLocation({
        ...getLocation,
        movingTo: type === "road" ? juso.roadAddr : juso.jibunAddr,
      })
    );

    dispatch(
      commonActions.setJuso({
        ...getJuso,
        end: juso,
      })
    );

    toggleAddressTo();
  };

  return (
    <S.Container>
      <div className="w-max">
        <S.Section>
          <S.Title>이사예정일</S.Title>
          <Input
            theme="default"
            icon="down"
            border
            readOnly
            placeholder="이사예정일"
            onClick={toggleCalendar}
            value={getLocation.movingDate}
            data-cy="movingDate"
          />
          <CalendarModal
            visible={calendarPopup}
            title="날짜선택"
            onClose={toggleCalendar}
            onSelect={calendarModalSelector}
            selected={[getLocation.movingDate]}
          />
        </S.Section>
        <S.Section>
          <S.Title>출발지</S.Title>
          <Input
            theme="default"
            border
            icon="search"
            readOnly
            placeholder="주소 검색"
            onClick={toggleAddressFrom}
            data-cy="startDong"
            value={fromAddress}
            style={{ paddingRight: 42 }}
          />
          <AddressModal
            visible={addressFromPopup}
            title="주소검색"
            onClose={toggleAddressFrom}
            onConfirm={toggleAddressFrom}
            onClick={toggleAddressFrom}
            onSelect={onSelectStartAddress}
          />
          <Input
            theme="default"
            border
            icon="down"
            placeholder="층수"
            readOnly
            value={getLocation.movingFromFloor.value}
            onClick={() => {
              setVisible({
                ...visible,
                from: !visible.from,
              });
            }}
            data-cy="startFloor"
          />
          <Select
            visible={visible.from}
            items={floor}
            onClose={() =>
              setVisible({
                ...visible,
                from: !visible.from,
              })
            }
            onSelect={(key) => {
              const value = key + "층";
              dispatch(
                orderActions.setTempLocation({
                  ...getLocation,
                  movingFromFloor: {
                    key,
                    value,
                  },
                })
              );
            }}
            headerTitle="층수 선택"
          />
          <Input
            className="detailAddress"
            theme="default"
            border
            placeholder="상세주소"
            maxLength={45}
            value={getLocation.movingFromDetail}
            onChange={(e) => {
              dispatch(
                orderActions.setTempLocation({
                  ...getLocation,
                  movingFromDetail: e.target.value,
                })
              );
            }}
            data-cy="startDetail"
          />
        </S.Section>
        <S.Section style={{ marginBottom: 20 }}>
          <S.Title>도착지</S.Title>
          <Input
            theme="default"
            border
            icon="search"
            placeholder="주소 검색"
            readOnly
            onClick={toggleAddressTo}
            value={toAddress}
            style={{ paddingRight: 42 }}
            data-cy="endDong"
          />
          <AddressModal
            visible={addressToPopup}
            title="주소검색"
            onClose={toggleAddressTo}
            onConfirm={toggleAddressTo}
            onClick={toggleAddressTo}
            onSelect={onSelectEndAddress}
          />
          <Input
            theme="default"
            border
            icon="down"
            placeholder="층수"
            readOnly
            value={getLocation.movingToFloor.value}
            onClick={() =>
              setVisible({
                ...visible,
                to: !visible.to,
              })
            }
            data-cy="endFloor"
          />
          <Select
            visible={visible.to}
            items={floor}
            onClose={() =>
              setVisible({
                ...visible,
                to: !visible.to,
              })
            }
            onSelect={(key) => {
              const value = key + "층";
              dispatch(
                orderActions.setTempLocation({
                  ...getLocation,
                  movingToFloor: {
                    key,
                    value,
                  },
                })
              );
            }}
            headerTitle="층수 선택"
          />
          <Input
            className="detailAddress"
            theme="default"
            border
            placeholder="상세주소"
            value={getLocation.movingToDetail}
            maxLength={45}
            onChange={(e) => {
              dispatch(
                orderActions.setTempLocation({
                  ...getLocation,
                  movingToDetail: e.target.value,
                })
              );
            }}
            data-cy="endDetail"
          />
        </S.Section>
        <S.Section>
          <Checkbox
            label="보관이사 필요"
            checked={getLocation.needStorage}
            onChange={() => {
              dispatch(
                orderActions.setTempLocation({
                  ...getLocation,
                  needStorage: !getLocation.needStorage,
                })
              );
            }}
          />
          <S.Guide>출발지에서 짐을 빼는 날과 도착지에 짐 들어오는 날이 다를 경우 견적이 달라질 수 있습니다.</S.Guide>
        </S.Section>
        <ButtonContainer>
          {isDesktop && <S.PreviousButton onClick={onPrev}>이전</S.PreviousButton>}
          <Button className="fixButton" theme="primary" onClick={onNext} disabled={isDisabled()} data-cy="next">
            다음
          </Button>
        </ButtonContainer>
      </div>
    </S.Container>
  );
}
