import React from "react";
import styled, { css } from "styled-components";

import colors from "styles/colors";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isHalf: boolean;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
}

const S = {
  Container: styled.label<{
    isHalf: boolean;
    checked: boolean;
    disabled?: boolean;
  }>`
    float: left;
    width: ${(props) => (props.isHalf ? "47%" : "30.3%")};
    height: ${(props) => (props.isHalf ? "56px" : "80px")};
    margin: 0 1.5%;
    margin-bottom: 10px;
    border: 1px solid ${colors.lineDefault};
    border-radius: 8px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    ${(props) =>
      props.checked &&
      css`
        border: 1px solid ${colors.pointBlue};
        box-shadow: 0 4px 10px rgba(22, 114, 247, 0.25);
      `};
    ${(props) =>
      props.disabled &&
      css`
        color: ${colors.lineDefault};
        cursor: not-allowed;
      `};
  `,
  Text: styled.p<{ isHalf: boolean; checked: boolean }>`
    font-size: 16px;
    line-height: ${(props) => (props.isHalf ? "56px" : "84px")};
    letter-spacing: -2px;
    ${(props) =>
      props.checked &&
      css`
        font-weight: 700;
        color: ${colors.pointBlue};
      `};
    @media screen and (max-width: 320px) {
      font-size: 14px;
    }
  `,
  HiddenCheckbox: styled.input`
    display: none;
  `,
};

export default function GridCheckbox({
  label,
  isHalf,
  checked = false,
  disabled = false,
  className,
  ...props
}: Props) {
  return (
    <S.Container {...{ isHalf, checked, disabled, className }}>
      <S.Text {...{ isHalf, checked }}>{label}</S.Text>
      <S.HiddenCheckbox type="checkbox" disabled={disabled} {...props} />
    </S.Container>
  );
}
