import React, { ReactNode, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "react-use-media";
import styled from "styled-components";

import { Status } from "components/common/step";
import { NavHeader, Step, MainHeader } from "components/common";
import { SpinnerPopup } from "@wematch/wematch-ui";
import LoginPage from "components/da24/Login";
import NewModal from "components/da24/NewModalTemplate";

import MovingTypes from "components/templates/step/movingTypes";
import Movers from "components/templates/step/movers";
import Location from "components/templates/step/location";

import * as orderActions from "services/order/actions";
import * as orderSelector from "services/order/selectors";

import useHashToggle from "hooks/useHashToggle";

import { dataLayer } from "lib/gtm";

const Container = styled.div`
  background: #fafafa;

  min-height: calc(100vh - 56px);
  .h-56 {
    height: 56px;
  }
`;

const DeskTopTitle = styled.strong`
  display: block;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
`;

const StepContainer = styled.div`
  display: flex;
  width: 288px;
`;

const FormContainer = styled.div`
  @media screen and (min-width: 768px) {
    margin: 0 auto;
    padding: 0;
  }
  .page {
    transition: all 0.3s ease-in-out;
  }
`;

export const ButtonContainer = styled.div`
  background-color: #fafafa;
  transition: 0.5s ease;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  position: fixed;
  left: 0;
  bottom: 0;
  @media screen and (min-width: 1200px) {
    overflow: hidden;
    position: relative;
    margin-top: 60px;
    max-width: 720px;
  }
`;

const getStepStatus = (currentStep: number, length: number) => {
  const styles: Status[] = [];
  for (let i = 0; i < length; i++) {
    if (i < currentStep) {
      styles.push("done");
    } else if (i === currentStep) {
      styles.push("doing");
    } else {
      styles.push("todo");
    }
  }
  return styles;
};

const steps = [
  { title: "원룸이사", component: <MovingTypes /> },
  { title: "짐량 선택", component: <Movers /> },
  { title: "이사 조건 입력", component: <Location /> },
  // { title: '번호인증',component: <Login /> }
];

export default function MultiStep() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isDesktop = useMedia({
    minWidth: 1200,
  });

  const LOGIN_STEP = steps?.length | 0;
  const currentStep = useSelector(orderSelector.getCurrentStep);
  const getSubmittedOrder = useSelector(orderSelector.getSubmittedOrder);

  const [stepStyle, setStepStyle] = useState<Status[]>([]);
  const [visibleLoginPage, setVisibleLoginPage] = useState(false);
  const [visibleCancel, setVisibleCancel] = useHashToggle("#verifyCancel");

  useEffect(() => {
    setStepStyle(getStepStatus(currentStep, steps.length));
  }, [currentStep]);

  const onPrev = () => {
    if (currentStep <= 0) {
      history.goBack();
    } else {
      if (currentStep === LOGIN_STEP) {
        setVisibleCancel(true);
      } else {
        dispatch(
          orderActions.setCurrentStep({
            currentStep: currentStep - 1,
          })
        );
      }
    }
  };

  const handleModalClose = () => {
    if (visibleCancel) {
      setVisibleCancel(!visibleCancel);
      setTimeout(() => {
        dispatch(
          orderActions.setCurrentStep({
            currentStep: currentStep - 1,
          })
        );
      }, 100);
    }
  };

  const handleLoginCancel = () => {
    dataLayer({
      event: "login",
      category: "로그인취소_팝업",
      action: "로그인취소",
      label: "취소",
    });
    handleModalClose();
  };

  const handleLoginConfirm = () => {
    dataLayer({
      event: "login",
      category: "로그인취소_팝업",
      action: "계속진행",
      label: "계속진행하기",
    });
    setVisibleCancel(!visibleCancel);
  };

  // step이 바뀔때 마다 가장 상단으로 스크롤 올린다.
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === LOGIN_STEP) {
      setVisibleLoginPage(true);
    } else {
      setVisibleLoginPage(false);
    }
  }, [currentStep]);

  if (getSubmittedOrder.loading === "loading") {
    return (
      <SpinnerPopup
        title="내 조건에 맞는 이사업체 찾는 중"
        subtitle="(최대 1분 소요)"
      />
    );
  }
  return (
    <>
      {isDesktop ? (
        <MainHeader />
      ) : (
        <NavHeader
          title={
            currentStep === LOGIN_STEP ? "번호인증" : steps[currentStep]?.title
          }
          onPreviousButtonClick={onPrev}
        />
      )}
      {visibleLoginPage ? (
        <LoginPage
          onPrev={handleLoginConfirm}
          cancelModal={
            <NewModal
              visible={visibleCancel}
              title={"번호인증 취소"}
              content={
                "번호인증을 취소하시면 견적상담신청 및 내신청내역을 확인할 수 없습니다.\n취소하시겠어요?"
              }
              confirmText={"인증 진행하기"}
              cancelText={"취소"}
              cancelClick={handleLoginCancel}
              confirmClick={handleLoginConfirm}
              tags={{
                cancel: "dsl_logincancel_cancel",
                success: "dsl_logincancel_continue",
              }}
            />
          }
        />
      ) : (
        <StepComponent
          isDesktop={isDesktop}
          stepComponent={steps[currentStep]}
          stepStyle={stepStyle}
        />
      )}
    </>
  );
}

interface Props {
  isDesktop: boolean;
  stepComponent: any;
  stepStyle: Status[];
}

function StepComponent({ isDesktop, stepComponent, stepStyle }: Props) {
  return (
    <Container>
      <header className="bg-white flex flex-col items-center">
        {isDesktop ? (
          <DeskTopTitle className="pt-72">{stepComponent?.title}</DeskTopTitle>
        ) : (
          <div className="h-56" />
        )}
        <StepContainer id="dsl_section_div_step" className="pt-24 pb-40">
          {steps.map((s, i) => (
            <Step key={i} text={i + 1} status={stepStyle[i]} />
          ))}
        </StepContainer>
      </header>
      {/*마지막 회원가입 페이지만 Container 사이즈가 다름 */}
      <FormContainer id="dsl_section_div_form" className="page">
        {stepComponent?.component}
      </FormContainer>
    </Container>
  );
}
