import * as React from "react";

function Styler({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2.25a.748.748 0 01.75-.75h7.5a.75.75 0 010 1.5H7.5v19.5h9V2.25a.75.75 0 011.5 0v21a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75v-21zm3.75 4.578a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75z"
        fill={fill}
      />
      <rect x={6} y={18} width={12} height={1.5} rx={0.75} fill={fill} />
    </svg>
  );
}

export default Styler;
