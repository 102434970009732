import React from "react";
import styled, { css } from "styled-components";

import * as Icon from "components/common/Icon";
import colors from "styles/colors";

export type Status = "todo" | "doing" | "done";

const StyledStep = styled.div<{ status: Status }>`
  position: relative;
  text-align: center;
  width: 100%;
  user-select: none;

  &:last-child .bullet:before,
  &:last-child .bullet:after {
    display: none;
  }

  .bullet {
    position: relative;
    height: 24px;
    width: 24px;
    display: inline-block;
    border-radius: 50%;
    background-color: ${colors.lineDeco};
    transition: 0.2s;
    z-index: 10;

    .text {
      /* position: absolute;
      top: 50%;
      margin-top: -6px; */
      font-weight: bold;
      font-size: 12px;
      color: #c4c9d1;
      line-height: 24px;
      transform: translateX(-50%);

      ${(props) =>
        props.status === "doing" &&
        css`
          color: ${colors.white};
        `}

      ${(props) =>
        props.status === "done" &&
        css`
          display: none;
        `}
    }

    &:last-child:after {
      content: none;
    }

    ${(props) =>
      props.status === "doing" &&
      css`
        color: ${colors.white};
        background: ${colors.pointSky};
      `}

    ${(props) =>
      props.status === "done" &&
      css`
        color: ${colors.white};
        background: ${colors.pointSky};
      `}
  }

  &:before,
  &:after {
    content: "";
    z-index: 0;
    position: absolute;
    top: 11px;
    left: 50%;
    height: 3px;
    width: 100%;
    background: ${colors.lineDeco};
  }

  &:last-child:before,
  &:last-child:after {
    content: none;
  }

  ${(props) =>
    props.status === "done" &&
    css`
      :after {
        background: ${colors.pointSky};
        transform: scaleX(0);
        transform-origin: left;
        animation: animate 0.3s linear forwards;
      }
    `}

  @keyframes animate {
    100% {
      transform: scaleX(1);
    }
  }

  .check {
    display: none;
    font-size: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    ${(props) =>
      props.status === "done" &&
      css`
        color: ${colors.white};
        display: block;
      `}
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: number;
  status: Status;
}

export default function Step({ text, status }: Props) {
  return (
    <StyledStep {...{ status }}>
      <div className="bullet">
        <span className="text">{text}</span>
      </div>
      <Icon.CheckOn className="check" />
    </StyledStep>
  );
}
