export interface User {
  uuid: string;
  tel: string;
  name: string;
  agreed_marketing?: string;
}

export enum InitService {
  MOVE_ONEROOM = '원룸이사',
}
