import { put, all, select, takeEvery, call, takeLeading } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { push } from "connected-react-router";
import * as sentry from "@sentry/react";
import { Severity } from "@sentry/react";

import * as actions from "./actions";
import * as requests from "./requests";
import { Order, SubmittedOrder } from "types/order";
import * as commonSelector from "services/common/selectors";

import { MOVE_URL } from "constants/env";
import { sendGAdsAreaAvgPrice } from "lib/googleAds";
import { sendPixelAreaAvgPrice } from "lib/facebookPixel";
import { Juso } from "types/address";
import { getReferer } from "lib/referer";

type TypeJuso = {
  start: Juso;
  end: Juso;
  distance: number;
};

/* SUBMIT ORDER */
export function* submitOrderSaga(action: ActionType<typeof actions.submitOrderAsync.request>) {
  const juso: TypeJuso = yield select(commonSelector.getJuso);
  const orderForm: Order = {
    ...action.payload.order,
    distance: juso.distance,
    referer: getReferer(),
  };

  const { start_sido, start_gugun } = action.payload.order;
  try {
    const data: SubmittedOrder = yield call(requests.submitOrder, orderForm, action.payload.token);
    yield put(actions.submitOrderAsync.success(data));

    // 구글 애드워즈, 픽셀 트래킹 코드 추가 (21.11.29 Koo)
    sendGAdsAreaAvgPrice(start_sido, start_gugun);
    sendPixelAreaAvgPrice(start_sido, start_gugun);

    if (data?.match_status === "success") {
      yield put(push("/requests/completed"));
    } else if (data?.match_status === "no partner") {
      yield put(push("/requests/nopartner"));
    } else if (data?.match_status === "no service") {
      yield put(push("/requests/noservice"));
    }
  } catch (e) {
    yield put(actions.submitOrderAsync.failure());
    alert("에러가 발생했습니다.");
    sentry.captureMessage("원룸이사 접수 실패", {
      level: Severity.Error,
    });
    sentry.captureException(e);

    yield put(push("/error"));
  }
}

export function* getOrderSaga(action: ActionType<typeof actions.getOrderAsync.request>) {
  try {
    const data: SubmittedOrder = yield call(requests.getOrder, action.payload);
    if (!data) {
      window.location.href = `${MOVE_URL}/myrequest`;
    } else {
      yield put(actions.getOrderAsync.success(data));
    }
  } catch (e) {
    yield put(actions.getOrderAsync.failure());
    if (e === "Order not found") {
      window.location.href = `${MOVE_URL}/myrequest`;
    }
  }
}

export default function* () {
  yield all([takeEvery(actions.submitOrderAsync.request, submitOrderSaga), takeEvery(actions.getOrderAsync.request, getOrderSaga)]);
}
