import React from "react";
import styled from "styled-components";
import colors from "styles/colors";
import * as constants from "constants/env";

const S = {
  Wrapper: styled.div`
    overflow: hidden;
    min-width: 320px;
    @media screen and (min-width: 1200px) {
      background: #fff;
    }
  `,
  HeaderWrapper: styled.div`
    overflow: hidden;
    position: relative;
    box-shadow: inset 0 -1px 0 ${colors.lineDefault};
  `,
  Header: styled.div`
    position: relative;
    @media screen and (min-width: 1200px) {
      width: 992px;
      margin: 0 auto;
    }
  `,
  Logo: styled.h1`
    display: block;
    //height: 55px;
    padding: 10px 24px;
    //margin-top: 0;

    a {
      display: block;
      width: 87px;
      height: 16px;
      padding: 16px 0 10px;
    }

    span {
      display: block;
      width: 82px;
      height: 38px;
      font-size: 1px;
      background: url(https://marketdesigners-asset.s3.ap-northeast-2.amazonaws.com/images/logo/wm_da24_pc.png) 0 0 no-repeat;
      background-size: 100% auto;
      color: transparent;
    }

    @media screen and (min-width: 1200px) {
      height: 72px;
      padding: 0;
      a {
        width: 108px;
        height: 20px;
        padding-top: 16px;
      }
    }
  `,
  Categories: styled.ul`
    display: flex;
    top: 26px;
    left: 168px;
    width: auto;
    height: auto;
    font-size: 18px;
    color: ${colors.gray33};
    border-top: 1px solid ${colors.lineDefault};

    li {
      margin-left: 30px;
      padding: 13px 2px 11px;
      font-size: 16px;
      line-height: 23px;
      color: ${colors.gray33};
      letter-spacing: -0.01em;

      @media screen and (min-width: 1200px) {
        .on {
          color: ${colors.pointBlue};
          padding-bottom: 8px;
          border-bottom: 0;
          font-weight: bold;
        }
      }
    }

    @media screen and (min-width: 1200px) {
      left: 152px;
      position: absolute;
      top: 25px;
      width: auto;
      height: auto;
      border-top: 0 none;

      li {
        margin-left: 32px;
        padding: 0;
        font-size: 18px;
        line-height: 24px;
        &:first-child {
          margin: 0;
        }
      }
    }
  `,
  Lnb: styled.div`
    position: absolute;
    top: 9px;
    right: 25px;
    color: ${colors.gray66};

    @media screen and (min-width: 768px) {
      top: 20px;
      right: 32px;
    }
    @media screen and (min-width: 1200px) {
      right: 0;
    }
  `,
  Partner: styled.div`
    display: inline-block;
    padding: 8px 10px;
    border: 1px solid #c3c7d0;
    border-radius: 28px;
    font-size: 14px;
    letter-spacing: -1px;
    background: rgba(255, 255, 255, 0.5);

    @media screen and (min-width: 1200px) {
      margin-left: 32px;
      a {
        font-weight: normal;
        font-size: 15px;
        color: ${colors.gray66};
        padding: 8px 10px;
      }
    }
  `,
  QuickGnb: styled.div`
    display: none;
    a {
      display: inline-block;
      margin-left: 6px;
    }

    .divide {
      &:before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 14px;
        margin-top: 1px;
        margin-right: 10px;
        background-color: #bcc0c6;
        vertical-align: top;
      }
    }

    @media screen and (min-width: 768px) {
      display: block;
      float: left;
      a {
        padding-top: 9px;
        font-size: 15px;
      }
    }
  `,
};

export default function MainHeader() {
  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.Header>
          <S.Logo>
            <a href={constants.MOVE_URL}>
              <span>위매치다이사</span>
            </a>
          </S.Logo>
          <S.Categories>
            <li>
              <a href={constants.MOVE_URL} className="on">
                이사
              </a>
            </li>
            <li>
              <a href={constants.CLEAN_URL}>입주청소</a>
            </li>
            <li>
              <a href={"https://tv.wematch.com/"}>인터넷</a>
            </li>
            <li>
              <a href={`${constants.MOVE_URL}/main/ac-apply`}>에어컨설치</a>
            </li>
          </S.Categories>
          <S.Lnb>
            <S.QuickGnb />
            <S.Partner>
              <a href={`${constants.MOVE_URL}/myrequest`}>내 신청내역</a>
            </S.Partner>
          </S.Lnb>
        </S.Header>
      </S.HeaderWrapper>
    </S.Wrapper>
  );
}
