import React from "react";
import styled, { css } from "styled-components";

import colors from "styles/colors";

interface Props
  extends Omit<React.HTMLAttributes<HTMLButtonElement>, "border"> {
  theme: "default" | "primary";
  disabled?: boolean;
  border?: boolean;
}

const StyledButton = styled.button<Props>`
  display: block;
  width: 100%;
  height: 56px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  ${({ theme }) =>
    theme === "default" &&
    css`
      background-color: ${colors.white};
      color: ${colors.black};
    `}

  ${({ theme }) =>
    theme === "primary" &&
    css`
      background-color: ${colors.pointBlue};
      color: ${colors.white};
    `}
    
    ${({ border }) =>
    border &&
    css`
      border-radius: 6px;
    `}
    
    &:disabled {
    background-color: ${colors.lineDefault};
    cursor: not-allowed;
  }
`;

export default function Button({
  theme = "default",
  disabled = false,
  border = false,
  children,
  ...restProps
}: Props) {
  return (
    <StyledButton {...{ theme, disabled, border }} {...restProps}>
      {children}
    </StyledButton>
  );
}
