import React from "react";

const TermsText = () => {
  return (
    <section className="cont_agree">
      <h2 className="title_head">위매치다이사 서비스 이용약관</h2>
      <div className="wrap_agree">
        <h3 className="title_agree">제 1 장 총 칙</h3>
        <div className="desc_agree fst">
          <strong className="title_g">제 1 조 (목적)</strong>
          <p>
            이 약관은 (주)다이사(이하 `회사`)가 운영하는 위매치다이사 사이트(https://da24.wematch.com/, 이하 `위매치다이사`)를 통해서 제공하는 서비스(이하 `서비스`)를 이용함에 있어 사업자와 이용자간의 이사 · 청소 종합서비스에 대한 계약사항 및 회사와 이용자간의 권리 및 의무 및 책임사항을
            규정함을 목적으로 합니다. 위매치다이사는 이용자간 유용한 의사결정을 할 수 있도록 도와드리는 이사 · 청소 관련 종합서비스 소개사이트로써 계약 관련한 책임은 당사자간에 있습니다.
          </p>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 2 조 (용어의 정의)</strong>
          <ul>
            <li>① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</li>
            <ul>
              <li>1. 서비스 : `서비스`라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 `이용자`가 이용할 수 있는 위매치다이사 및 위매치다이사 관련 제반 서비스를 의미합니다.</li>
              <li>2. 사업자 : 이사화물(이삿짐)의 운송 및 이에 부대 하는 포장, 보관, 정리 등의 이사 관련 서비스 또는 청소서비스를 취급하는 사업자(이하 `사업자`)를 말합니다.</li>
              <li>3. 이용자 : 서비스에 접속하여 이 약관에 따라 회사가 제공하는 서비스를 이용하는 회원 및 비회원(이하 `이용자`)을 말합니다.</li>
              <li>4. 회원 : ‘회원’이라 함은 서비스에 개인정보를 제공하여 회원등록을 한 자로서, 서비스의 정보를 지속적으로 제공받으며, 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.</li>
              <li>5. 게시물 : 회원 및 이용고객이 회사가 제공하는 서비스에 게시 또는 등록하는 부호(URL 포함), 문자, 음성, 음향, 영상(동영상 포함), 이미지(사진 포함), 파일 등을 말합니다.</li>
            </ul>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 3 조 (약관등의 명시와 설명 및 개정)</strong>
          <ul>
            <li>
              ① 회사는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 사이트의 초기 서비스화면(전면)에
              게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
            </li>
            <li>② 회사는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임, 환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.</li>
            <li>③ 회사는 전자상거래등에서의소비자보호에관한법률, 약관의규제에관한법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진등에관한법률, 방문판매등에관한법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
            <li>
              ④ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 사이트의 초기화면에 그 적용일자 7일이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 회사는
              개정전 내용과 개정후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
            </li>
            <li>
              ⑤ 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간내에 회사에 송신하여 회사의 동의를 받은 경우에는 개정약관 조항이 적용됩니다. 또한 공지된 적용일자 이후에 이용자가 회사의 서비스를 계속 이용하는 경우에는 개정된 약관에
              동의하는 것으로 봅니다. 개정된 약관에 동의하지 아니하는 이용자는 언제든지 자유롭게 서비스 이용계약을 해지할 수 있습니다.
            </li>
            <li>⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의소비자보호에관한법률, 약관의규제등에관한법률, 공정거래위원회가 정하는 전자상거래등에서의소비자보호지침 및 관계법령 또는 상관례에 따릅니다.</li>
          </ul>
        </div>
      </div>

      <div className="wrap_agree">
        <h3 className="title_agree">제 2 장 이용 계약의 체결</h3>
        <div className="desc_agree fst">
          <strong className="title_g">제 4 조 (서비스의 제공 및 변경)</strong>
          <ul>
            <li>
              ① 회사는 다음과 같은 업무를 수행합니다.
              <ul>
                <li>1) 서비스 등에 대한 마켓 플레이스 서비스</li>
                <li>2) 정보 제공 서비스</li>
                <li>3) 광고 서비스</li>
                <li>4) 기타 회사가 정하는 서비스</li>
              </ul>
            </li>
            <li>② 회사는 서비스 제공과 관련한 회사 정책의 변경 등 기타 상당한 이유가 있는 경우 등 운영상, 기술상의 필요에 따라 제공하고 있는 서비스의 전부 또는 일부를 변경 또는 중단할 수 있습니다.</li>
            <li>③ 서비스의 내용, 이용방법, 이용시간에 대하여 변경 또는 서비스 중단이 있는 경우에는 변경 또는 중단될 서비스의 내용 및 사유와 일자 등은 그 변경 또는 중단 전에 회사 "사이트" 또는 서비스 내 "공지사항" 화면 등 “회원”이 충분히 인지할 수 있는 방법으로 사전에 공지합니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 5 조 (서비스의 중단)</strong>
          <ul>
            <li>① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 정기 점검 등의 필요로 회사가 정한 날이나 시간은 제외됩니다. 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.</li>
            <li>② 회사는 서비스의 원활한 수행을 위하여 필요한 기간을 정하여 사전에 공지하고 서비스를 중지할 수 있습니다. 단, 불가피하게 긴급한 조치를 필요로 하는 경우 사후에 통지할 수 있습니다.</li>
            <li>③ 회사는 컴퓨터 등 정보통신설비의 보수점검•교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 6 조 (이용자 및 사업자의 회원이입)</strong>
          <ul>
            <li>① 이용자 또는 사업자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원이입을 신청합니다.</li>
            <li>
              ② 회사는 회원으로 가입할 것을 신청한 이용자 또는 사업자 중 다음 각호에 해당하는 신청에 대하여는 승인을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
              <ul>
                <li>1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 회원자격 상실 후 3년이 경과한 자로서 회사의 회원재가입 승낙을 얻은 경우에는 예외로 함</li>
                <li>2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
                <li>3. 부정한 용도 또는 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우</li>
                <li>4. 관계법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우</li>
                <li>5. 14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우</li>
                <li>6. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하여 신청하는 경우</li>
                <li>7. 기타 회원으로 등록하는 것이 서비스의 기술상 현저히 지장이 있다고 판단되는 경우</li>
              </ul>
            </li>
            <li>③ 회원이입계약의 성립시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.</li>
            <li>④ 사업자의 경우, 회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</li>
            <li>⑤ 회원은 등록사항에 변경이 있는 경우, 변경일로부터 60일 이내에 전자우편 기타 방법으로 회사에 그 변경사항을 알려야 합니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 7 조 (회원 탈퇴 및 자격 상실 등)</strong>
          <ul>
            <li>① 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 특별한 사정이 없는 한 이를 즉시 처리합니다.</li>
            <li>
              ② 회원이 다음 각호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.
              <ul>
                <li>1. 가입 신청시에 허위 내용을 등록한 경우</li>
                <li>2. 서비스를 이용하여 구입한 재화등의 대금, 기타 회사가용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</li>
                <li>3. 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
                <li>4. 서비스를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                <li>5. 사업자의 경우, 고객평가정보가 허위작성 또는 조작된 경우</li>
                <li>6. 사업자의 경우, 환급과 관련된 견적 및 계약 등의 정보제공을 의도적으로 누락하거나 왜곡한 경우</li>
                <li>7. 기타 1~6의 사례가 과거에 있었던 경우</li>
              </ul>
            </li>
            <li>③ 회사가 회원 자격을 제한, 정지 시킨후, 동일한 행위가 2회이상 반복되거나 30일이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다.</li>
            <li>④ 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 8 조 (회원의 ID 및 비밀번호에 대한 의무)</strong>
          <ul>
            <li>① ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.</li>
            <li>② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
            <li>③ 회원은 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 회사에 통보하고 회사의 조치가 있는 경우에는 그에 따라야 합니다.</li>
            <li>④ 회원은 제3항에 따른 통지를 하지 않거나 회사의 조치에 응하지 아니하여 발생하는 모든 불이익에 대한 책임은 회원에게 있습니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 9 조 (회원의 의무)</strong>
          <ul>
            <li>① 회원는 관계법령 및 이 약관의 규정, 회사의 정책, 이용안내 등 회사가 통지 또는 공지하는 사항을 준수하여야 하며, 기타 회사 업무에 방해되는 행위를 하여서는 안됩니다.</li>
            <li>
              ② 회원는 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
              <ul>
                <li>1. 서비스 신청 또는 변경 시 허위내용의 등록</li>
                <li>2. 회사에 게시된 정보의 허가 받지 않은 변경</li>
                <li>3. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시</li>
                <li>4. 회사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                <li>5. 외설, 폭력적인 메시지, 화상, 음성 기타 공공질서 미풍양속에 반하는 정보를 공개 또는 게시하는 행위</li>
                <li>6. 고객/상담원/사업자와의 상담 내용이 욕설, 폭언, 성희롱 등에 해당하는 행위</li>
                <li>7. 사업자의 경우, 포인트를 부정하게 적립하거나 사용하는 등의 행위</li>
                <li>8. 허위 리뷰작성 등을 통해 서비스를 부정한 목적으로 이용하는 행위</li>
                <li>9. 자신의 ID, PW를 제3자에게 양도하거나 대여하는 등의 행위</li>
                <li>10. 정당한 사유 없이 당사의 영업을 방해하는 내용을 기재하는 행위</li>
                <li>11. 회원이 회사를 사칭하는 경우</li>
                <li>12. 기타 관계법령에 위반된다고 판단되는 행위</li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 10 조 (이용제한 등)</strong>
          <ul>
            <li>① 회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등의 서비스 이용제한 조치를 취할 수 있습니다.</li>
            <li>② 사업자의 경우, 회사는 사업자 회원이 회사가 요청하는 고객 피해구제요청에 적극적으로 대응하지 않을 시, 2회 경고 후 영구이용정지를 할 수 있습니다.</li>
            <li>③ 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 11 조 (회사의 의무)</strong>
          <ul>
            <li>① 회사는 지속적이고 안정적으로 서비스를 제공하기 위하여 관리자의 의무를 다하도록 노력합니다.</li>
            <li>② 회사는 회원이 안정적인 사업을 이어갈 수 있도록 “사이트” 및 서비스 개선에 노력합니다.</li>
            <li>③ 회사는 관계 법령이 정한 의무사항을 준수합니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 12 조 (포인트)</strong>
          <ul>
            <li>① 포인트는 사업자가 회원가입 후 서비스를 제공받을 수 있는 결제수단을 의미합니다.</li>
            <li>② 포인트는 사업자의 구매활동, 이벤트 참여 등에 따라 회사가 적립, 부여하는 보너스포인트와 회원이 유료로 구매하는 충전포인트로 구분됩니다.</li>
            <li>③ 포인트의 유효기간은 발급 시점에 회원에 고지되며, 따로 고지되지 않은 경우에 한해서 발급일로부터 5년을 유효기간으로 합니다. 유효기간까지 사용되지 않은 포인트는 소멸됩니다.</li>
            <li>④ 회사가 무상으로 적립 또는 부여하는 보너스포인트는 현금환급 신청이 불가합니다.</li>
            <li>⑤ 회사는 회원이 충전포인트에 대한 환급을 요구할 경우, 환급수수료를 공제하고 환급할 수 있으며, 환급조건 및 환급수수료에 대한 구체적인 내용은 회사에서 요청시 안내합니다.</li>
            <li>⑥ 회원 탈퇴 시 미 사용한 보너스포인트는 소멸되며, 회사는 별도의 보상을 하지 않습니다.</li>
            <li>⑦ 포인트의 차감은 충전포인트를 우선합니다. 단, 회사에서 별도로 정한 기준을 안내한 경우에는 그에 따르기로 합니다.</li>
          </ul>
        </div>
        {/*13조 스킵*/}
        <div className="desc_agree">
          <strong className="title_g">제 13 조 (개인정보보호)</strong>
          <ul>
            <li>1. “제휴사”는 “고객”의 개인정보를 보호하기 위하여 정보통신망법 및 개인정보 보호법 등 관계 법령에서 정하는 바를 준수합니다.</li>
            <li>2. “회사”는 관련법령 및 개인정보취급방침에 따라 “제휴사” 및 “고객”의 개인정보를 최대한 보호하기 위하여 노력합니다.</li>
            <li>
              3. “회사”의 공식 사이트 이외의 링크된 사이트에서는 “회사”의 개인정보취급방침이 적용되지 않습니다. 서비스를 제공하는 제3자의 개인정보 취급과 관련하여는 해당사이트 및 해당 제3자의 개인정보취급방침을 확인할 책임이 이용자에게 있으며, “회사”는 이에 대하여 책임을 부담하지 않습니다.
            </li>
            <li>
              4. “회사”는 “제휴사”가 이 약관에 동의 시 다음과 같은 개인정보 수집 및 이용동의, 개인정보 제3자 제공동의에 대해 동의한 것으로 간주합니다.
              <ul>
                <li>1) 수납, 서비스 영업 및 관리를 목적으로 “제휴사＂의 개인정보(성명, 성별, 생년월일, 연락처, 결제사명, 결제자명, 카드번호, 유효기간, 휴대폰번호)를 수집 및 이용</li>
                <li>2) 수납서비스 제공 및 동의 사실 통지, 고객센터 운영을 목적으로 “제휴사＂가 제공한 개인정보를 유니윌㈜, 효성에프엠에스㈜, 카드사(BC, 국민, 외환, 삼성, 신한, 롯데, 하나 SK, 현대 등), 결제 대행사(KG 이니시스, KCP), 효성ITX, MD서비스 등에 제공</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      {/*3장*/}
      <div className="wrap_agree">
        <h3 className="title_agree">제 3 장 계약 당사자의 의무</h3>
        <div className="desc_agree fst">
          <strong className="title_g">제 13 조 (제휴서비스 계약)</strong>
          <ul>
            <li>① 제휴서비스에 대한 계약은 고객이 사업자가 제시한 상품의 판매 조건에 응하여 청약의 의사표시를 하고 이에 대하여 고객이 계약금의 일부를 사업자에게 지급 함으로써 고객과 사업자간에 체결됩니다.</li>
            <li>② 회사는 온라인 마켓 플레이스만을 제공하며 제휴서비스의 분쟁과 책임 계약사항은 사업자와 고객간에 있습니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 14 조 (사업자의 포인트 취소・환불)</strong>
          <p>회사의 서비스와 관련하여 건별 “포인트” 차감에 대해 각 사업자가 문제를 제기하는 경우, 별도의 포인트 환불 정책에 따릅니다.</p>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 15 조 (책임제한)</strong>
          <ul>
            <li>① 회사는 사업자와 고객 간의 서비스거래를 중개하는 플랫폼 서비스만을 제공할 뿐, “제휴서비스”를 판매하는 당사자가 아니며, “제휴서비스”에 대한 품질 및 하자 등에 대한 책임은 사업자에게 있습니다.</li>
            <li>② 회사는 사업자가 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임을 지지 않습니다.</li>
            <li>③ 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</li>
            <li>④ 회사는 사업자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.</li>
            <li>⑤ 회사는 고객 및 사업자가 게재한 이용후기, 평가, 사진 등 정보/자료/사실의 신뢰도, 정확성에 대해서는 책임을 지지 않습니다.</li>
            <li>⑥ 회사 및 회사의 임직원 그리고 대리인은 고의 또는 중대한 과실이 없는 한 다음과 같은 사항으로부터 발생하는 손해에 대해 책임을 지지 아니합니다.</li>
            <ul>
              <li>1. 사업자의 허위 또는 부정확성에 기인하는 손해</li>
              <li>2. 서비스에 대한 접속 및 서비스의 이용과정에서 사업자의 귀책사유로 발생하는 손해</li>
              <li>3. 서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터 발생하는 손해 및 제3자의 불법적인 행위를 방지하거나 예방하는 과정에서 발생하는 손해</li>
              <li>4. 제3자가 서비스를 이용하여 불법적으로 전송, 유포하거나 또는 전송, 유포되도록 한 모든 바이러스, 스파이웨어 및 기타 악성 프로그램으로 인한 손해</li>
            </ul>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 16 조 (회원의 ID 및 비밀번호에 대한 의무)</strong>
          <ul>
            <li>① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.</li>
            <li>② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
            <li>③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “몰”에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 17 조 (이용자의 의무)</strong>
          <ul>
            <li>이용자는 다음 행위를 하여서는 안됩니다.</li>
            <ul>
              <li>① 신청 또는 변경시 허위 내용의 등록</li>
              <li>② 타인의 정보 도용</li>
              <li>③ 사이트에 게시된 정보의 변경</li>
              <li>④ 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
              <li>⑤ 회사 · 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
              <li>⑥ 회사 · 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
              <li>⑦ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 사이트에 공개 또는 게시하는 행위</li>
            </ul>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 18 조 (연결 `서비스`와 ‘피연결서비스` 간의 관계)</strong>
          <ul>
            <li>① 상위 서비스와 하위 서비스가 하이퍼 링크(예: 하이퍼 링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 서비스(웹 사이트)이라고 하고 후자를 피연결서비스(웹사이트)이라고 합니다.</li>
            <li>② 연결서비스는 피연결서비스가 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않는다는 뜻을 연결서비스의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증책임을 지지 않습니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 19 조 (분쟁해결)</strong>
          <ul>
            <li>① 회사는 사업자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 보상처리팀을 설치・운영합니다.</li>
            <li>② 회사는 사업자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.</li>
            <li>③ 회사와 사업자 간에 발생한 분쟁과 관련하여 사업자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 20 조 (준거법 및 관할법원)</strong>
          <ul>
            <li>① 이 약관의 해석 및 회사와 회원간의 분쟁에 대하여는 대한민국의 법을 적용합니다.</li>
            <li>② 서비스 이용 중 발생한 회원사와 회사간의 소송은 민사소송법에 의한 관할법원에 제소합니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 21 조 (비용 정책)</strong>
          <p>"당사"와 "회원사" 간의 발생되는 비용은 "당사"의 영업 방침에 의해 변경될 수 있음을 안내 해드립니다.</p>
        </div>
      </div>

      {/*4장*/}
      <div className="wrap_agree">
        <h3 className="title_agree">제 4 장 게시물 운영정책</h3>
        <div className="desc_agree fst">
          <strong className="title_g">제 22 조 (운영 정책)</strong>
          <p>서비스 내 각종 게시물의 운영정책은 방송통신심의위원회의 정보통신에 관한 심의규정에 기반하며 이를 위반할 경우, "위매치다이사" 운영정책에 의해 관련 게시물은 예고 없이 삭제, 이동 될 수 있으며, 게시자는 글쓰기 기능제한 이용제한 등 사이트 이용에 제한을 받을 수 있습니다.</p>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 23 조 (게시물 규정)</strong>
          <ul>
            <li>① 게시물이라 함은 이용자가 서비스를 이용하며 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.</li>
            <li>② 이용자는 사업자의 서비스를 이용 후, 관련 평점 및 평가를 사이트에 자유롭게 등록할 수 있으며, 작성 게시물에 대한 모든 권리 및 책임은 이를 게시한 이용자에게 있습니다.</li>
            <li>③ 2항에도 불구하고 회사는 회원이 게시하거나 등록하는 서비스의 내용물이 다음 각 호에 해당한다고 판단되는 경우에 사전통지 없이 삭제하거나 문제가 되는 부분을 삭제하거나 기호 등으로 수정할 수 있습니다. 단, 이에 대하여 회사는 어떠한 책임도 지지 않습니다.</li>
            <ul>
              <li>1. 욕설/비속어가 담겨있거나 연상시키는 게시물</li>
              <li>2. 상업성 광고 및 홍보 글에 관한 게시물</li>
              <li>3. 본인 또는 타인의 개인정보(신상정보, 위치, 연락처, 이메일 등)가 포함된 게시물</li>
              <li>4. 정치적 견해 차이 및 인종/ 성별/ 지역/ 종교에 대한 차별, 비하가 담긴 게시물</li>
              <li>5. 다른 회원 또는 제3자를 비방하거나 명예 손상, 음해 목적의 게시물 - 공개되었을 경우, 당사자의 권리침해가 우려되는 내용</li>
              <li>6. 공공질서 및 미풍양속에 위반되는 게시물</li>
              <li>7. 회사, 회사의 직원이나 관계자 및 운영자를 사칭하는 게시물</li>
              <li>8. 기타 본 약관 및 관련 법령에 위반된다고 판단되는 게시물</li>
            </ul>
            <li>④ 회사는 제3항의 게시물 등록자에 대해 약관에서 공지하는 바에 따라 제재를 적용할 수 있습니다.</li>
          </ul>
        </div>
        <div className="desc_agree">
          <strong className="title_g">제 24 조 (저작권의 귀속 및 이용제한)</strong>
          <ul>
            <li>① 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.</li>
            <li>② 이용자 및 사업자는 서비스를 이용함으로써 얻은 정보 중 회사에 지적재산권이 귀속된 정보를 회사의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</li>
            <li>③ 회사는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.</li>
            <li>
              ④ 이용자가 작성한 저작물에 대한 모든 권리 및 책임은 이를 작성한 이용자에게 있으며, 회사는 이용자가 서비스에 게시하는 게시물은 서비스를 운영, 홍보 및 개선하고 새로운 서비스를 개발하기 위해 필요한 범위 내에서 일부 수정·복제·편집되어 사용될 수 있습니다. 단, 이렇게 창작된
              2차적저작물 또는 편집저작물은 ㈜다이사 이외의 제 3자의 무단사용으로부터 보호됩니다. 또한 이용자가 게시하거나 등록하는 저작물의 내용이 다음 각 항에 해당한다고 판단되는 경우에 관련법에 의거하여 해당 저작물을 임시조치를 취하거나 사전통지 없이 삭제할 수 있습니다.
            </li>
            <ul>
              <li>1. 다른 이용자 또는 제 3자를 비방하거나 명예를 손상시키는 내용인 경우</li>
              <li>2. 회사의 저작권 및 제 3자의 저작권 등 타인의 권리를 침해하는 내용인 경우</li>
              <li>3. 타인의 명의를 도용한 경우</li>
              <li>4. 개인간의 금전거래를 요구하는 경우</li>
              <li>5. 회사가 서비스의 성격 및 게시물의 위치에 부합되지 않는다고 판단하는 경우</li>
              <li>6. 기타 관계법령에 위배된다고 판단되는 경우</li>
            </ul>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default TermsText;
