import * as React from "react";

function Down({
  width = 16,
  height = 16,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="fill"
      {...props}
    >
      <path
        d="M13.353 5.854a.498.498 0 000-.708.504.504 0 00-.71 0L7.974 9.793 3.357 5.197a.504.504 0 00-.71 0 .498.498 0 000 .707l4.973 4.95a.504.504 0 00.734-.026.525.525 0 00.026-.025l4.973-4.95z"
        fill="#121212"
      />
    </svg>
  );
}

export default Down;
