import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import BasePopup from "components/base/basePopup";
import { Button, Quantity } from "components/common/index";

import * as orderSelector from "services/order/selectors";
import * as orderActions from "services/order/actions";

import colors from "styles/colors";

import {
  CategoryName,
  Furniture,
  HomeAppliance,
  Initialize,
  SmallAppliance,
} from "types/order";

interface Props {
  visible: boolean;
  onClose: () => void;
  title: string;
  categoryName: CategoryName;
  category: Furniture | HomeAppliance | SmallAppliance | Initialize;
}

const S = {
  Container: styled.div`
    margin: 31px 24px 20px;
    .mb {
      margin-bottom: 40px;
    }
  `,
  Title: styled.h3`
    color: ${colors.gray33};
    margin-bottom: 8px;
  `,
};

export default function SmallCategoryPopup({
  visible,
  onClose,
  title,
  categoryName,
  category,
}: Props) {
  const dispatch = useDispatch();

  const orderForm = useSelector(orderSelector.getOrderForm);
  const getTempAnswersCount = useSelector(orderSelector.getTempAnswersCount);

  const [count, setCount] = useState(1);
  const [quantity, setQuantity] = useState<{ count: number; action: string }>({
    count: 1,
    action: "",
  });

  const handleSubmit = () => {
    // 의류관리기인 경우는 예외처리
    if (category === HomeAppliance.CLOT) {
      const home = orderForm.home_appliance?.filter(
        (order) => order.category !== category
      );

      if (home) {
        dispatch(
          orderActions.setOrderForm({
            order: {
              ...orderForm,
              home_appliance: [
                ...home,
                {
                  count,
                  category: category as HomeAppliance,
                  detail: [],
                },
              ],
            },
          })
        );
      }
    } else {
      const smallAppliance = orderForm.small_appliance?.filter(
        (order) => order.category !== category
      );

      if (smallAppliance) {
        dispatch(
          orderActions.setOrderForm({
            order: {
              ...orderForm,
              small_appliance: [
                ...smallAppliance,
                {
                  count: quantity.count,
                  category: category as SmallAppliance,
                },
              ],
            },
          })
        );
      }
    }

    onClose();
  };

  const handleOnClose = () => {
    // 선택된 값 초기화
    if (onClose) {
      // 선택된 모든값 제거
      dispatch(
        orderActions.removeCategory({
          categoryName,
          category,
        })
      );
      setCount(1);
      onClose();
    }
  };

  useEffect(() => {
    const answersCount = getTempAnswersCount.find(
      (t) => t.category === category
    );
    if (answersCount) {
      setCount(answersCount?.count);
    } else {
      setCount(1);
    }
  }, [category, visible, quantity, count]);

  useEffect(() => {
    if (quantity.action === "increment" && category) {
      dispatch(
        orderActions.setTempAnswerCount({
          category,
          count: quantity.count,
        })
      );
    } else if (quantity.action === "decrement" && category) {
      dispatch(
        orderActions.setTempAnswerCount({
          category,
          count: quantity.count,
        })
      );
    }
  }, [quantity.count]);

  return (
    <BasePopup
      style={{ height: "275px" }}
      {...{ visible }}
      onClose={handleOnClose}
      headerTitle={title}
      closeText="초기화"
    >
      <S.Container>
        <S.Title>수량</S.Title>
        <Quantity
          min={1}
          max={5}
          defaultValue={count}
          style={{ marginBottom: 40 }}
          onChange={(count, action) => setQuantity({ count, action })}
        />

        <Button
          theme="primary"
          disabled={false}
          border
          onClick={handleSubmit}
          data-cy="save_button"
        >
          저장
        </Button>
      </S.Container>
    </BasePopup>
  );
}
