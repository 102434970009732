import { createGlobalStyle } from "styled-components";
import reset from "./reset";
import { resetButton, resetInput } from "./mixins";
import "../assets/css/common.css";

export default createGlobalStyle`
  ${reset}
  a {
    text-decoration: none;
    color: inherit;
  }
  body {
    font-family: 'Noto Sans KR', 'AppleSDGothicNeo', '맑은고딕', 'Malgun Gothic', sans-serif;
    background-color: white;
    letter-spacing: -0.5px;
    touch-action: pan-y;
    -webkit-font-smoothing: antialiased;
  }
  button {
    ${resetButton}
  }
  input {
    ${resetInput}
  }
  
`;
