import * as React from "react";

function Bed({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 4.5a.75.75 0 00-.75.75V12H2.25a.75.75 0 00-.75.75v9a.75.75 0 001.5 0v-3.563h18v3.563a.75.75 0 001.5 0v-9a.75.75 0 00-.75-.75H19.5V5.25a.75.75 0 00-1.5 0V12h-1.5V9.75a.75.75 0 00-.75-.75h-7.5a.75.75 0 00-.75.75V12H6V6h9.75a.75.75 0 000-1.5H5.25zm3.75 6V12h6v-1.5H9zm-.75 3H3v3.188h18V13.5H8.25z"
        fill={fill}
      />
    </svg>
  );
}

export default Bed;
