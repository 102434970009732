import TagManager from "react-gtm-module";
import { omitBy, isEmpty } from "lodash";

interface Props {
  event: string;
  category?: string;
  action?: string;
  label?: string;
}

export const dataLayer = ({ event, category, action, label }: Props) => {
  const removeEmpty = omitBy({ event, category, action, label }, isEmpty);

  TagManager.dataLayer({
    dataLayer: {
      ...removeEmpty,
    },
  });
};
