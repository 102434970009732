import styled, { css } from "styled-components";
import colors from "styles/colors";

export const S1 = {
  Container: styled.div`
    width: 380px;
    margin: 0 auto;
    padding: 0px;
    .bg-gray {
      background-color: #fafafa;
    }
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
      letter-spacing: -1px;
      color: ${colors.gray33};
      padding: 8px 24px;
    }
    .move-types {
      padding: 24px;
    }
    .fixButton {
      width: 100%;
      margin: 0 auto;
      @media screen and (min-width: 1200px) {
        width: 70%;
        border-radius: 0;
      }
    }
    .back-btn-container {
      padding-top: 24px;
      max-width: 380px;
    }
    .btn-back {
      padding: 0px 24px 16px;
    }
  `,
  Arrow: styled.div`
    position: absolute;
    bottom: -12px;
    width: 0;
    height: 0;
    border-top: 12px solid #1672f7; /* 화살표 */
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
  `,
};

export const SMovingTypes = {
  Container: styled.div`
    overflow: hidden;
    margin-bottom: 16px;
    background-color: white;
    padding: 16px;
    border: 1px solid ${colors.lineDefault};
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;

    .type-title {
      padding-bottom: 4px;
      color: #333333;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    }
    .type-subtitle {
      padding-bottom: 24px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -1px;
    }

    .service-bg {
      border: solid 1px #d7dbe2;
      border-radius: 6px;
    }
    .service-bar {
      background-color: ${colors.pointBlue};
      height: 10px;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .point {
        width: 6px;
        height: 6px;
        background-color: white;
        border-radius: 3px;
        margin: 0 2px;
      }
    }
    .full {
      width: 100%;
    }
    .range14 {
      width: 76%;
      .point:nth-child(5) {
        display: none;
      }
    }
    .range23 {
      margin-left: 24%;
      width: 52%;
      .point:nth-child(4),
      .point:nth-child(5) {
        display: none;
      }
    }
  `,
  TypeTitle: styled.strong<{ checked: boolean }>`
    display: inline-block;
    padding-top: 3px;
    font-size: 18px;
    font-weight: 700;
    color: ${colors.gray33};
    line-height: 27px;

    ${(props) =>
      props.checked &&
      css`
        color: ${colors.pointBlue};
      `};
  `,
  TypeSubtitle: styled.p`
    padding-top: 9px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: ${colors.gray66};
    @media screen and (max-width: 320px) {
      font-size: 14px;
    }
  `,
  TypeIcon: styled.div`
    float: right;
    margin-top: 10px;
    @media screen and (max-width: 320px) {
      margin-top: 16px;
    }
    svg {
      @media screen and (max-width: 320px) {
        width: 48px;
        height: 48px;
      }
    }
  `,
};

export const S2 = {
  Container: styled.div``,
  Header: styled.div`
    background-color: #2ccbcb;
    .back-btn-container {
      margin: 0 auto;
      padding-top: 24px;
      max-width: 380px;
    }
    .btn-back {
      padding: 0px 24px;
    }
  `,
  HeaderMain: styled.div`
    color: #ffffff;
    padding: 8px 24px 32px;
    max-width: 380px;
    box-sizing: border-box;
    margin: 0 auto;

    div:nth-child(1) {
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      letter-spacing: -1px;
    }
    div:nth-child(2) {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -1px;
    }
  `,
  Form: styled.div`
    padding: 0 24px 24px;
    box-sizing: border-box;
    max-width: 380px;
    margin: 0 auto;
  `,
  Label: styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -1px;
    padding: 40px 0 16px;
  `,
};

export const S3 = {
  Container: styled.div`
    text-align: center;
    padding-top: 24px;
  `,
  Title: styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -1px;
    color: #333333;
  `,
  Content: styled.div`
    padding-top: 4px;
    color: #666666;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -1px;
    b {
      font-weight: bold;
    }
  `,
  ButtonContainer: styled.div`
    padding-top: 12px;
    width: 312px;
    margin: 0 auto;
  `,
};
