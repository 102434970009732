import * as React from "react";

function Refrigerator({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.25a.748.748 0 01.75-.75h13.5a.75.75 0 010 1.5H4.5v9h15V2.25a.75.75 0 011.5 0v21a.75.75 0 01-.75.75H3.75a.747.747 0 01-.75-.75v-21zM19.5 22.5v-9h-15v9h15zM6 6.75a.75.75 0 011.5 0v1.5a.75.75 0 01-1.5 0v-1.5zm.75 9.75a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75z"
        fill={fill}
      />
    </svg>
  );
}

export default Refrigerator;
