import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import _ from "lodash";

import BasePopup from "components/base/basePopup";
import {
  Button,
  EqSelect,
  Quantity,
  SingleSelect,
} from "components/common/index";

import {
  CategoryName,
  CheckType,
  Furniture,
  GroupType,
  Height,
  HomeAppliance,
  Initialize,
  Kind,
  Material,
  Option,
  Size,
  SmallAppliance,
  Width,
} from "types/order";

import * as orderSelector from "services/order/selectors";
import * as orderActions from "services/order/actions";

export interface DataType {
  id: number;
  category: Furniture | HomeAppliance | SmallAppliance;
  group: string;
  key: Size | Material | Option | Kind | Width | Height;
  label: string;
}

interface Props {
  visible: boolean;
  onClose: () => void;
  categoryName: CategoryName;
  category: Furniture | HomeAppliance | SmallAppliance | Initialize;
  data: DataType[];
  title: string;
}

const S = {
  Container: styled.div`
    overflow-y: scroll;
    max-height: 360px;
    padding: 20px 24px 0;
    ::-webkit-scrollbar {
      display: none !important;
    }
    -ms-overflow-style: none !important;
    .btnCategory {
      margin-bottom: 20px;
    }
    @media screen and (min-width: 1200px) {
      max-height: 418px;
    }
    @media screen and (max-width: 320px) {
      max-height: 260px;
    }
  `,
  Box: styled.section<{ isLast: boolean }>`
    margin-top: 50px;
    ${(props) =>
      props.isLast &&
      css`
        padding-bottom: 106px;
      `};
  `,
  Title: styled.h3`
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
  `,
  SubTitle: styled.p`
    margin-bottom: 8px;
    font-size: 15px;
  `,
  Group: styled.section`
    overflow: hidden;
  `,
  BottomContainer: styled.div`
    position: relative;
    button {
      width: calc(100% - 48px);
      position: fixed;
      bottom: 0;
    }
  `,
};

export default function CategoryPopup({
  visible,
  onClose,
  data,
  categoryName,
  category,
  title,
}: Props) {
  const dispatch = useDispatch();

  const orderForm = useSelector(orderSelector.getOrderForm);
  const previousTempAnswer = useSelector(orderSelector.getPreviousTempAnswers);
  const getTempAnswersCount = useSelector(orderSelector.getTempAnswersCount);
  const getTempAnswersEq = useSelector(orderSelector.getTempAnswerEq);

  const handleSetTempAnswer = useCallback(
    (TempAnswer) => {
      dispatch(orderActions.setTempAnswer(TempAnswer));
    },
    [dispatch]
  );

  const [count, setCount] = useState(1);
  const [quantity, setQuantity] = useState<{ count: number; action: string }>({
    count: 1,
    action: "",
  });
  const [answers, setAnswers] = useState<DataType[][][]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isEqDisabled, setIsEqDisabled] = useState(false);

  const group = _.chain(data)
    .filter((d) => d.category === category)
    .groupBy("category")
    .values()
    .value();
  const groupKey = _.chain(data)
    .filter((d) => d.category === category)
    .groupBy("category")
    .keys()
    .value();
  const key = _.findIndex(groupKey, (c) => c === category);
  const categories = _.chain(group[key]).groupBy("group").values().value();

  const OptionText = (option?: GroupType) => {
    if (option === "kind") {
      return "종류";
    } else if (option === "size") {
      return "크기";
    } else if (option === "width") {
      return "너비";
    } else if (option === "height") {
      return "높이";
    } else if (option === "material") {
      return "재질";
    } else if (option === "option") {
      return "기타옵션 (분해조립)";
    }
  };

  const handleSubmit = () => {
    const categoryDetails = [];

    const answer = _.chain(previousTempAnswer)
      ?.filter((t) => t.category === category)
      .groupBy("groupValue")
      .value();
    const answersCount = getTempAnswersCount.find(
      (t) => t.category === category
    );

    for (let i = 0; i < count; i++) {
      const temp = {};
      answer[i]
        ?.filter((item) => item.category === category)
        .map((item) => {
          return {
            [item.group]: item.key,
          };
        })
        .map((detail) => {
          Object.assign(temp, detail);
        });
      // 빈값 확인
      if (!_.isEmpty(temp)) {
        categoryDetails.push(temp);
      }
    }

    if (categoryName === "f") {
      const furniture = orderForm.furniture?.filter(
        (order) => order.category !== category
      );

      if (furniture) {
        dispatch(
          orderActions.setOrderForm({
            order: {
              ...orderForm,
              furniture: [
                ...furniture,
                {
                  count: answersCount ? answersCount?.count : 1,
                  category: category as Furniture,
                  detail: categoryDetails,
                },
              ],
            },
          })
        );
      }
    }

    if (categoryName === "h") {
      const home = orderForm.home_appliance?.filter(
        (order) => order.category !== category
      );

      if (home) {
        dispatch(
          orderActions.setOrderForm({
            order: {
              ...orderForm,
              home_appliance: [
                ...home,
                {
                  count: answersCount ? answersCount?.count : 1,
                  category: category as HomeAppliance,
                  detail: categoryDetails,
                },
              ],
            },
          })
        );
      }
    }

    onClose();
  };

  const handleDisabled = () => {
    return !(!isDisabled && !isEqDisabled);
  };

  const handleEqDisabled = () => {
    // 첫번재 옵션을 선택하지 않으면, 두번째 옵션도 선택할수 없도록 막는다.
    const answers = previousTempAnswer.filter(
      (t) => t.category === category && t.groupValue === "0"
    ); // 첫번째 선택값을 가져온다.
    const optionCount = _.chain(data)
      .filter((d) => d.category === category)
      .groupBy("group")
      .values()
      .value();

    return answers.length !== optionCount?.length;
  };

  const handleOnClose = () => {
    // 선택된 값 초기화
    if (onClose) {
      // 선택된 모든값 제거
      dispatch(
        orderActions.removeCategory({
          categoryName,
          category,
        })
      );
      setCount(1);
      onClose();
    }
  };

  useEffect(() => {
    const answersCount = getTempAnswersCount.find(
      (t) => t.category === category
    );
    if (answersCount && answersCount?.category === category) {
      setCount(answersCount?.count);

      let tempCategories = [categories];
      for (let i = 1; i < answersCount?.count; i++) {
        const cloneCategory = _.cloneDeep(categories).map((category) => {
          let last = _.last(category)?.id;
          return category.map((c) => {
            if (last) {
              ++last;
              return { ...c, id: last };
            }
          }) as DataType[];
        });

        tempCategories = [...tempCategories, cloneCategory];
      }
      setAnswers(tempCategories);
    } else {
      setCount(1);
      setAnswers([categories]);
    }
  }, [category]);

  useEffect(() => {
    const answersCount = getTempAnswersCount.find(
      (t) => t.category === category
    );
    if (answersCount && answersCount?.category === category) {
      setCount(answersCount?.count);

      let tempCategories = [categories];
      for (let i = 1; i < answersCount?.count; i++) {
        const cloneCategory = _.cloneDeep(categories).map((category) => {
          let last = _.last(category)?.id;
          return category.map((c) => {
            if (last) {
              ++last;
              return { ...c, id: last };
            }
          }) as DataType[];
        });

        tempCategories = [...tempCategories, cloneCategory];
      }
      setAnswers(tempCategories);
    } else {
      setAnswers([categories]);
    }
  }, [visible]);

  useEffect(() => {
    if (quantity.action === "increment" && category) {
      dispatch(
        orderActions.setTempAnswerCount({
          category,
          count: quantity.count,
        })
      );

      dispatch(
        orderActions.setTempAnswerEq({
          id: answers.length,
          category,
          status: "init" as CheckType,
          disabled: true,
        })
      );

      setCount(quantity.count);

      const cloneCategory = _.cloneDeep(categories).map((category) => {
        let last = _.last(category)?.id;
        return category.map((c) => {
          if (last) {
            ++last;
            return { ...c, id: last };
          }
        }) as DataType[];
      });

      setAnswers(answers.concat([cloneCategory]));
    } else if (quantity.action === "decrement" && category) {
      //마지막 인덱스 삭제
      if (answers.length > 1) {
        dispatch(
          orderActions.setTempAnswerCount({
            category,
            count: quantity.count,
          })
        );
        setAnswers(_.initial(answers));

        dispatch(
          orderActions.removeTempAnswer({
            category,
            groupValue: String(answers.length - 1),
          })
        );
        dispatch(
          orderActions.removeTempAnswerEq({ category, id: answers.length - 1 })
        );
      }
    }
  }, [quantity.count]);

  useEffect(() => {
    // 수량 추가 시 '같은 옵션' 박스 활성화 상태 여부 체크
    const answersEq = getTempAnswersEq.filter((t) => t.category === category);

    if (_.isEmpty(answersEq)) {
      setIsEqDisabled(false);
    } else {
      const isDisabled = _.every(answersEq, { disabled: false });
      isDisabled ? setIsEqDisabled(false) : setIsEqDisabled(true);
    }
  }, [getTempAnswersEq, getTempAnswersCount, visible]);

  useEffect(() => {
    const optionCount = _.chain(data)
      .filter((d) => d.category === category)
      .groupBy("group")
      .values()
      .value();
    const answer = previousTempAnswer.filter((t) => t.category === category);
    const answerCount = getTempAnswersCount.find(
      (t) => t.category === category
    );

    // 처음에 answer count 정보가 스토어에 없는 경우가 있을수 있어서... (초기에는 없음)
    if (answerCount && optionCount) {
      answer.length === optionCount?.length * answerCount?.count
        ? setIsDisabled(false)
        : setIsDisabled(true);
    } else {
      answer.length === optionCount?.length
        ? setIsDisabled(false)
        : setIsDisabled(true);
    }
  }, [previousTempAnswer, getTempAnswersCount, getTempAnswersEq, answers]);

  return (
    <BasePopup
      visible={visible}
      onClose={handleOnClose}
      headerTitle={title}
      closeText="초기화"
    >
      <S.Container>
        <S.SubTitle>수량</S.SubTitle>
        <Quantity
          min={1}
          max={5}
          defaultValue={count}
          onChange={(count, action) => setQuantity({ count, action })}
        />
        {answers?.map((answer, index) => {
          return (
            <S.Box key={index} isLast={index === answers?.length - 1}>
              <S.Title>
                {title} {index + 1}
              </S.Title>
              {index === 0 &&
                answer.map((details, i) => {
                  const detail = details.find((detail) => detail.id);
                  return (
                    <S.Group key={i}>
                      <S.SubTitle>
                        {OptionText(detail?.group as GroupType)}
                      </S.SubTitle>
                      <SingleSelect
                        key={i}
                        gId={detail?.group}
                        gValue={String(index)}
                        details={details}
                        category={category}
                        previousTempAnswer={previousTempAnswer}
                        handleSetTempAnswer={handleSetTempAnswer}
                      />
                    </S.Group>
                  );
                })}
              {index >= 1 && (
                <EqSelect
                  id={index}
                  category={category}
                  disabled={handleEqDisabled()}
                  label={`${title} 1과 동일`}
                >
                  {answer.map((details, i) => {
                    const detail = details.find((detail) => detail.id);
                    return (
                      <S.Group key={i}>
                        <S.SubTitle>
                          {OptionText(detail?.group as GroupType)}
                        </S.SubTitle>
                        <SingleSelect
                          key={i}
                          gId={detail?.group}
                          gValue={String(index)}
                          details={details}
                          category={category}
                          previousTempAnswer={previousTempAnswer}
                          handleSetTempAnswer={handleSetTempAnswer}
                        />
                      </S.Group>
                    );
                  })}
                </EqSelect>
              )}
            </S.Box>
          );
        })}
        <S.BottomContainer>
          <Button
            theme="primary"
            disabled={handleDisabled()}
            border
            onClick={handleSubmit}
            className="btnCategory"
            data-cy="save_button"
          >
            저장
          </Button>
        </S.BottomContainer>
      </S.Container>
    </BasePopup>
  );
}
