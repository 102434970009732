import axios, { AxiosError } from "axios";
import { API_URL_OPS, API_URL_ONEROOM, API_URL_DA24, API_ADDRESS } from "constants/env";

export interface ApiResponse<T> {
  data: T;
  error: string;
}

const opsApi = axios.create({
  baseURL: API_URL_OPS,
});

const da24Api = axios.create({
  baseURL: API_URL_DA24,
});

const oneRoomApi = axios.create({
  baseURL: API_URL_ONEROOM,
});

const addressApi = axios.create({
  baseURL: API_ADDRESS,
});

// opsApi.interceptors.response.use((response) => {
//   return response
// }, (error: AxiosError) => {
//   return error.response
// })

da24Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error?.response?.data);
  }
);

oneRoomApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error.response?.data.error);
  }
);

export {
  opsApi,
  da24Api,
  oneRoomApi,
  addressApi,
  // middlewareApi,
};
