import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMedia } from "react-use-media";
import styled from "styled-components";
import colors from "styles/colors";

const Tag = ({ keywords }: { keywords: string[] }) => {
  const location = useLocation();
  const isDesktop = useMedia({
    minWidth: 1200,
  });
  const count = isDesktop ? 5 : 2;
  const [visibleEtc, setEtc] = useState(true);

  useEffect(() => {
    location.pathname.includes("requests/completed") ? setEtc(false) : setEtc(true);
  }, []);

  const handleClick = () => {
    const elem = document.getElementById("advantages");
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
  };

  const etcComp = keywords.length - count > 0 && (
    <S.Tag key="etc" onClick={handleClick} style={{ cursor: location.pathname.includes("requests/completed") ? "pointer" : "" }}>
      외 {keywords.length - count}개
    </S.Tag>
  );

  return (
    <>
      {keywords.slice(0, count).map((data, index) => (
        <S.Tag key={index}>{data.split("||")[0]}</S.Tag>
      ))}
      {isDesktop && etcComp}
      {!isDesktop && visibleEtc && etcComp}
    </>
  );
};

const S = {
  Tag: styled.div`
    border-radius: 16px;
    background: ${colors.boxBg};
    padding: 6px 12px;
    color: ${colors.gray66};
    font-size: 14px;
    letter-spacing: -1px;
    text-align: center;
    align-content: center;
    user-select: none;
  `,
};

export default Tag;
