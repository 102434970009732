import * as React from "react";

function Closet({
  width = 24,
  height = 24,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5.75A.75.75 0 015.25 0h10.5a.75.75 0 010 1.5h-2.947v18H18V.75a.75.75 0 011.5 0v22.5a.75.75 0 01-1.5 0V21H6v2.25a.75.75 0 01-1.5 0V.75zM6 19.5h5.303v-18H6v18zm3.803-9.75a.75.75 0 10-1.5 0v1.5a.75.75 0 001.5 0v-1.5zm5.25-.75a.75.75 0 01.75.75v1.5a.75.75 0 11-1.5 0v-1.5a.75.75 0 01.75-.75z"
        fill={fill}
      />
    </svg>
  );
}

export default Closet;
