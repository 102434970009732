import React from "react";
import Styled from "styled-components";
import colors from "styles/colors";
import PopupTemplate from "./PopupTemplate";
import PrivacyText from "../text/PrivacyText";

const PrivacyModal: React.FC<Props> = (props) => {
  const { visible = false, onClose } = props;

  return (
    <PopupTemplate visible={visible} onClose={onClose}>
      <S.Container>
        <S.TermsWrap>
          <PrivacyText />
        </S.TermsWrap>
      </S.Container>
    </PopupTemplate>
  );
};

interface Props {
  visible: boolean;
  onClose: () => void;
}

const S = {
  Container: Styled.div`
    position: relative;
    padding: 0 24px;
    height: 100%;
    overflow-y: auto;
    margin-top: 56px;
`,
  TermsWrap: Styled.div`
    padding-top: 10px;
    top: 0;
    left: 24px;
    right: 24px;
    font-size: 13px;
    line-height: 20px;
    color: ${colors.gray33};
    
    @media (min-width: 1200px) {
    width: 312px;
    }
    
    h4 {
      display: block;
      margin-bottom: 3px;
      font-size: 15px;
      font-weight: bold;
      line-height: 26px;
      color: ${colors.gray33};
    }

    .title_head {
      font-size: 24px;
      font-weight: 700;
      line-height: 35px;
      color: ${colors.gray33};
      margin-bottom: 25px;
    }

    .wrap_agree {
      padding: 0px 0px 60px;
      font-size: 14px;
      line-height: 22px;
      color: ${colors.gray66};

      .title_agree {
          padding-bottom: 16px;
          font-weight: 600;
          font-size: 18px;
          line-height: 1.1;
          color: ${colors.gray33};
      }
      .desc_agree {
          padding-top: 30px;
          &.fst {
              padding-top: 0;
          }
      }
      .title_g {
          display: block;
          font-weight: normal;
          color: ${colors.gray33};
      }
      .desc_table {
        display: block;
        overflow: auto;
        table {
          margin-top: 15px;
          width: 880px;
          border: 1px solid ${colors.gray66};
        }

        th, td {
          padding: 10px;
          color: ${colors.gray33};
          border: 1px solid ${colors.gray66};
        }
        th {
          font-weight: 600;
          color: ${colors.gray33};
          background: ${colors.grayBg};
        }
      }
      .btn {
        font-weight: 600;
        cursor: pointer;
        color: ${colors.gray33};
      }
    }

    .desc_agree {
        padding-top: 10px;
    }
`,
};

export default PrivacyModal;
