import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Icon } from "components/common";
import colors from "styles/colors";

interface Props {
  increment?: number;
  min?: number;
  max?: number;
  onChange?: (count: number, action: string) => void;
  defaultValue?: number;
  style?: React.CSSProperties;
}

const StyledQuantity = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  padding: 0 17px;
  border: 1px solid ${colors.lineDefault};
  border-radius: 6px;

  input {
    text-align: center;
  }

  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
`;

export default function Quantity({
  increment,
  min = 1,
  max = 100,
  onChange,
  defaultValue = 1,
  style,
}: Props) {
  const [value, setValue] = useState({
    count: defaultValue,
    action: "",
  });

  const onDecrement = () => {
    if (value.count > min) {
      setValue({
        count: increment ? value.count - increment : value.count - 1,
        action: "decrement",
      });
    }
  };

  const onIncrement = () => {
    if (value.count < max) {
      setValue({
        count: increment ? increment + value.count : value.count + 1,
        action: "increment",
      });
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(value.count, value.action);
    }
  }, [value]);

  useEffect(() => {
    setValue({
      count: defaultValue,
      action: "",
    });
  }, [defaultValue]);

  return (
    <StyledQuantity {...{ style }}>
      <button onClick={onDecrement}>
        <Icon.Minus />
      </button>
      <input
        type="text"
        readOnly
        value={
          increment
            ? `${value.count}` + " ~ " + `${value.count + increment}`
            : `${value.count}`
        }
      />
      <button onClick={onIncrement}>
        <Icon.Plus data-cy="plusBtn" />
      </button>
    </StyledQuantity>
  );
}
