import { createAction, createAsyncAction } from "typesafe-actions";

import * as addressTypes from "types/address";
import * as phoneTypes from "types/phone";

export const fetchAddressListAsync = createAsyncAction("FETCH_ADDRESS_REQUEST", "FETCH_ADDRESS_SUCCESS", "FETCH_ADDRESS_FAILURE")<
  addressTypes.RequestAddressProps,
  addressTypes.ResponseAddressProps,
  addressTypes.AddressErrorType
>();

export const fetchAddressMoreListAsync = createAsyncAction(
  "FETCH_ADDRESS_LIST_MORE_REQUEST",
  "FETCH_ADDRESS_LIST_MORE_SUCCESS",
  "FETCH_ADDRESS_LIST_MORE_FAILURE"
)<addressTypes.RequestAddressProps, addressTypes.ResponseAddressProps, addressTypes.AddressErrorType>();

export const resetAddressList = createAction("RESET_ADDRESS_LIST")();

export const fetchVerifySendMessageAsync = createAsyncAction(
  "FETCH_VERIFY_SEND_MESSAGE_REQUEST",
  "FETCH_VERIFY_SEND_MESSAGE_SUCCESS",
  "FETCH_VERIFY_SEND_MESSAGE_FAILURE"
)<{ phone: string }, { message: string }, undefined>();

export const fetchVerifyCodeAsync = createAsyncAction("FETCH_VERIFY_CODE_REQUEST", "FETCH_VERIFY_CODE_SUCCESS", "FETCH_VERIFY_CODE_FAILURE")<
  phoneTypes.Phone,
  { is_verified: boolean | undefined },
  undefined
>();

export const setJuso = createAction("SET_JUSO")<{
  start: addressTypes.Juso | null;
  end: addressTypes.Juso | null;
  distance: number;
}>();

// export const fetchDistance = createAsyncAction("FETCH_DISTANCE_REQUEST", "FETCH_DISTANCE_SUCCESS", "FETCH_DISTANCE_FAILURE")<
//   addressTypes.RequestDistanceType,
//   addressTypes.ResponseDistanceType,
//   undefined
// >();

export const resetAll = createAction("RESET_ALL")();
export const setDeviceId = createAction("SET_DEVICE_ID")<string>();

export const fetchPrivacyPartnerListAsync = createAsyncAction(
  "FETCH_PRIVACY_PARTNER_LIST_REQUEST",
  "FETCH_PRIVACY_PARTNER_LIST_RESPONSE",
  "FETCH_PRIVACY_PARTNER_LIST_FAILURE"
)<undefined, any, undefined>();
