import React, { useState, useEffect } from "react";
import { useMedia } from "react-use-media";
import styled, { css } from "styled-components";
import colors from "styles/colors";
import { MatchedOrder } from "types/order";
import Tag from "./Tag";
import StarRating from "./StarRating";
import Dot from "./Dot";
import SBadge from "./SBadge";
import { MOVE_URL } from "constants/env";

const PartnerInfoCard = ({
  data,
  index,
}: {
  data: MatchedOrder;
  index: number;
}) => {
  const isDesktop = useMedia({
    minWidth: 1200,
  });
  const [imgValue, setImgValue] = useState(0);
  useEffect(() => {
    setImgValue(Math.floor(Math.random() * 8));
  }, []);

  const partnerImage = (name: string, image: string) => {
    console.log(image);
    return (
      <PartnerImg.WrapImg>
        {image !== "" && image !== null ? (
          <PartnerImg.ProfileImg profileImg={image}>
            <div />
          </PartnerImg.ProfileImg>
        ) : (
          <PartnerImg.DefaultProfileImg imgValue={`background_${imgValue}.png`}>
            <div>
              <p>{name}</p>
            </div>
          </PartnerImg.DefaultProfileImg>
        )}
      </PartnerImg.WrapImg>
    );
  };

  return (
    <S.Card key={index}>
      <S.ListBoxWrapper>
        <S.ListBox>
          {partnerImage(data.adminname, data.partner_meta?.profile_img)}
          <S.CompanyWrapper>
            <S.CompanyTitle>{data.adminname}</S.CompanyTitle>
            <S.CompanyInfo>
              {data.partner_meta && (
                <StarRating score={data.partner_meta?.score} />
              )}
              <Dot />
              <div>후기 {data.feedback_cnt}개</div>
              <Dot />
              <div>경력 {data.experience}년</div>
              {isDesktop && (
                <>
                  <Dot />
                  <div>{`${data.phone1}-${data.phone2}-${data.phone3}`}</div>
                </>
              )}
            </S.CompanyInfo>
          </S.CompanyWrapper>
        </S.ListBox>
        <S.TagWrapper>
          {data.partner_meta?.keyword_list?.length > 0 && (
            <Tag keywords={data.partner_meta.keyword_list} />
          )}
          {data.level === "S" && <SBadge />}
        </S.TagWrapper>
      </S.ListBoxWrapper>
      <ButtonWrapper>
        <S.BorderLineButton
          id="call_oneroom"
          isDesktop={isDesktop}
          href={`tel:${data.phone1}-${data.phone2}-${data.phone3}`}
        >
          전화하기
        </S.BorderLineButton>
        <S.ColorButton
          id="detail_oneroom"
          isDesktop={isDesktop}
          href={`${MOVE_URL}/requests/oneroom/completed/${data.adminid}`}
          target="_blank"
        >
          자세히 보기
        </S.ColorButton>
      </ButtonWrapper>
    </S.Card>
  );
};

const S = {
  Card: styled.li`
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    background-color: ${colors.white};
    padding: 16px;
    margin: 16px 0;
    box-sizing: border-box;
  `,
  ListBoxWrapper: styled.div``,
  ListBox: styled.div`
    display: flex;
    flex-direction: row;
  `,
  CompanyWrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 4px 0 0 10px;
    letter-spacing: -1px;
    color: ${colors.gray33};

    @media screen and (max-width: 320px) {
      margin: 5px 0 0 10px;
      font-size: 15px;
    }
  `,
  TagWrapper: styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin: 15px 0 16px;
    img {
      margin-left: 4px;
      align-self: center;
    }
  `,
  CompanyTitle: styled.span`
    font-size: 20px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  CompanyInfo: styled.div`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colors.gray66};
    margin-top: 6px;

    @media screen and (max-width: 320px) {
      font-size: 12px;
    }
  `,
  BorderLineButton: styled.a<{ isDesktop: boolean }>`
    width: ${(props) => (props.isDesktop ? "0" : "30")}%;
    color: ${colors.pointBlue};
    border: 1px solid ${colors.pointBlue};
    border-radius: 6px;
    padding: 9px 20px 10px;
    margin-right: 8px;
    ${(props) =>
      props.isDesktop &&
      `
      display: none;
      margin-right: 0px;
  `}
  `,
  ColorButton: styled.a<{ isDesktop: boolean }>`
    width: ${(props) => (props.isDesktop ? "100" : "70")}%;
    color: ${colors.white};
    background-color: ${colors.pointBlue};
    border-radius: 6px;
    padding: 9px 20px 10px;
  `,
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 700;
  text-align: center;
`;

const PartnerImg = {
  WrapImg: styled.div`
    width: 54px;
    height: 54px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid ${colors.lineDefault};
  `,
  ProfileImg: styled.div<{ profileImg: string }>`
    position: relative;
    div {
      width: 54px;
      height: 54px;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-image: url(${(props) => props.profileImg});
    }
  `,
  DefaultProfileImg: styled.div<{ imgValue: string }>`
    color: #ffffff;
    position: relative;
    text-align: center;
    div {
      width: 54px;
      height: 54px;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      ${(props) =>
        props.imgValue &&
        css`
          background-image: url(${require(`assets/images/partner/${props.imgValue}`)});
        `};
    }
    p {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 700;
      font-size: 6px;
    }
  `,
};

export default PartnerInfoCard;
