import * as React from "react";

function Clean({
  width = 32,
  height = 32,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
      {...props}
    >
      <rect
        x={7.429}
        y={26.855}
        width={17.714}
        height={1.168}
        rx={0.584}
        fill="#121212"
      />
      <rect
        x={24}
        y={18.682}
        width={5.714}
        height={1.167}
        rx={0.584}
        fill="#121212"
      />
      <path
        d="M15.601 5.783a.563.563 0 01.803-.005l13.129 13.065c.228.227.23.601.005.831a.563.563 0 01-.803.006L15.607 6.614a.593.593 0 01-.006-.831z"
        fill="#121212"
      />
      <path
        d="M11.034 11.273a.563.563 0 00.808 0l4.56-4.653a.593.593 0 000-.826.563.563 0 00-.809 0l-4.559 4.653a.593.593 0 000 .826z"
        fill="#121212"
      />
      <rect
        x={25.143}
        y={18.682}
        width={9.341}
        height={1.143}
        rx={0.571}
        transform="rotate(90 25.143 18.682)"
        fill="#121212"
      />
      <rect
        x={8.571}
        y={23.936}
        width={4.087}
        height={1.143}
        rx={0.571}
        transform="rotate(90 8.571 23.936)"
        fill="#121212"
      />
      <path
        d="M13.588 12.86c-1.098.34-2.05.895-2.818 1.68-.752.766-1.288 1.71-1.623 2.798-.335-1.089-.873-2.032-1.627-2.8-.77-.783-1.722-1.338-2.82-1.679 1.1-.345 2.053-.905 2.823-1.695.752-.77 1.289-1.718 1.624-2.809.335 1.09.87 2.037 1.62 2.809.77.79 1.72 1.35 2.82 1.695z"
        stroke="#1672F7"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Clean;
