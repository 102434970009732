import * as React from "react";

function AirCleaner({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 4.5v2.25a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75V4.5a3 3 0 00-3 3v15H19.5a.75.75 0 010 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-15A4.5 4.5 0 018.25 3h7.5a4.5 4.5 0 014.5 4.5v12.75a.75.75 0 01-1.5 0V7.5a3 3 0 00-3-3zm-1.5 0h-4.5V6h4.5V4.5z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 13.5a.75.75 0 00-.75.75v6c0 .414.336.75.75.75h9a.75.75 0 00.75-.75v-6a.75.75 0 00-.75-.75h-9zm.75 6V18h1.5v1.5h-1.5zm0-4.5v1.5h1.5V15h-1.5zm3 4.5V18h1.5v1.5h-1.5zm0-3h1.5V15h-1.5v1.5zm3 3V18h1.5v1.5h-1.5zm0-4.5v1.5h1.5V15h-1.5z"
        fill={fill}
      />
    </svg>
  );
}

export default AirCleaner;
