import * as React from "react";

function Close({
  width = 16,
  height = 16,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="fill"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4 13.046a.5.5 0 000-.707L9.156 8.096l3.889-3.889a.5.5 0 10-.707-.707l-3.89 3.89-4.242-4.244a.5.5 0 10-.707.708l9.192 9.192a.5.5 0 00.707 0zm-6.718-3.89a.5.5 0 01.707.708l-3.535 3.535a.5.5 0 11-.708-.707l3.536-3.535z"
        fill="#121212"
      />
    </svg>
  );
}

export default Close;
