import dayjs from "dayjs";
import { deleteCookie, setCookie } from "lib/cookie";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import * as sentry from "@sentry/react";
import { Severity } from "@sentry/react";

import * as actions from "./actions";
import * as requests from "./requests";
import * as orderActions from "services/order/actions";
import * as orderSelector from "services/order/selectors";

import { push } from "connected-react-router";

const DUPLICATE_USER = 409;

export function* fetchVerifySendMessageSaga(action: ActionType<typeof actions.fetchVerifySendMessageAsync.request>) {
  try {
    const data = yield call(requests.verifySendMessage, action.payload.phone);
    yield put(actions.fetchVerifySendMessageAsync.success(data));
  } catch (e) {
    yield put(actions.fetchVerifySendMessageAsync.failure());
    sentry.captureMessage("인증 문자 메시지 보내기 실패", {
      level: Severity.Error,
    });
    sentry.captureException(e);
  }
}

export function* fetchVerifyCodeSaga(action: ActionType<typeof actions.fetchVerifyCodeAsync.request>) {
  try {
    const {
      data: { isVerified },
    } = yield call(requests.verifyAuthCode, action.payload.phone, action.payload.code);
    yield put(actions.fetchVerifyCodeAsync.success({ isVerified: isVerified }));
  } catch (e) {
    yield put(actions.fetchVerifyCodeAsync.failure());
    alert("에러가 발생했습니다.");
    sentry.captureMessage("인증코드 검증 실패", {
      level: Severity.Error,
    });
    sentry.captureException(e);
  }
}

export function* fetchSignUpSaga(action: ActionType<typeof actions.fetchSignUpAsync.request>) {
  const { tel, code } = action.payload;

  try {
    const { token, data } = yield call(requests.postSignUp, action.payload);
    // setCookie('x-wematch-token', token, { secure: true, 'max-age': 60 * 60 * 24 * 60 })
    setCookie("x-wematch-token", token, {
      "max-age": 60 * 60 * 24 * 60,
      expires: new Date(dayjs().add(60, "day").format()),
    });
    // 회원가입 성공 시
    yield put(actions.fetchSignUpAsync.success({ token, user: { ...data } }));

    // 로그인 요청 후 원룸 접수
    yield put(
      orderActions.submitOrderAsync.request({
        order: yield select(orderSelector.getOrderForm),
        token,
      })
    );
  } catch (e) {
    if (e.response.status === DUPLICATE_USER) {
      // 이미 가입된 경우 로그인 요청
      try {
        const { token } = yield call(requests.getSignIn, tel, code);
        setCookie("x-wematch-token", token, {
          "max-age": 60 * 60 * 24 * 60,
          expires: new Date(dayjs().add(60, "day").format()),
        });
        const { data } = yield call(requests.getUser, token);
        yield put(actions.fetchSignInAsync.success({ token, user: { ...data.user } }));

        // 로그인 요청 후 원룸 접수
        yield put(
          orderActions.submitOrderAsync.request({
            order: yield select(orderSelector.getOrderForm),
            token,
          })
        );
      } catch (e) {
        yield put(actions.fetchSignInAsync.failure());
        alert("로그인 실패");
        sentry.captureMessage("로그인 실패", {
          level: Severity.Error,
        });
        sentry.captureException(e);
        yield put(push("/error"));
      }
    } else {
      sentry.captureMessage("회원가입 실패", {
        level: Severity.Error,
      });
      sentry.captureException(e);
      yield put(push("/error"));
    }
  }
}

// export function* fetchSignInSaga(action: ActionType<typeof actions.fetchSignInAsync.request>) {
//   try {
//     const { phone, code } = action.payload
//     const response = yield call(requests.getSignIn, phone, code)
//     setCookie('x-wematch-token', response, { 'max-age': 60 * 60 * 24 * 60, expires: new Date(dayjs().add(60, 'day').format()) })
//     const { data } = yield call(requests.getUser, response)
//     yield put(actions.fetchSignInAsync.success({ token: response, user: { ...data.user } }))
//   } catch(e) {
//     yield put(actions.fetchSignInAsync.failure())
//   }
// }

export function* fetchGetUserSaga(action: ActionType<typeof actions.fetchGetUserAsync.request>) {
  try {
    const { token } = action.payload;
    const response = yield call(requests.getUser, token);
    yield put(
      actions.fetchGetUserAsync.success({
        token,
        user: { ...response.data },
      })
    );
  } catch (e) {
    deleteCookie("x-wematch-token");
    yield put(actions.fetchGetUserAsync.failure());
    sentry.captureMessage("사용자 프로필 조회 실패", {
      level: Severity.Error,
    });
    sentry.captureException(e);
  }
}

export default function* () {
  yield all([
    takeEvery(actions.fetchVerifySendMessageAsync.request, fetchVerifySendMessageSaga),
    takeEvery(actions.fetchVerifyCodeAsync.request, fetchVerifyCodeSaga),
    takeEvery(actions.fetchSignUpAsync.request, fetchSignUpSaga),
    // takeEvery(actions.fetchSignInAsync.request, fetchSignInSaga),
    takeEvery(actions.fetchGetUserAsync.request, fetchGetUserSaga),
  ]);
}
