import * as React from "react";

function Minus({
  width = 24,
  height = 24,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      {...props}
    >
      <rect
        x={22.5}
        y={12}
        width={1.5}
        height={21}
        rx={0.75}
        transform="rotate(90 22.5 12)"
        fill="#121212"
      />
    </svg>
  );
}

export default Minus;
