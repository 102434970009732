import { User } from "types/auth";
import { createAction, createAsyncAction } from "typesafe-actions";

import * as types from "./types";

export const fetchUserConsultAsync = createAsyncAction(
  "FETCH_USER_CONSULT_REQUEST",
  "FETCH_USER_CONSULT_SUCCESS",
  "FETCH_USER_CONSULT_FAILURE"
)<
  { name: string; phone: string },
  {
    name: string;
    phone: string;
    clean_orders: any;
    move_orders: any;
    past_orders: any;
  },
  undefined
>();

export const fetchSignInAsync = createAsyncAction(
  "FETCH_SIGN_IN_REQUEST",
  "FETCH_SIGN_IN_SUCCESS",
  "FETCH_SIGN_IN_FAILURE"
)<types.RequestSignInProps, types.ResponseSignInProps, undefined>();

export const fetchSignUpAsync = createAsyncAction(
  "FETCH_SIGN_UP_REQUEST",
  "FETCH_SIGN_UP_SUCCESS",
  "FETCH_SIGN_UP_FAILURE"
)<types.RequestSignUpProps, types.ResponseSignUpProps, undefined>();

export const fetchVerifySendMessageAsync = createAsyncAction(
  "FETCH_VERIFY_SEND_MESSAGE_REQUEST",
  "FETCH_VERIFY_SEND_MESSAGE_SUCCESS",
  "FETCH_VERIFY_SEND_MESSAGE_FAILURE"
)<
  types.RequestVerifySendMessageProps,
  types.ResponseVerifySendMessageProps,
  undefined
>();

export const fetchVerifyCodeAsync = createAsyncAction(
  "FETCH_VERIFY_CODE_REQUEST",
  "FETCH_VERIFY_CODE_SUCCESS",
  "FETCH_VERIFY_CODE_FAILURE"
)<types.RequestVerifyAuthCodeProps, types.ResponseVerifyCodeProps, undefined>();

export const fetchGetUserAsync = createAsyncAction(
  "FETCH_GET_USER_REQUEST",
  "FETCH_GET_USER_SUCCESS",
  "FETCH_GET_USER_FAILURE"
)<{ token: string }, { token: string; user: User }, undefined>();

export const phoneVerifyCancel = createAction("PHONE_VERIFY_CANCEL")();
export const phoneVerifyReset = createAction("PHONE_VERIFY_RESET")();
export const setIsError = createAction("SET_IS_ERROR")<boolean>();
