import * as React from "react";

function Truck01({
  width = 56,
  height = 56,
  fill = "none",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill={fill}
      {...props}
    >
      <mask
        id="prefix__truck_01"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={56}
        height={56}
      >
        <path fill="#fff" d="M0 0h56v56H0z" />
      </mask>
      <g mask="url(#prefix__truck_01)">
        <rect x={2} y={33} width={2} height={10} rx={1} fill="#121212" />
        <rect x={25} y={19} width={2} height={24} rx={1} fill="#121212" />
        <rect x={52} y={11} width={2} height={32} rx={1} fill="#121212" />
        <rect
          x={9.5}
          y={41.5}
          width={2}
          height={7}
          rx={1}
          transform="rotate(90 9.5 41.5)"
          fill="#121212"
        />
        <rect
          x={37}
          y={41}
          width={2}
          height={12}
          rx={1}
          transform="rotate(90 37 41)"
          fill="#121212"
        />
        <rect
          x={53.969}
          y={11}
          width={2}
          height={27}
          rx={1}
          transform="rotate(90 53.969 11)"
          fill="#121212"
        />
        <rect
          x={54}
          y={41}
          width={2}
          height={9}
          rx={1}
          transform="rotate(90 54 41)"
          fill="#121212"
        />
        <rect
          x={27}
          y={19}
          width={2}
          height={15}
          rx={1}
          transform="rotate(90 27 19)"
          fill="#121212"
        />
        <path
          d="M12.044 19.531a.996.996 0 011.625 1.151L3.833 34.567a.996.996 0 11-1.625-1.151l9.836-13.885z"
          fill="#121212"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.208 18.3a1 1 0 00-1.465.054l-7.729 7.729a1 1 0 001.414 1.414l.88-.879v4.6a1 1 0 001 1H44.692a1 1 0 001-1v-4.605l.879.879a1 1 0 001.414-1.414L40.208 18.3zm.311 11.918v-3a1 1 0 10-2 0v3h-3.212v-5.6l4.195-4.195 4.191 4.19v5.605h-3.174zM35 42a6 6 0 1012 0 6 6 0 00-12 0zm10 0a4 4 0 11-8 0 4 4 0 018 0z"
          fill="#121212"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 42a6 6 0 1012 0 6 6 0 00-12 0zm10 0a4 4 0 11-8 0 4 4 0 018 0z"
          fill="#1672F7"
        />
      </g>
    </svg>
  );
}

export default Truck01;
