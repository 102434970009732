import { call, put, all, takeEvery } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";

import * as actions from "./actions";
import * as requests from "./requests";
import { showToast } from "components/common/Toast";

export function* fetchAddressListSaga(action: ActionType<typeof actions.fetchAddressListAsync.request>) {
  try {
    const { data } = yield call(requests.getAddress, action.payload);
    yield put(actions.fetchAddressListAsync.success(data));
  } catch (e) {
    const errorCode = ["E0006", "E0009", "E0010", "EE0011", "E0012", "E0013"];
    if (!errorCode.includes(e.error.code)) {
      showToast({
        message: e.error.message,
        type: "error",
        position: "bottom",
      });
    }
    yield put(actions.fetchAddressListAsync.failure(e.error));
  }
}

export function* fetchAddressListMoreSaga(action: ActionType<typeof actions.fetchAddressMoreListAsync.request>) {
  try {
    const { data } = yield call(requests.getAddress, action.payload);
    yield put(actions.fetchAddressMoreListAsync.success(data));
  } catch (e) {
    const errorCode = ["E0006", "E0009", "E0010", "EE0011", "E0012", "E0013"];
    if (!errorCode.includes(e.error.code)) {
      showToast({
        message: e.error.message,
        type: "error",
        position: "bottom",
      });
    }
    yield put(actions.fetchAddressMoreListAsync.failure(e.error));
  }
}

// export function* fetchVerifySendMessageSaga(action: ActionType<typeof actions.fetchVerifySendMessageAsync.request>) {
//   try {
//     const data = yield call(requests.verifySendMessage, action.payload.phone)
//     yield put(actions.fetchVerifySendMessageAsync.success(data))
//   } catch (e) {
//     yield put(actions.fetchVerifySendMessageAsync.failure())
//     alert('에러가 발생했습니다.')
//   }
// }

// export function* fetchVerifyCodeSaga(action: ActionType<typeof actions.fetchVerifyCodeAsync.request>) {
//   try {
//     const data = yield call(requests.verifyAuthCode, action.payload.phone, action.payload.code)
//     yield put(actions.fetchVerifyCodeAsync.success(data))
//   } catch (e) {
//     yield put(actions.fetchVerifyCodeAsync.failure())
//     alert('에러가 발생했습니다.')
//   }
// }

export function* fetchPrivacyPartnerListAsyncSaga(action: ActionType<typeof actions.fetchPrivacyPartnerListAsync.request>) {
  try {
    const data = yield call(requests.getPrivacyPartner);
    yield put(actions.fetchPrivacyPartnerListAsync.success(data));
  } catch (e) {
    yield put(actions.fetchPrivacyPartnerListAsync.failure());
  }
}

export default function* () {
  yield all([
    takeEvery(actions.fetchAddressListAsync.request, fetchAddressListSaga),
    takeEvery(actions.fetchAddressMoreListAsync.request, fetchAddressListMoreSaga),
    takeEvery(actions.fetchPrivacyPartnerListAsync.request, fetchPrivacyPartnerListAsyncSaga),
    // takeEvery(actions.fetchVerifySendMessageAsync.request, fetchVerifySendMessageSaga),
    // takeEvery(actions.fetchVerifyCodeAsync.request, fetchVerifyCodeSaga)
  ]);
}
