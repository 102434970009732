import * as React from "react";

function Bookshelf({
  width = 24,
  height = 24,
  fill = "#121212",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 .75A.75.75 0 013.75 0h13.5a.75.75 0 010 1.5h-4.5v6h6.75V.75a.75.75 0 011.5 0v22.5a.75.75 0 01-.75.75H3.75a.747.747 0 01-.75-.75V.75zM4.5 16.5v6h6.75v-6H4.5zm6.75-1.5H4.5V9h6.75v6zm1.5 1.5v6h6.75v-6h-6.75zM19.5 15h-6.75V9h6.75v6zm-8.25-7.5v-6H4.5v6h6.75z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 3a.75.75 0 00-.75.75V7.5h1.5V3.75A.75.75 0 006.75 3zm7.4 7.875a.75.75 0 00-.275 1.024l2.625 4.547 1.299-.75-2.625-4.546a.75.75 0 00-1.024-.275zm2.725 1.024a.75.75 0 011.299-.75l2.625 4.547-1.299.75-2.625-4.547zM9.75 4.5a.75.75 0 00-.75.75V7.5h1.5V5.25a.75.75 0 00-.75-.75z"
        fill={fill}
      />
    </svg>
  );
}

export default Bookshelf;
