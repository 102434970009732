import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";
import { User } from "types/auth";
import { LoadingStatus } from "types/loading";

export type Actions = ActionType<typeof actions>;

export interface UserState {
  auth: {
    token: string | null;
    loading: LoadingStatus;
    user: User | null;
    isError: boolean;
  };
  phoneVerify: {
    isVerified: boolean | null;
    isSendMessage?: boolean;
    loading: boolean;
  };
  sendMessageError: boolean;
}

const initialState: UserState = {
  auth: {
    token: null,
    user: null,
    loading: "init",
    isError: false,
  },
  phoneVerify: {
    isVerified: null,
    isSendMessage: false,
    loading: false,
  },
  sendMessageError: false,
};

export default createReducer<UserState, Actions>(initialState)
  .handleAction(actions.fetchVerifySendMessageAsync.request, (state) => ({
    ...state,
    phoneVerify: { ...state.phoneVerify, isSendMessage: false, loading: true },
    sendMessageError: false,
  }))
  .handleAction(actions.fetchVerifySendMessageAsync.success, (state) => ({
    ...state,
    phoneVerify: { ...state.phoneVerify, isSendMessage: true, loading: false },
    sendMessageError: false,
  }))
  .handleAction(actions.fetchVerifySendMessageAsync.failure, (state) => ({
    ...state,
    phoneVerify: { ...state.phoneVerify, loading: false },
    sendMessageError: true,
  }))
  .handleAction(actions.fetchVerifyCodeAsync.request, (state) => ({
    ...state,
    phoneVerify: { ...state.phoneVerify, loading: true },
  }))
  .handleAction(actions.fetchVerifyCodeAsync.success, (state, action) => ({
    ...state,
    phoneVerify: {
      isVerified: action.payload.isVerified,
      isSendMessage: !action.payload.isVerified,
      loading: false,
    },
  }))
  .handleAction(actions.fetchVerifyCodeAsync.failure, (state) => ({
    ...state,
    phoneVerify: { ...state.phoneVerify, isVerified: false, loading: false },
  }))
  .handleAction([actions.fetchSignUpAsync.request, actions.fetchSignInAsync.request], (state) => ({ ...state, auth: { ...state.auth, loading: "loading" } }))
  .handleAction([actions.fetchSignInAsync.success, actions.fetchSignUpAsync.success], (state, action) => ({
    ...state,
    auth: {
      token: action.payload.token,
      user: action.payload.user,
      loading: "done",
      isError: false,
    },
  }))
  .handleAction([actions.fetchSignInAsync.failure, actions.fetchSignUpAsync.failure], (state) => ({
    ...state,
    auth: { ...state.auth, loading: "error", isError: true },
  }))
  .handleAction(actions.fetchGetUserAsync.request, (state) => ({
    ...state,
    auth: { ...state.auth, loading: "loading", isError: false },
  }))
  .handleAction(actions.fetchGetUserAsync.success, (state, action) => ({
    ...state,
    auth: {
      ...state,
      token: action.payload.token,
      user: action.payload.user,
      loading: "done",
      isError: false,
    },
  }))
  .handleAction(actions.fetchGetUserAsync.failure, (state) => ({
    ...state,
    auth: { ...state.auth, loading: "error", isError: true },
  }))
  .handleAction(actions.phoneVerifyCancel, (state) => ({
    ...state,
    phoneVerify: { isVerified: null, isSendMessage: false, loading: false },
  }))
  .handleAction(actions.phoneVerifyReset, (state) => ({
    ...state,
    phoneVerify: { ...state.phoneVerify, isVerified: null },
  }))
  .handleAction(actions.setIsError, (state, action) => ({
    ...state,
    auth: { ...state.auth, error: action.payload },
  }));
