import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import GridCheckbox from "./gridCheckBox";

import * as orderActions from "services/order/actions";
import * as orderSelector from "services/order/selectors";

import {
  CheckType,
  Furniture,
  HomeAppliance,
  Initialize,
  SmallAppliance,
} from "types/order";

const S = {
  Container: styled.div``,
  Group: styled.div`
    overflow: hidden;
    margin-bottom: 40px;
  `,
};

interface Props {
  id: number;
  category: Furniture | HomeAppliance | SmallAppliance | Initialize;
  disabled: boolean;
  label: string;
  children: React.ReactNode;
}

export default function EqSelect({
  id,
  category,
  disabled,
  label,
  children,
}: Props) {
  const dispatch = useDispatch();

  const getPreviousTempAnswersEq = useSelector(orderSelector.getTempAnswerEq);
  const getPreviousTempAnswers = useSelector(
    orderSelector.getPreviousTempAnswers
  );

  const [value, setValue] = useState<"eq" | "diff" | "init">("init");

  const onSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value as CheckType);

    dispatch(
      orderActions.setTempAnswerEq({
        id,
        category,
        status: value as CheckType,
        disabled: false,
      })
    );

    if (getPreviousTempAnswers && value === "eq") {
      const answers = getPreviousTempAnswers.filter(
        (t) => t.category === category && t.groupValue === "0"
      ); // 첫번째 선택값을 가져온다.
      if (answers) {
        answers.forEach((answer) => {
          dispatch(
            orderActions.setTempAnswer({
              ...answer,
              ...{ groupValue: String(id) },
            })
          );
        });
      }
    }

    if (getPreviousTempAnswers && value === "diff") {
      dispatch(
        orderActions.removeTempAnswer({
          category,
          groupValue: String(id),
        })
      );
    }
  };

  useEffect(() => {
    if (getPreviousTempAnswersEq) {
      const eq = getPreviousTempAnswersEq.find(
        (t) => t.id === id && t.category === category
      );
      if (eq) {
        setValue(eq.status);
      }
    }
  }, []);

  return (
    <S.Container>
      <S.Group>
        <GridCheckbox
          isHalf={true}
          onChange={onSelectChange}
          label={`${label}`}
          value="eq"
          checked={value === "eq"}
          disabled={disabled}
        />
        <GridCheckbox
          isHalf={true}
          onChange={onSelectChange}
          label="옵션 다름"
          value="diff"
          checked={value === "diff"}
          disabled={disabled}
        />
      </S.Group>
      {value === "diff" && children}
    </S.Container>
  );
}
