import React from "react";
import { Calendar } from "components/common";
import { CalendarDate } from "lib/date";
import styled from "styled-components";
import { Next } from "components/common/Icon";
import dayjs, { Dayjs } from "dayjs";

import colors from "styles/colors";

const S = {
  Container: styled.div``,
  CalendarHeaderWrapper: styled.div`
    position: relative;
    padding-bottom: 21px;
  `,
  CalendarHeader: styled.header`
    position: relative;
    text-align: center;
    font-size: 15px;
    color: ${colors.gray33};
    span {
      font-weight: 500;
      user-select: none;
    }
    button {
      font-size: 14px;
      cursor: pointer;
      position: absolute;
      display: inline-flex;
      &:nth-child(1) {
        left: 0;
      }
      &:last-child {
        right: 0;
      }
    }
  `,
  CurrentMonth: styled.span``,
  Info: styled.div`
    margin-top: 15px;
    padding: 16px 16px 14px;
    border: 1px solid #d7dbe2;
    border-radius: 6px;
    .infoTitle {
      position: relative;
      padding-left: 13px;
      font-size: 14px;
      line-height: 21px;
      .dot {
        position: absolute;
        top: 5px;
        left: -1px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: #f79016;
      }
      em {
        display: inline-block;
        margin-bottom: 2px;
        font-size: 15px;
        font-weight: 600;
      }
    }
  `,
};

interface Props {
  currentDate?: Dayjs | Date;
  selected?: string[];
  onSelect?(date: CalendarDate): void;
  rangeStartDate?: Dayjs | Date;
  rangeEndDate?: Dayjs | Date;
  disabledDate?: (date: Date) => boolean;
  closePopup?: () => void;
}

const DatePicker: React.FC<Props> = (props) => {
  const {
    currentDate,
    onSelect,
    disabledDate,
    selected = [],
    rangeStartDate,
    rangeEndDate,
    closePopup,
  } = props;

  const initialDate = (() => {
    if (currentDate) {
      if (currentDate instanceof Date) {
        return dayjs(currentDate);
      }
    }
    return dayjs();
  })();

  const [currentDateValue, setCurrentDateValue] =
    React.useState<Dayjs>(initialDate);

  const rangeStartMonthDate = React.useMemo(() => {
    if (rangeStartDate) {
      return dayjs(rangeStartDate).startOf("month");
    }
    return null;
  }, [rangeStartDate]);

  const rangeEndMonthDate = React.useMemo(() => {
    if (rangeEndDate) {
      return dayjs(rangeEndDate).endOf("month");
    }
    return null;
  }, [rangeEndDate]);

  const prevMonthDate = React.useMemo(() => {
    return dayjs(currentDateValue.month(currentDateValue.month() - 1));
  }, [currentDateValue]);

  const nextMonthDate = React.useMemo(() => {
    return dayjs(currentDateValue.month(currentDateValue.month() + 1));
  }, [currentDateValue]);

  const isBeforeRangeStartMonthDate = React.useMemo(() => {
    if (!rangeStartMonthDate) {
      return false;
    }
    return prevMonthDate.isBefore(rangeStartMonthDate);
  }, [rangeStartMonthDate, prevMonthDate]);

  const isAfterRangeEndMonthDate = React.useMemo(() => {
    if (!rangeEndMonthDate) {
      return false;
    }
    return nextMonthDate.isAfter(rangeEndMonthDate);
  }, [rangeEndMonthDate, nextMonthDate]);

  const handlePrevClick = () => setCurrentDateValue(prevMonthDate);

  const handleNextClick = () => setCurrentDateValue(nextMonthDate);

  const disabledDateWithRange = React.useCallback(
    (date: Date) => {
      const isBeforeRangeStartDate = rangeStartDate
        ? date < rangeStartDate
        : false;
      const isAfterRangeEndDate = rangeEndDate ? date > rangeEndDate : false;
      const isDisabledDate = disabledDate ? disabledDate(date) : false;

      return isBeforeRangeStartDate || isAfterRangeEndDate || isDisabledDate;
    },
    [rangeStartDate, rangeEndDate, disabledDate]
  );

  const year = currentDateValue.year();

  return (
    <S.Container>
      <S.CalendarHeaderWrapper>
        <S.CalendarHeader>
          {!isBeforeRangeStartMonthDate && (
            <button onClick={handlePrevClick}>
              <div>이전달</div>
            </button>
          )}
          <S.CurrentMonth>
            {year}년 {currentDateValue.month() + 1}월
          </S.CurrentMonth>
          {!isAfterRangeEndMonthDate && (
            <button onClick={handleNextClick}>
              <div data-cy="nextMonth">다음달</div>
            </button>
          )}
        </S.CalendarHeader>
      </S.CalendarHeaderWrapper>
      <Calendar
        currentDate={currentDateValue}
        onSelect={onSelect}
        disabledDate={disabledDateWithRange}
        selected={selected}
      />
    </S.Container>
  );
};

export default DatePicker;
