import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { get, isEmpty } from "lodash";
import { useMedia } from "react-use-media";

import { ButtonContainer } from "components/templates/multistep";
import { Button, CategoryItem, Quantity } from "components/common";
import CategoryPopup from "components/common/categoryPopup";
import SmallCategoryPopup from "components/common/smallCategoryPopup";

import * as Icon from "components/common/Icon";

import * as orderActions from "services/order/actions";
import * as orderSelector from "services/order/selectors";

import colors from "styles/colors";
import { events } from "lib/appsflyer";

import MoveImg from "assets/images/thumb_box2x.jpg";
import OrderOptions from "lib/orderOptions";
import {
  CategoryName,
  Furniture,
  HomeAppliance,
  Initialize,
  SmallAppliance,
} from "types/order";
import { dataLayer } from "lib/gtm";

const S = {
  Container: styled.div`
    background-color: white;
    padding: 0px 24px 120px;
    margin: 0 auto;
    .listInfoText {
      margin-bottom: 32px;
      font-size: 14px;
      line-height: 22px;
      word-break: keep-all;
      span {
        @media screen and (min-width: 768px) {
          float: right;
        }
      }
    }
    .fixButton {
      width: 100%;
      @media screen and (min-width: 1200px) {
        width: 70%;
      }
    }
  `,
  Title: styled.h3`
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
  `,
  Group: styled.section`
    overflow: hidden;
    margin-bottom: 50px;
    h4 {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 16px;
    }
  `,
  SubTitle: styled.p`
    margin-bottom: 12px;
    font-size: 15px;
  `,
  Notice: styled.section`
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin: 20px 0 10px 0;
      color: ${colors.pointBlue};
      @media screen and (min-width:768px) {
        margin: 31px 0 15px;
      }
    }
    div {
      display: flex;
      ol {
        width: 100%;
        padding-left: 14px;
        list-style: decimal;
        li {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 22px;
        }
      }
      /* img {
        margin-left: 16px;
        border-radius: 8px;
      } */
      .moveImg{
        width: 267px;
        height: 195px;
        margin-left: 16px;
        border-radius: 8px;
        background-size: cover;
        @media screen and (min-width: 768px) {
          width: 285px;
          height: 170px;
          margin-left: 0;
          margin-top: -40px;
          background-position: 0 -100px;
        }
      }
    }
    }
  `,
  Memo: styled.textarea`
    background: ${colors.grayBg};
    border: 1px solid ${colors.lineDefault};
    box-sizing: border-box;
    border-radius: 6px;
    padding: 16px;
    resize: none;
    -webkit-appearance: none;
    box-shadow: none !important;
    width: 100%;
    height: 150px;
    letter-spacing: -0.5px;
    ::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
    :focus::-webkit-input-placeholder {
      color: transparent;
    }
    :focus::-ms-input-placeholder {
      color: transparent;
    }
  `,
  PreviousButton: styled.button`
    float: left;
    width: 30%;
    height: 56px;
    border: 1px solid #d7dbe2;
    font-size: 18px;
    background: #fff;
  `,
};

const initState = {
  BED: false,
  BOOK: false,
  CABI: false,
  CHAI: false,
  CHIF: false,
  CLOS: false,
  DESK: false,
  SOFA: false,
  TABL: false,
  VANI: false,
  AC: false,
  CLOT: false,
  DISP: false,
  DRYE: false,
  FRID: false,
  WASH: false,
  BIDE: false,
  CATT: false,
  ETC: false,
  MICR: false,
  SPOR: false,
  STOV: false,
  WATE: false,
  AIRP: false,
};

const INCREMENT = 5;

export default function Movers({}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const isDesktop = useMedia({
    minWidth: 1200,
  });

  const currentStep = useSelector(orderSelector.getCurrentStep);
  const orderForm = useSelector(orderSelector.getOrderForm);

  const [category, setCategory] = useState<
    Furniture | HomeAppliance | SmallAppliance | Initialize
  >(Initialize.EMPTY);
  const [categoryPopupVisible, setCategoryPopupVisible] = useState(initState);
  const [boxCount, setBoxCount] = useState(1);
  const [memo, setMemo] = useState("");

  const togglePopup = (
    category: Furniture | HomeAppliance | SmallAppliance | Initialize
  ) => {
    setCategory(category);
    if (category) {
      const getCategory = get(categoryPopupVisible, category);
      setCategoryPopupVisible({ ...initState, [category]: !getCategory });
    }
  };

  const isChecked = (
    categoryName: "f" | "h" | "s",
    category: Furniture | HomeAppliance | SmallAppliance | Initialize
  ) => {
    if (categoryName === "f") {
      const find = orderForm.furniture?.find(
        (order) => order.category === category
      );
      return !!find;
    } else if (categoryName === "h") {
      const find = orderForm.home_appliance?.find(
        (order) => order.category === category
      );
      return !!find;
    } else if (categoryName === "s") {
      const find = orderForm.small_appliance?.find(
        (order) => order.category === category
      );
      return !!find;
    }

    return false;
  };

  const isActive = (
    categoryName: "f" | "h" | "s",
    category: Furniture | HomeAppliance | SmallAppliance | Initialize
  ) => {
    return isChecked(categoryName, category) ? colors.pointBlue : colors.black;
  };

  const onNext = () => {
    dataLayer({
      event: "step_2",
      category: "다이사_원룸_신청",
      action: "짐량 선택",
      label: "step_2",
    });

    events({
      action: "app_move_oneroom_order_02",
    });

    if (orderForm) {
      dispatch(
        orderActions.setOrderForm({
          order: orderForm,
        })
      );
    }

    dispatch(
      orderActions.setCurrentStep({
        currentStep: currentStep + 1,
      })
    );
  };

  const isDisabled = () => {
    return (
      isEmpty(orderForm.furniture) &&
      isEmpty(orderForm.home_appliance) &&
      isEmpty(orderForm.small_appliance)
    );
  };

  const onPrev = () => {
    if (currentStep <= 0) {
      history.goBack();
    } else {
      dispatch(
        orderActions.setCurrentStep({
          currentStep: currentStep - 1,
        })
      );
    }
  };

  useEffect(() => {
    // box 수량
    if (orderForm && orderForm.box >= 1) {
      setBoxCount(orderForm.box - INCREMENT);
    }
    // 메모
    if (orderForm && orderForm.memo) {
      setMemo(orderForm.memo);
    }
  }, [currentStep]);

  return (
    <S.Container>
      <div className="w-max">
        <p className="listInfoText">
          정확한 견적을 위해 짐량을 꼼꼼하게 선택해 주세요!{" "}
          <span>(복수선택)</span>
        </p>

        <S.Group>
          <h4>가구</h4>
          <CategoryItem
            icon={<Icon.Bed fill={isActive("f", Furniture.BED)} />}
            label="침대"
            checked={isChecked("f", Furniture.BED)}
            onClick={() => {
              togglePopup(Furniture.BED);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.BED}
            onClose={() => {
              togglePopup(Furniture.BED);
            }}
            data={OrderOptions}
            categoryName={CategoryName.Furniture}
            category={category}
            title={"침대"}
          />
          <CategoryItem
            icon={<Icon.Closet fill={isActive("f", Furniture.CLOS)} />}
            label="옷장"
            checked={isChecked("f", Furniture.CLOS)}
            onClick={() => {
              togglePopup(Furniture.CLOS);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.CLOS}
            onClose={() => {
              togglePopup(Furniture.CLOS);
            }}
            data={OrderOptions}
            categoryName={CategoryName.Furniture}
            category={category}
            title={"옷장"}
          />
          <CategoryItem
            icon={<Icon.Bookshelf fill={isActive("f", Furniture.BOOK)} />}
            label="책장"
            checked={isChecked("f", Furniture.BOOK)}
            onClick={() => {
              togglePopup(Furniture.BOOK);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.BOOK}
            onClose={() => {
              togglePopup(Furniture.BOOK);
            }}
            data={OrderOptions}
            categoryName={CategoryName.Furniture}
            category={category}
            title={"책장"}
          />
          <CategoryItem
            icon={<Icon.Desk fill={isActive("f", Furniture.DESK)} />}
            label="책상"
            checked={isChecked("f", Furniture.DESK)}
            onClick={() => {
              togglePopup(Furniture.DESK);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.DESK}
            onClose={() => {
              togglePopup(Furniture.DESK);
            }}
            data={OrderOptions}
            categoryName={CategoryName.Furniture}
            category={category}
            title={"책상"}
          />
          <CategoryItem
            icon={<Icon.Chair fill={isActive("f", Furniture.CHAI)} />}
            label="의자"
            checked={isChecked("f", Furniture.CHAI)}
            onClick={() => {
              togglePopup(Furniture.CHAI);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.CHAI}
            onClose={() => {
              togglePopup(Furniture.CHAI);
            }}
            data={OrderOptions}
            categoryName={CategoryName.Furniture}
            category={category}
            title={"의자"}
          />
          <CategoryItem
            icon={<Icon.Table fill={isActive("f", Furniture.TABL)} />}
            label="테이블"
            checked={isChecked("f", Furniture.TABL)}
            onClick={() => {
              togglePopup(Furniture.TABL);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.TABL}
            onClose={() => {
              togglePopup(Furniture.TABL);
            }}
            data={OrderOptions}
            categoryName={CategoryName.Furniture}
            category={category}
            title={"테이블"}
          />
          <CategoryItem
            icon={<Icon.Sofa fill={isActive("f", Furniture.SOFA)} />}
            label="소파"
            checked={isChecked("f", Furniture.SOFA)}
            onClick={() => {
              togglePopup(Furniture.SOFA);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.SOFA}
            onClose={() => {
              togglePopup(Furniture.SOFA);
            }}
            data={OrderOptions}
            categoryName={CategoryName.Furniture}
            category={category}
            title={"소파"}
          />
          <CategoryItem
            icon={<Icon.Vanity fill={isActive("f", Furniture.VANI)} />}
            label="화장대"
            checked={isChecked("f", Furniture.VANI)}
            onClick={() => {
              togglePopup(Furniture.VANI);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.VANI}
            onClose={() => {
              togglePopup(Furniture.VANI);
            }}
            data={OrderOptions}
            categoryName={CategoryName.Furniture}
            category={category}
            title={"화장대"}
          />
          <CategoryItem
            icon={<Icon.Cabinet fill={isActive("f", Furniture.CABI)} />}
            label="수납장"
            checked={isChecked("f", Furniture.CABI)}
            onClick={() => {
              togglePopup(Furniture.CABI);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.CABI}
            onClose={() => {
              togglePopup(Furniture.CABI);
            }}
            data={OrderOptions}
            categoryName={CategoryName.Furniture}
            category={category}
            title={"수납장"}
          />
          <CategoryItem
            icon={<Icon.Chiffonier fill={isActive("f", Furniture.CHIF)} />}
            label="서랍장"
            checked={isChecked("f", Furniture.CHIF)}
            onClick={() => {
              togglePopup(Furniture.CHIF);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.CHIF}
            onClose={() => {
              togglePopup(Furniture.CHIF);
            }}
            data={OrderOptions}
            categoryName={CategoryName.Furniture}
            category={category}
            title={"서랍장"}
          />
        </S.Group>

        <S.Group>
          <h4>가전</h4>
          <CategoryItem
            icon={<Icon.TV fill={isActive("h", HomeAppliance.DISP)} />}
            label="TV/모니터"
            checked={isChecked("h", HomeAppliance.DISP)}
            onClick={() => {
              togglePopup(HomeAppliance.DISP);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.DISP}
            onClose={() => {
              togglePopup(HomeAppliance.DISP);
            }}
            data={OrderOptions}
            categoryName={CategoryName.HomeAppliance}
            category={category}
            title={"TV/모니터"}
          />
          <CategoryItem
            icon={<Icon.Washing fill={isActive("h", HomeAppliance.WASH)} />}
            label="세탁기"
            checked={isChecked("h", HomeAppliance.WASH)}
            onClick={() => {
              togglePopup(HomeAppliance.WASH);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.WASH}
            onClose={() => {
              togglePopup(HomeAppliance.WASH);
            }}
            data={OrderOptions}
            categoryName={CategoryName.HomeAppliance}
            category={category}
            title={"세탁기"}
          />
          <CategoryItem
            icon={<Icon.Dryer fill={isActive("h", HomeAppliance.DRYE)} />}
            label="건조기"
            checked={isChecked("h", HomeAppliance.DRYE)}
            onClick={() => {
              togglePopup(HomeAppliance.DRYE);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.DRYE}
            onClose={() => {
              togglePopup(HomeAppliance.DRYE);
            }}
            data={OrderOptions}
            categoryName={CategoryName.HomeAppliance}
            category={category}
            title={"건조기"}
          />
          <CategoryItem
            icon={<Icon.Air fill={isActive("h", HomeAppliance.AC)} />}
            label="에어컨"
            checked={isChecked("h", HomeAppliance.AC)}
            onClick={() => {
              togglePopup(HomeAppliance.AC);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.AC}
            onClose={() => {
              togglePopup(HomeAppliance.AC);
            }}
            data={OrderOptions}
            categoryName={CategoryName.HomeAppliance}
            category={category}
            title={"에어컨"}
          />
          <CategoryItem
            icon={
              <Icon.Refrigerator fill={isActive("h", HomeAppliance.FRID)} />
            }
            label="냉장고"
            checked={isChecked("h", HomeAppliance.FRID)}
            onClick={() => {
              togglePopup(HomeAppliance.FRID);
            }}
          />
          <CategoryPopup
            visible={categoryPopupVisible.FRID}
            onClose={() => {
              togglePopup(HomeAppliance.FRID);
            }}
            data={OrderOptions}
            categoryName={CategoryName.HomeAppliance}
            category={category}
            title={"냉장고"}
          />
          <CategoryItem
            icon={<Icon.Styler fill={isActive("h", HomeAppliance.CLOT)} />}
            label="의류관리기"
            checked={isChecked("h", HomeAppliance.CLOT)}
            onClick={() => {
              togglePopup(HomeAppliance.CLOT);
            }}
          />
          <SmallCategoryPopup
            visible={categoryPopupVisible.CLOT}
            onClose={() => {
              togglePopup(HomeAppliance.CLOT);
            }}
            categoryName={CategoryName.HomeAppliance}
            category={category}
            title={"의류관리기"}
          />
        </S.Group>

        <S.Group>
          <h4>소형가전</h4>
          <CategoryItem
            icon={<Icon.Microwave fill={isActive("s", SmallAppliance.MICR)} />}
            label="전자레인지"
            checked={isChecked("s", SmallAppliance.MICR)}
            onClick={() => {
              togglePopup(SmallAppliance.MICR);
            }}
          />
          <SmallCategoryPopup
            visible={categoryPopupVisible.MICR}
            onClose={() => {
              togglePopup(SmallAppliance.MICR);
            }}
            categoryName={CategoryName.SmallAppliance}
            category={category}
            title={"전자레인지"}
          />
          <CategoryItem
            icon={<Icon.Purifier fill={isActive("s", SmallAppliance.WATE)} />}
            label="정수기"
            checked={isChecked("s", SmallAppliance.WATE)}
            onClick={() => {
              togglePopup(SmallAppliance.WATE);
            }}
          />
          <SmallCategoryPopup
            visible={categoryPopupVisible.WATE}
            onClose={() => {
              togglePopup(SmallAppliance.WATE);
            }}
            categoryName={CategoryName.SmallAppliance}
            category={category}
            title={"정수기"}
          />
          <CategoryItem
            icon={<Icon.Gasrange fill={isActive("s", SmallAppliance.STOV)} />}
            label="가스레인지"
            checked={isChecked("s", SmallAppliance.STOV)}
            onClick={() => {
              togglePopup(SmallAppliance.STOV);
            }}
          />
          <SmallCategoryPopup
            visible={categoryPopupVisible.STOV}
            onClose={() => {
              togglePopup(SmallAppliance.STOV);
            }}
            categoryName={CategoryName.SmallAppliance}
            category={category}
            title={"가스레인지"}
          />
          <CategoryItem
            icon={<Icon.Bidet fill={isActive("s", SmallAppliance.BIDE)} />}
            label="비데"
            checked={isChecked("s", SmallAppliance.BIDE)}
            onClick={() => {
              togglePopup(SmallAppliance.BIDE);
            }}
          />
          <SmallCategoryPopup
            visible={categoryPopupVisible.BIDE}
            onClose={() => {
              togglePopup(SmallAppliance.BIDE);
            }}
            categoryName={CategoryName.SmallAppliance}
            category={category}
            title={"비데"}
          />
          <CategoryItem
            icon={<Icon.AirCleaner fill={isActive("s", SmallAppliance.AIRP)} />}
            label="공기청정기"
            checked={isChecked("s", SmallAppliance.AIRP)}
            onClick={() => {
              togglePopup(SmallAppliance.AIRP);
            }}
          />
          <SmallCategoryPopup
            visible={categoryPopupVisible.AIRP}
            onClose={() => {
              togglePopup(SmallAppliance.AIRP);
            }}
            categoryName={CategoryName.SmallAppliance}
            category={category}
            title={"공기청정기"}
          />
          <CategoryItem
            icon={<Icon.CatTower fill={isActive("s", SmallAppliance.CATT)} />}
            label="캣타워"
            checked={isChecked("s", SmallAppliance.CATT)}
            onClick={() => {
              togglePopup(SmallAppliance.CATT);
            }}
          />
          <SmallCategoryPopup
            visible={categoryPopupVisible.CATT}
            onClose={() => {
              togglePopup(SmallAppliance.CATT);
            }}
            categoryName={CategoryName.SmallAppliance}
            category={category}
            title={"캣타워"}
          />
          <CategoryItem
            icon={
              <Icon.RunningMachine fill={isActive("s", SmallAppliance.SPOR)} />
            }
            label="운동용품"
            checked={isChecked("s", SmallAppliance.SPOR)}
            onClick={() => {
              togglePopup(SmallAppliance.SPOR);
            }}
          />
          <SmallCategoryPopup
            visible={categoryPopupVisible.SPOR}
            onClose={() => {
              togglePopup(SmallAppliance.SPOR);
            }}
            categoryName={CategoryName.SmallAppliance}
            category={category}
            title={"운동용품"}
          />
        </S.Group>

        <S.Title>잔 짐(박스)</S.Title>
        <S.SubTitle>옷, 식기, 책 등의 생활짐</S.SubTitle>
        <Quantity
          increment={INCREMENT}
          min={1}
          max={55}
          defaultValue={boxCount}
          onChange={(count) => {
            dispatch(
              orderActions.setOrderForm({
                order: {
                  ...orderForm,
                  box: count + INCREMENT, // API는 최대값으로 (1~6 범위로 안받음)
                },
              })
            );
          }}
        />

        <S.Notice>
          <h3>확인해주세요!</h3>
          <div>
            <ol>
              <li>우체국 5호 박스 기준 (48cm x 36cm x 34cm)</li>
              <li>
                기사님이 박스를 여유롭게 준비하실 수 있도록 넉넉히 입력해주세요.
              </li>
              <li>
                입력하신 박스 양이 실제와 다른 경우 추가요금이 발생 할 수
                있습니다.
              </li>
            </ol>
            <div
              className="moveImg"
              style={{ backgroundImage: `url(${MoveImg})` }}
            />
          </div>
        </S.Notice>

        {/* 2022.01.06 메모 기능 숨김처리 by Koo */}

        {/* <S.Title style={{ marginTop: 50, marginBottom: 16 }}>전달메모</S.Title>
        <S.Memo
          placeholder="전달할 내용이 있다면 입력해 주세요. ex) 경유지가 있어요."
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            // 따옴표, 쌍따옴표 입력 제거 (DB에러 발생)
            // const englishAlphabetAndWhiteSpace = /[A-Za-z0-9 ._^%$#!~@,-]/g;
            const rex = /['"]/g;
            if (!rex.test(e.target.value)) {
              setMemo(e.target.value);
              dispatch(
                orderActions.setOrderForm({
                  order: {
                    ...orderForm,
                    memo: e.target.value,
                  },
                })
              );
            }
          }}
          value={memo}
          data-cy="memo"
        /> */}

        <ButtonContainer>
          {isDesktop ? (
            <S.PreviousButton onClick={onPrev}>이전</S.PreviousButton>
          ) : (
            ""
          )}
          <Button
            className="fixButton"
            theme="primary"
            onClick={onNext}
            disabled={isDisabled()}
            data-cy="next"
          >
            다음
          </Button>
        </ButtonContainer>
      </div>
    </S.Container>
  );
}
