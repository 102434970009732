import { ApiResponse, oneRoomApi } from "lib/api";
import { Order } from "types/order";

export const submitOrder = async (order: Order, token: string | null) => {
  const { data } = await oneRoomApi.request<ApiResponse<Order>>({
    method: "post",
    headers: {
      "X-Wematch-Token": token,
    },
    url: "/orders",
    data: order,
  });

  return data.data;
};

export const getOrder = async (token: string) => {
  const { data } = await oneRoomApi.request<ApiResponse<Order>>({
    method: "get",
    headers: {
      "X-Wematch-Token": token,
    },
    url: "/order",
  });

  return data.data;
};
