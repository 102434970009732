import React, { useState, useEffect } from "react";
import styled from "styled-components";

import BasePopup from "components/base/basePopup";

const S = {
  Section: styled.section``,
  Title: styled.h3`
    margin-bottom: 22px;
    font-size: 20px;
    font-weight: 700;
  `,
  SubTitle: styled.p`
    margin-bottom: 8px;
    font-size: 15px;
  `,
  Container: styled.div`
    overflow: hidden;
    margin: 0 -1.5%;
    margin-bottom: 40px;
  `,
  Category: styled.div<{ isHalf: boolean }>`
    float: left;
    width: ${(props) => (!props.isHalf ? "30.3%" : "47%")};
    height: ${(props) => (!props.isHalf ? "72px" : "56px")};
    margin: 0 1.5%;
    margin-bottom: 10px;
    border: 1px solid #d7dbe2;
    border-radius: 8px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    p {
      font-size: 16px;
      line-height: ${(props) => (!props.isHalf ? "84px" : "56px")};
    }
    &.active {
      border: 1px solid #1672f7;
      box-shadow: 0px 4px 10px rgba(22, 114, 247, 0.25);
    }
  `,
};

interface CategoryProps {
  id: string;
  label: string;
  isHalf: boolean;
  component: React.ReactNode;
}

function CategoryItem({ id, label, isHalf, component }: CategoryProps) {
  const [visible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const body = document.querySelector("body") as HTMLElement;
    const scrollPosition = window.pageYOffset;

    if (visible) {
      body.style.overflow = "hidden";
      body.style.position = "fixed";
      body.style.top = `-${scrollPosition}px`;
      body.style.left = "0";
      body.style.right = "0";

      return () => {
        body.style.removeProperty("overflow");
        body.style.removeProperty("position");
        body.style.removeProperty("top");
        body.style.removeProperty("left");
        body.style.removeProperty("right");

        window.scrollTo(0, scrollPosition);
      };
    }
  }, [visible]);

  return (
    <>
      <S.Category isHalf={isHalf} onClick={toggle}>
        <p>{label}</p>
      </S.Category>

      <BasePopup visible={visible} onClose={toggle}>
        {component}
      </BasePopup>
    </>
  );
}

interface CategoryListProps {
  lists: {
    id: string;
    label: string;
    component: React.ReactNode;
  }[];
  title: string;
  subTitle?: string;
  isHalf: boolean;
}

export default function CategoryList({
  lists,
  title,
  subTitle,
  isHalf,
}: CategoryListProps) {
  return (
    <S.Section>
      <S.Title>{title}</S.Title>
      {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
      <S.Container>
        {lists.map((l, i) => (
          <CategoryItem
            key={i}
            label={l.label}
            isHalf={isHalf}
            id={l.id}
            component={l.component}
          />
        ))}
      </S.Container>
    </S.Section>
  );
}
